import { faChevronDown, faChevronRight, faCloudArrowUp, faQuestionCircle, faSpinnerThird, faTimes } from "@fortawesome/pro-light-svg-icons";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { castArray, cloneDeep, get, isUndefined, omitBy, throttle } from "lodash";
import React from "react";
import { useController, useFormContext, useFormState } from "react-hook-form";
import styled, { css } from "styled-components";
import { Alert, Button, CommandBar, Flex, FlexItem, OverlayTrigger, Text, Tooltip } from "../../Components";
import { ToggleInput } from "../../Forms";
import { DockedPanel, usePanelKey } from "../DockedManager";
import { FormPath, FormPathDisplay } from "./components/Path";
const FormGroupCtx = React.createContext({ collapsed: false, onToggle: () => undefined, fake: true });
// Form
const FormStyled = styled.form ``;
// Group Header
const FormGroupHeaderStyled = styled.div `
	display: flex;
	flex-direction: row;

	background: white;
	position: sticky;
	top: 0;
	border-bottom: 1px solid #dddddd;

	& > * {
		padding: 7px;
	}

	& > *:nth-child(1) {
		flex: 0 0 0;
		cursor: pointer;
	}
	&> *:nth-child(2) {
		flex: 1 0 0;
		overflow-x: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	&> *:nth-child(3) {
		flex: 0 0 0;
		border: none;
		padding: 0 5px;
	}
`;
const FormGroupHeader = React.forwardRef(function FormGroupHeader(props, ref) {
    const ctx = React.useContext(FormGroupCtx);
    const internalRef = React.useRef();
    React.useImperativeHandle(ref, () => internalRef.current);
    return React.createElement(FormGroupHeaderStyled, { ...props },
        React.createElement(Flex, { ref: internalRef, style: { width: "100%" } },
            props.left,
            React.createElement("div", { onClick: ctx?.onToggle }, React.createElement(FontAwesomeIcon, { color: ctx.fake ? "transparent" : undefined, fixedWidth: true, icon: ctx.collapsed ? faChevronRight : faChevronDown })),
            props.children,
            props.onContextMenu ? (React.createElement(Button, { type: "button", onClick: props.onContextMenu },
                React.createElement(FontAwesomeIcon, { fixedWidth: true, icon: faEllipsisV }))) : null,
            props.hint && React.createElement(React.Fragment, null,
                React.createElement(FlexItem, { grow: true }),
                React.createElement(OverlayTrigger, { trigger: { type: "hover", triggerTime: 200 }, placement: "left", overlay: React.createElement(Tooltip, null, props.hint) },
                    React.createElement(FontAwesomeIcon, { fixedWidth: true, icon: faQuestionCircle })))));
});
// Group Body
const FormGroupBodyStyled = styled.div `
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	grid-gap: 7px;
	padding: 7px;
`;
export const groupBodyProps = React.createContext({
    bootstrap: true,
});
export const useGroupBodyProps = () => {
    return React.useContext(groupBodyProps);
};
const FormGroupBody = function (props) {
    const ctx = React.useContext(FormGroupCtx);
    const grpBody = React.useContext(groupBodyProps);
    if (ctx.collapsed)
        return null;
    return React.createElement(groupBodyProps.Provider, { value: { ...grpBody, ...props } },
        React.createElement(FormGroupBodyStyled, { className: props.className, style: props.style }, props.children));
};
const FormGroupRemovePadding = styled.div `
	margin-left: -7px;
	margin-right: -7px;
`;
// Group
const FormGroupStyled = styled.div `
	flex: 0 0 100%;
	border-top: 1px solid #dddddd;

	& + & {
		border-top: none;
	}
`;
const FormGroup = React.forwardRef(function FormGroup(props, ref) {
    const [internalCollapsed, setInternalCollapsed] = React.useState(props.initialCollapsed);
    const collapsed = props.collapsed ?? internalCollapsed;
    const onToggle = props.onToggle ?? setInternalCollapsed;
    const internalRef = React.useRef();
    React.useImperativeHandle(ref, () => internalRef.current);
    React.useEffect(() => window.DSPopUpResize?.(), [collapsed]);
    return React.createElement(FormGroupStyled, { ref: internalRef },
        React.createElement(FormGroupCtx.Provider, { value: { collapsed, onToggle: () => onToggle(!collapsed) } }, props.children));
});
FormGroup.defaultProps = {
    initialCollapsed: false,
};
// Field
const FormFieldStyled = styled.div `
	flex: 1 1 300px;
	font-weight: normal;
	${p => p.bootstrap ? "" : css `
		padding-bottom: .125rem;
	`}
	
	& > ${FormGroupStyled} {
		margin: 0 -7px;
	}
`;
const FormFieldLabelStyled = styled.div `
	font-size: .80em;
	${e => !e.bootstrap ? css `
		letter-spacing: 0.05em;
		padding-left: 7px;
		// font-variant: all-small-caps;
		text-transform: uppercase;
		color: rgb(130 ,130 ,130);
		margin-top: 5px;
	` : ""}
`;
const FieldUi = (props) => {
    const bodyProps = useGroupBodyProps();
    const bootstrap = props.bootstrap ?? bodyProps.bootstrap;
    const LabelElementType = props.labelElementType ?? "span";
    return React.createElement(FormFieldStyled, { className: props.className, bootstrap: bootstrap },
        React.createElement(FormFieldLabelStyled, { bootstrap: bootstrap },
            React.createElement(Flex, null,
                React.createElement(FlexItem, { grow: true },
                    React.createElement(LabelElementType, null, props.label)),
                (props.rightLabel || props.hint) && React.createElement(FlexItem, null,
                    props.rightLabel,
                    props.hint && React.createElement(OverlayTrigger, { trigger: { type: "hover", triggerTime: 200 }, placement: "left", overlay: React.createElement(Tooltip, null, props.hint) },
                        React.createElement(FontAwesomeIcon, { fixedWidth: true, icon: faQuestionCircle }))))),
        props.children,
        typeof props.error === "string" ? React.createElement("span", { style: { color: "red" } }, props.error) : props.error);
};
const FormField = (props) => {
    return React.createElement(FieldUi, { ...props });
};
const FieldContext = React.createContext(null);
export const useFieldHook = () => {
    return React.useContext(FieldContext);
};
export const SCHEMA_MARKER = Symbol("SCHEMA_MARKER");
const HookFormField = (props) => {
    const f = useFormContext();
    const schema = f.control[SCHEMA_MARKER];
    const fieldError = schema?.get(props.name);
    let isRequired = false;
    if (fieldError) {
        switch (fieldError.code) {
            case "invalid_type":
                isRequired = fieldError.received === "undefined";
                break;
            case "invalid_union":
                isRequired = fieldError.unionErrors.some(e => e.issues.find(e => e.received == undefined));
                break;
        }
    }
    if (!props.experimentalRequired)
        isRequired = false;
    return React.createElement(FieldContext.Provider, { value: { name: props.name } },
        React.createElement(FieldUi, { labelElementType: props.labelElementType, error: React.createElement(HookError, { name: props.name }), ...props, label: React.createElement(React.Fragment, null,
                props.label,
                isRequired ? React.createElement(Text, { bold: true }, "*") : "") }));
};
const HookFormFieldToggle = (props) => {
    const { name } = useFieldHook() ?? {};
    const finalName = props.name || name;
    const controller = useController({ name: finalName, defaultValue: props.defaultValue });
    return React.createElement(Form.HookField, { name: props.name },
        React.createElement(Flex, null,
            "\u00A0",
            React.createElement(Flex, { gap: ".5em", style: { cursor: props.disabled ? undefined : "pointer" }, onClick: () => {
                    if (props.disabled)
                        return;
                    controller.field.onChange(!controller.field.value);
                } },
                React.createElement(FlexItem, null,
                    React.createElement(ToggleInput, { size: "sm", ...props, onChange: e => {
                            if (props.disabled)
                                return;
                            controller.field.onChange(e);
                            props.onChange?.(e);
                        }, value: controller.field.value, style: { display: "inline-block", marginTop: 7, lineHeight: "10px", ...(props.style ?? {}) } })),
                React.createElement(FlexItem, { alignSelf: "center" }, props.children),
                props.hint && React.createElement(FlexItem, { alignSelf: "center" },
                    React.createElement(OverlayTrigger, { trigger: { type: "hover", triggerTime: 200 }, placement: "left", overlay: React.createElement(Tooltip, null, props.hint) },
                        React.createElement(FontAwesomeIcon, { fixedWidth: true, opacity: 0.5, icon: faQuestionCircle }))))));
};
const FormFieldToggle = (props) => {
    return React.createElement(Form.Field, null,
        React.createElement(Flex, null,
            "\u00A0",
            React.createElement(Flex, { gap: ".5em", style: { cursor: props.disabled ? undefined : "pointer" }, onClick: () => {
                    if (props.disabled)
                        return;
                    props.onChange?.(!props.value);
                } },
                React.createElement(FlexItem, null,
                    React.createElement(ToggleInput, { size: "sm", ...props, onChange: e => {
                            if (props.disabled)
                                return;
                            props.onChange?.(e);
                        }, value: props.value, style: { display: "inline-block", marginTop: 7, lineHeight: "10px", ...(props.style ?? {}) } })),
                React.createElement(FlexItem, { alignSelf: "center" }, props.children),
                props.hint && React.createElement(FlexItem, { alignSelf: "center" },
                    React.createElement(OverlayTrigger, { trigger: { type: "hover", triggerTime: 200 }, placement: "left", overlay: React.createElement(Tooltip, null, props.hint) },
                        React.createElement(FontAwesomeIcon, { fixedWidth: true, opacity: 0.5, icon: faQuestionCircle }))))));
};
const HookError = (props) => {
    const { control } = useFormContext();
    const { errors } = useFormState({ control });
    const error = get(errors, props.name);
    if (error) {
        return React.createElement(Text, { color: "danger" }, error.message);
    }
    else {
        return null;
    }
};
const FormFooter = styled(Flex) `
	// border-top: 1px solid #ddd;
	// padding-top: 14px;
	// padding-right: 8px;
`;
export const handleAPIMessage = (message) => {
    try {
        const errorParsed = JSON.parse(message);
        // only exists if API Error
        if (errorParsed.internal?.body) {
            const body = JSON.parse(errorParsed.internal.body);
            return typeof body.message === "string" ? body.message : JSON.stringify(body.message);
        }
        else if (errorParsed.message) {
            return typeof errorParsed.message === "string" ? errorParsed.message : JSON.stringify(errorParsed.message);
        }
        else if (Array.isArray(errorParsed)) { // Case: Zod Errors come as array including messages
            return errorParsed.map(e => e.message ?? JSON.stringify(e)).join(", ");
        }
        return null;
    }
    catch (err) {
        console.error("unexpected parsing error in handleAPIMessage:", err);
        return null;
    }
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FormErrors = (props) => {
    const panelKey = usePanelKey();
    const { errors } = useFormState();
    const Wrapper = React.useMemo(() => {
        if (panelKey)
            return DockedPanel.Footer;
        return React.Fragment;
    }, [panelKey]);
    const err = cloneDeep(errors);
    clearRef(err);
    function clearRef(obj) {
        for (const key in obj) {
            if (key === "ref") {
                delete obj[key];
            }
            else if (obj[key] instanceof Object) {
                clearRef(obj[key]);
            }
            else {
                continue;
            }
        }
    }
    const deepFlatErrors = React.useMemo(() => {
        const target = [];
        function deepFlat(error) {
            for (const key in error) {
                if (key === "ref") {
                    continue;
                }
                else if (key === "message") {
                    // "submit" is special
                    if (["submit", "load"].includes(error.type)) {
                        const errorParsed = handleAPIMessage(error.message);
                        if (errorParsed) {
                            target.push(errorParsed);
                            props.onError?.(error.type, errorParsed);
                        }
                        else {
                            const msg = typeof error[key] === "object" ? JSON.stringify(error[key]) : error[key];
                            target.push(msg);
                            props.onError?.(error.type, error.message);
                        }
                    }
                    else {
                        /*
                            2024-07-12 [AP/PR]
                            HeliAustria erhält öfters ZOD validation errors, die wir nicht reproduzieren können:
                            https://react.dev/errors/31?invariant=31&args%5B%5D=object%20with%20keys%20%7Bissues%2C%20name%7D
                            Additionl check ob es ein String oder Object ist.
                        */
                        const msg = typeof error[key] === "object" ? JSON.stringify(error[key]) : error[key];
                        target.push(msg);
                        props.onError?.(error.type, error.message);
                    }
                }
                else if (error[key] instanceof Object) {
                    deepFlat(error[key]);
                }
                else
                    continue;
            }
        }
        deepFlat(errors);
        return target;
    }, [JSON.stringify(err)]);
    if (!deepFlatErrors?.length)
        return null;
    return React.createElement(Wrapper, null,
        React.createElement(Alert, { fullWidth: true, style: { position: "relative", border: "none", lineHeight: "40px", padding: 0 }, color: "danger" },
            React.createElement(Flex, { style: { height: "100%" }, column: true }, deepFlatErrors.map(e => React.createElement(FlexItem, { style: { paddingLeft: "1em" } }, e)))));
};
const FormCancelSave = (props) => {
    if (props.confirm)
        return React.createElement(FormCancelSaveConfirm, { ...props });
    return React.createElement(FormCancelSaveNoConfirm, { ...props });
};
const FormCancelSaveConfirm = (props) => {
    const [confirm, setConfirm] = React.useState(false);
    const [confirmCancel, setConfirmCancel] = React.useState(false);
    const { bootstrap } = useGroupBodyProps();
    const realBootstrap = props.bootstrap ?? bootstrap;
    if (realBootstrap === false) {
        return React.createElement(React.Fragment, null,
            confirmCancel && React.createElement(Alert, { style: { padding: "0 1.5rem", margin: 0 }, color: "warning" }, "You have unsaved changes. Discard changes?"),
            (props.children && !props.pullChildrenRight) && React.createElement(FlexItem, { shrink: true }, props.children),
            (confirm && props.confirmMessage) ? React.createElement(Alert, { style: { padding: "0 1.5rem", margin: 0 }, color: "warning" }, props.confirmMessage) : null,
            React.createElement(FlexItem, { grow: true }),
            confirm ? React.createElement(React.Fragment, null,
                !props.saveHidden && (React.createElement(CommandBar.Button, { disabled: props.saveDisabled, type: props.saveType ?? "submit", onClick: props.onSubmit ? throttle(() => props.onSubmit?.(), 1000) : undefined, variant: props.saveVariant || "primary" }, props.saveLabel)),
                React.createElement(CommandBar.Button, { disabled: props.cancelDisabled, type: "button", onClick: () => setConfirm(false), variant: props.cancelVariant || "default" }, props.cancelLabel)) : React.createElement(React.Fragment, null,
                (props.useCancelConfirm && confirmCancel) && (React.createElement(CommandBar.Button, { type: props.cancelType ?? "reset", variant: "danger", disabled: props.saveDisabled, onClick: e => {
                        setTimeout(() => setConfirmCancel(false));
                        props.onCancel?.();
                        return true;
                    } }, "Discard")),
                React.createElement(CommandBar.Button, { type: props.cancelType ?? "reset", variant: props.cancelVariant || "default", disabled: props.cancelDisabled, onClick: e => {
                        if (props.useCancelConfirm) {
                            if (props.isDirty && !confirmCancel) {
                                e.preventDefault();
                                setConfirmCancel(true);
                                return false;
                            }
                            if (confirmCancel && props.isDirty) {
                                e.preventDefault();
                                setConfirmCancel(false);
                                return false;
                            }
                        }
                        else if (props.cancelType === "button") {
                            props.onCancel?.();
                        }
                    } }, props.cancelLabel),
                (!props.useCancelConfirm || props.useCancelConfirm && !confirmCancel) && !props.saveHidden && (React.createElement(CommandBar.Button, { type: "button", variant: props.saveVariant || "primary", disabled: props.saveDisabled, onClick: () => setConfirm(true) }, props.saveLabel))));
    }
    return React.createElement(React.Fragment, null,
        confirmCancel && React.createElement(Alert, { style: { padding: "0 1.5rem", margin: 0 }, color: "warning" }, "You have unsaved changes. Discard changes?"),
        React.createElement(FormFooter, { style: { width: "100%", ...(props.style ?? {}) }, gap: "4px" },
            (props.children && !props.pullChildrenRight) && React.createElement(FlexItem, { shrink: true }, props.children),
            (confirm && props.confirmMessage) ? React.createElement(Alert, { style: { padding: "0 1.5rem", margin: 0 }, color: "warning" }, props.confirmMessage) : null,
            React.createElement(FlexItem, { grow: true }),
            React.createElement(FlexItem, null, confirm ? React.createElement(React.Fragment, null,
                !props.saveHidden && (React.createElement(Button, { disabled: props.saveDisabled, size: "sm", type: props.saveType ?? "submit", onClick: props.onSubmit ? throttle(() => props.onSubmit?.(), 1000) : undefined, variant: props.saveVariant || "primary" }, props.saveLabel)),
                React.createElement(Button, { disabled: props.cancelDisabled, size: "sm", type: "button", onClick: () => setConfirm(false), variant: props.cancelVariant || "link" }, props.cancelLabel)) : React.createElement(React.Fragment, null,
                (props.useCancelConfirm && confirmCancel) && (React.createElement(Button, { disabled: props.saveDisabled, onClick: e => {
                        setTimeout(() => setConfirmCancel(false));
                        props.onCancel?.();
                        return true;
                    }, size: "sm", type: props.cancelType ?? "reset", variant: "danger" }, "Discard")),
                React.createElement(Button, { onClick: e => {
                        if (props.useCancelConfirm) {
                            if (props.isDirty && !confirmCancel) {
                                e.preventDefault();
                                setConfirmCancel(true);
                                return false;
                            }
                            if (confirmCancel && props.isDirty) {
                                e.preventDefault();
                                setConfirmCancel(false);
                                return false;
                            }
                        }
                        else if (props.cancelType === "button") {
                            props.onCancel?.();
                        }
                    }, disabled: props.cancelDisabled, size: "sm", type: props.cancelType ?? "reset", variant: props.cancelVariant || "link" }, props.cancelLabel),
                (!props.useCancelConfirm || props.useCancelConfirm && !confirmCancel) && !props.saveHidden && (React.createElement(Button, { disabled: props.saveDisabled, onClick: () => setConfirm(true), size: "sm", type: "button", variant: props.saveVariant || "primary" }, props.saveLabel)))),
            (props.children && props.pullChildrenRight) && React.createElement(FlexItem, { shrink: true }, props.children)));
};
const FormCancelSaveNoConfirm = (props) => {
    const [confirmCancel, setConfirmCancel] = React.useState(false);
    const { bootstrap } = useGroupBodyProps();
    const realBootstrap = props.bootstrap ?? bootstrap;
    if (realBootstrap === false) {
        return React.createElement(React.Fragment, null,
            confirmCancel && React.createElement(Alert, { style: { padding: "0 1.5rem", margin: 0 }, color: "warning" }, "You have unsaved changes. Discard changes?"),
            React.createElement(CommandBar.Button, { type: props.onCancel ? "button" : "reset", variant: props.cancelVariant || "default", disabled: props.cancelDisabled, onClick: e => {
                    if (props.useCancelConfirm) {
                        if (props.isDirty && !confirmCancel) {
                            e.preventDefault();
                            setConfirmCancel(true);
                            return;
                        }
                        else if (confirmCancel && props.isDirty) {
                            e.preventDefault();
                            setConfirmCancel(false);
                        }
                        else {
                            props.onCancel?.();
                        }
                    }
                    else {
                        props.onCancel?.();
                    }
                } }, props.cancelLabel),
            (!props.useCancelConfirm || props.useCancelConfirm && !confirmCancel) && !props.saveHidden && (React.createElement(CommandBar.Button, { type: props.saveType ?? "submit", variant: props.saveVariant || "primary", disabled: props.saveDisabled, onClick: props.onSubmit ? throttle(() => props.onSubmit?.(), 1000) : undefined }, props.saveLabel)),
            (props.useCancelConfirm && confirmCancel) && (React.createElement(CommandBar.Button, { type: props.onCancel ? "button" : "reset", variant: "danger", disabled: props.saveDisabled, onClick: e => {
                    setTimeout(() => setConfirmCancel(false));
                    props.onCancel?.();
                    return true;
                } }, "Discard")));
    }
    return React.createElement(React.Fragment, null,
        confirmCancel && React.createElement(Alert, { style: { padding: "0 1.5rem", margin: 0 }, color: "warning" }, "You have unsaved changes. Discard changes?"),
        React.createElement(FormFooter, { style: { width: "100%", ...(props.style ?? {}) }, gap: "4px" },
            (props.children && !props.pullChildrenRight) && React.createElement(FlexItem, { shrink: true }, props.children),
            React.createElement(FlexItem, { grow: true }),
            React.createElement(FlexItem, null,
                React.createElement(Button, { onClick: e => {
                        if (props.useCancelConfirm) {
                            if (props.isDirty && !confirmCancel) {
                                e.preventDefault();
                                setConfirmCancel(true);
                                return;
                            }
                            else if (confirmCancel && props.isDirty) {
                                e.preventDefault();
                                setConfirmCancel(false);
                            }
                            else {
                                props.onCancel?.();
                            }
                        }
                        else {
                            props.onCancel?.();
                        }
                    }, disabled: props.cancelDisabled, size: "sm", type: props.onCancel ? "button" : "reset", variant: props.cancelVariant || "link" }, props.cancelLabel),
                (!props.useCancelConfirm || props.useCancelConfirm && !confirmCancel) && !props.saveHidden && (React.createElement(Button, { disabled: props.saveDisabled, onClick: props.onSubmit ? throttle(() => props.onSubmit?.(), 1000) : undefined, size: "sm", type: props.saveType ?? "submit", variant: props.saveVariant || "primary" }, props.saveLabel)),
                (props.useCancelConfirm && confirmCancel) && (React.createElement(Button, { onClick: e => {
                        setTimeout(() => setConfirmCancel(false));
                        props.onCancel?.();
                        return true;
                    }, disabled: props.saveDisabled, size: "sm", type: props.onCancel ? "button" : "reset", variant: "danger" }, "Discard"))),
            (props.children && props.pullChildrenRight) && React.createElement(FlexItem, { shrink: true }, props.children)));
};
FormCancelSave.defaultProps = {
    saveLabel: "Save",
    cancelLabel: "Cancel",
};
export const FormCancelSaveHookContext = React.createContext(null);
const FormCancelSaveHook = (providedProps) => {
    const [confirmCancel, setCancelConfirm] = React.useState(false);
    const [confirmSave, setSaveConfirm] = React.useState(false);
    const { isLoading, isSubmitting } = useFormState();
    const propsContext = React.useContext(FormCancelSaveHookContext);
    const props = { ...(omitBy(propsContext ?? {}, isUndefined)), ...(omitBy(providedProps ?? {}, isUndefined)) };
    const isConfirmControlled = props.confirmCancel !== undefined;
    const isSaveControlled = props.confirmSave !== undefined;
    const showCancelConfirm = isConfirmControlled ? props.confirmCancel : confirmCancel;
    const showSaveConfirm = isSaveControlled ? props.confirmSave : confirmSave;
    const cancelProps = {
        onClick: (e) => {
            if (props.useCancelConfirm) {
                if (showCancelConfirm) {
                    setCancelConfirm(false);
                    props.onCancel?.();
                    return;
                }
                setCancelConfirm(true);
                return;
            }
            props.onCancel?.();
        },
        label: props.cancelLabel ?? "Cancel",
        disabled: props.cancelDisabled || isLoading || isSubmitting,
    };
    const handleCancelCancel = (e) => {
        if (isConfirmControlled) {
            props.onCancelConfirmCancel?.(e);
            return;
        }
        else {
            setCancelConfirm(false);
        }
    };
    const handleCancelConfirm = (e) => {
        if (isConfirmControlled) {
            props.handleCancelConfirm?.(e);
            return;
        }
        else {
            setCancelConfirm(false);
            props.onCancel?.();
        }
    };
    const saveProps = {
        onClick: () => {
            if (props.useCancelConfirm && showCancelConfirm) {
                setCancelConfirm(false);
                props.onCancel?.();
                return;
            }
            if (props.useCancelConfirm && showSaveConfirm) {
                setSaveConfirm(false);
                props.onSubmit?.();
                return;
            }
            props.onSubmit?.();
        },
        label: props.saveLabel ?? "Save",
        disabled: props.saveDisabled || isLoading || isSubmitting,
        variant: props.saveVariant,
    };
    const saveAndNextProps = {
        onClick: () => {
            if (props.useCancelConfirm && showCancelConfirm) {
                setCancelConfirm(false);
                props.onCancel?.();
                return;
            }
            if (props.useCancelConfirm && showSaveConfirm) {
                setSaveConfirm(false);
                props.handleNext?.();
                props.onSubmit?.();
                return;
            }
            props.handleNext?.();
            props.onSubmit?.();
        },
        label: props.saveLabel ?? "Save And Next",
        disabled: props.saveDisabled || isLoading || isSubmitting,
    };
    return React.createElement(CommandBar, { flipBorder: true },
        !providedProps.pullChildrenRight && React.createElement(CommandBar.Left, null, props.children),
        showCancelConfirm && React.createElement(StyledAlert, { color: "warning" }, "Discard unsaved changes?"),
        showSaveConfirm && React.createElement(StyledAlert, { color: "warning" }, "Are you sure?"),
        React.createElement(CommandBar.Right, null,
            !(showCancelConfirm || showSaveConfirm) && React.createElement(React.Fragment, null,
                React.createElement(CancelButton, { ...cancelProps }),
                React.createElement(ConfirmButton, { ...saveProps, style: { marginRight: 6, ...(saveProps.style ?? {}) } }),
                props.andNext && React.createElement(ConfirmButton, { ...saveAndNextProps, style: { marginRight: 6, ...(saveProps.style ?? {}) } })),
            showCancelConfirm && React.createElement(React.Fragment, null,
                React.createElement(CancelButton, { ...cancelProps, onClick: handleCancelCancel }),
                React.createElement(ConfirmButton, { ...cancelProps, onClick: handleCancelConfirm, label: "Discard", style: { marginRight: 6, ...(cancelProps.style ?? {}) } }))),
        providedProps.pullChildrenRight && React.createElement(CommandBar.Right, null,
            React.createElement("div", null, props.children)));
};
const HeaderSaveButton = (props) => {
    const propsContext = React.useContext(FormCancelSaveHookContext);
    const handleClick = props.handleClick || propsContext?.onSubmit;
    const onClick = e => {
        handleClick?.(e);
        props.onClick?.(e);
    };
    const { isLoading, isSubmitting } = useFormState();
    return React.createElement(CommandBar.Button, { style: props.style, onClick: onClick, disabled: props.disabled || isSubmitting || isLoading, title: "Save" }, isSubmitting ? React.createElement(FontAwesomeIcon, { icon: faSpinnerThird, spin: true }) : React.createElement(FontAwesomeIcon, { icon: faCloudArrowUp }));
};
const CancelButton = (props) => {
    return React.createElement(Button, { style: props.style, onClick: props.onClick, disabled: props.disabled, variant: "link", size: "sm" }, props.label || "Cancel");
};
const ConfirmButton = (props) => {
    return React.createElement(Button, { style: props.style, onClick: props.onClick, disabled: props.disabled, variant: props.variant ?? "primary", size: "sm" }, props.label || "Save");
};
export const Form = Object.assign(FormStyled, {
    Group: FormGroup,
    GroupHeader: FormGroupHeader,
    GroupBody: FormGroupBody,
    GroupBodyNoPadding: FormGroupRemovePadding,
    Field: Object.assign(FormField, { Label: FormFieldLabelStyled, Toggle: FormFieldToggle }),
    HookField: Object.assign(HookFormField, { Toggle: HookFormFieldToggle }),
    Footer: FormFooter,
    CancelSave: FormCancelSave,
    CancelSaveHook: FormCancelSaveHook,
    HeaderSave: HeaderSaveButton,
    Errors: FormErrors,
    HookError: HookError,
    PathDisplay: FormPathDisplay,
    PathProvider: FormPath,
    SelectTheme: {
        components: {
            ClearIndicator: function ClearIndicator(props) {
                let values = castArray(props.getValue());
                values = values.filter(v => props.selectProps.isOptionDisabled?.(v) ?? true);
                return React.createElement(FontAwesomeIcon, { onClick: () => {
                        if (props.selectProps.isMulti) {
                            props.setValue(values);
                        }
                        else {
                            if (values.length === 1) {
                                props.setValue(values[0]);
                            }
                            else {
                                props.setValue(null);
                            }
                        }
                    }, style: { padding: "0 0.85rem" }, icon: faTimes, fixedWidth: true });
            },
            DropdownIndicator: function DropdownIndicator(props) {
                if (props.isDisabled)
                    return null;
                return React.createElement(FontAwesomeIcon, { icon: faChevronDown, style: { padding: "0 0.85rem" }, fixedWidth: true });
            },
            DownChevron: function DownChevron(props) {
                if (props.isDisabled)
                    return null;
                return React.createElement(FontAwesomeIcon, { icon: faChevronDown, style: { padding: "0 0.85rem" }, fixedWidth: true });
            },
            MultiValueRemove: function MultiValueRemove(p) {
                const isDisabled = p.selectProps?.isOptionDisabled?.(p.data) ?? false;
                if (isDisabled)
                    return null;
                const optionValue = p.selectProps.getOptionValue(p.data);
                const dropItem = () => {
                    const next = p.selectProps.value.filter(v => p.selectProps.getOptionValue(v) !== optionValue);
                    if (next.length === 0)
                        p.selectProps.onChange(undefined);
                    else
                        p.selectProps.onChange(next);
                };
                return React.createElement(FontAwesomeIcon, { onClick: dropItem, icon: faTimes, style: { padding: "4px 0.55rem" }, fixedWidth: true });
            },
        },
        styles: {
            menu: (base) => ({ ...base, zIndex: 500 }),
            container: (base, state) => ({ ...base,
                minWidth: 75,
                "::after": {
                    content: "''",
                    position: "absolute",
                    bottom: "0px",
                    left: "0px",
                    width: 1,
                    height: 6,
                    background: "#ddd"
                },
                width: "100%",
            }),
            // option: (base) => ({ ...base, padding: 8 }),
            placeholder: (base, state) => ({ ...base, color: "hsl(0,0%,50%)", paddingLeft: 2, visibility: state.isDisabled ? "hidden" : "visible" }),
            multiValue: (base) => ({ ...base, fontSize: "1rem", marginLeft: 5 }),
            multiValueLabel: (base) => ({ ...base, marginRight: 5 }),
            control: (base, state) => {
                return { ...base,
                    marginLeft: "0px",
                    //background: "rgb(180 180 180 / 10%)",
                    border: "none",
                    borderBottom: `1px solid ${state.menuIsOpen ? "#0167e3" : "hsl(0,0%,80%)"}`,
                    "&:hover": {},
                    "&:active": {
                        borderBottom: "1px solid #0167e3",
                    },
                    background: state.isDisabled ? "#fafafa" : "#fff",
                    borderColor: "#9e9e9e",
                    height: "auto",
                    minHeight: "24px",
                    // height: 24,
                    boxShadow: state.isFocused ? null : null,
                };
            },
            indicatorSeparator: (base) => ({ ...base, display: "none" }),
            indicatorsContainer: (provided, state) => {
                return {
                    ...provided,
                    minHeight: "24px",
                    height: "24px",
                    "::before": {
                        content: "''",
                        position: "absolute",
                        bottom: "0px",
                        right: "0px",
                        width: 1,
                        height: 6,
                        background: "#ddd",
                    },
                };
            },
            valueContainer: (provided, state) => ({
                ...provided,
                height: "auto",
                minHeight: "24px",
                // height: "24px",
                padding: "0 6px"
            }),
            input: (provided, state) => ({
                ...provided,
                margin: "0px",
                fontSize: "1.25rem",
            }),
        },
        // theme: (theme => ({ ...theme, borderRadius: 0, spacing: { ...theme.spacing, baseUnit: 1, menuGutter: 2 }})),
    },
});
const StyledAlert = styled(Alert) `
	font-size: .9em;
    padding: 0px;
    padding-inline: 0.6em;
    margin-left: 0.6em;
`;
