import { FpApi, FpDirClientState, FpId, ResourceScheduleUtil } from "@tcs-rliess/fp-core";
import { QUERY_CACHE } from "@tcs-rliess/fp-query";
import { castArray, defer, groupBy } from "lodash-es";
import { DateTime } from "luxon";

import { FleetplanApp } from "../../../../../FleetplanApp";
import { ContactStateUtil } from "../../../../ContactStateUtil";
import { ServiceDataProcessor } from "../../../../fp-query";
import { StoreUpdateSource } from "../../../BaseStoreRange";

import { BalanceBucket, ScheduleBucket } from "./BalanceBucket";
import { ScheduleBalanceContactBucket } from "./ScheduleBalanceContactBucket";
import { CheckInQuery, CustomQuery, ScheduleQuery } from "./types";


// there should only be exact one instance of this store
export class ScheduleBalanceStore {
	private scheduleBucketQueue: [ number, ...Parameters<InstanceType<typeof ScheduleBalanceContactBucket>["add"]>][] = [];
	private timeout: NodeJS.Timeout;

	private scheduleUtil = new ResourceScheduleUtil();

	private buildQueueItem(item: FpApi.Resource.Duty.Schedule): (typeof this.scheduleBucketQueue)[number] {
		const balance = ScheduleBucket.fromSchedule(item);
		const date = ScheduleBucket.getSpan(DateTime.fromISO(item.dateFrom));
		return [ +item.linkId, "schedule", date, {
			checkedIn: item.data?.dsrsciid ? true : false,
			dscatid: item.dscatid,
			type: item.type,
			status: item.status,
		}, balance ];
	}



	// @ET add push listener here (this.app.store.resource.scheduleBalance.updateBalance)
	constructor(private app: FleetplanApp) {
		defer(() => {
			this.app.store.resource.schedule.addListener("put", (item: FpApi.Resource.Duty.Schedule, options: { previous?: FpApi.Resource.Duty.Schedule, source: StoreUpdateSource }) => {
				if(options.source !== "local") return; // only listen to local changes
				clearTimeout(this.timeout);
				if(options.previous) {
					const balance = ScheduleBucket.fromSchedule(item).negate();
					const date = ScheduleBucket.getSpan(DateTime.fromISO(options.previous.dateFrom));
					this.scheduleBucketQueue.push([ +options.previous.linkId, "schedule", date, {
						checkedIn: options.previous.data?.dsrsciid ? true : false,
						dscatid: options.previous.dscatid,
						type: options.previous.type,
						status: options.previous.status,
					}, balance ]);

				}
				if(!item.isCurrent) return; // non current are ignored
				if([ FpApi.Resource.Duty.ScheduleStatus.Container, FpApi.Resource.Duty.ScheduleStatus.Approved ].includes(item.status) === false) return;
				if(item.dscid > 0) {
					let items = [ item ];
					if(item.status === FpApi.Resource.Duty.ScheduleStatus.Container) {
						// unwrap container
						items = this.scheduleUtil.flatten([ item ]);
					}
					items.forEach(e => {
						this.scheduleBucketQueue.push(this.buildQueueItem(e));
					});
				}
				this.timeout = setTimeout(() => this.performUpdate(), 1000);
			});
		});
	}

	private performUpdate(): void {
		// this.state.store.projectModel.resourceStore.beginBatch();
		const queue = this.scheduleBucketQueue;
		this.scheduleBucketQueue = [];
		for(const [ dscaid, type, date, query, balance ] of queue) {
			const bucket = this.app.store.resource.scheduleBalance.buckets.get(dscaid);
			if(bucket) {
				bucket.add(type, date, query, balance);
			}
		}
		// this.state.store.projectModel.resourceStore.endBatch();
	}
	// mapped by dscaid
	public buckets = new Map<number, ScheduleBalanceContactBucket>();

	// YYYYMM:DSCAID
	// private loadedRanges = new Set<`${number}:${number}`>();

	private correctionDeltaByYear = new Map<number, Map<number, FpApi.Contact.ContactTargetWorkingTimeCorrectionDelta>>();
	private entitlementByYear = new Map<number, Map<number, FpApi.Resource.Duty.DutyRosterVacation>>();

	private contactStates = new Map<number, FpDirClientState>();
	public getDelta(year: number, dscaid: number): number {
		const yearMap = this.correctionDeltaByYear.get(year);
		if(yearMap) {
			const delta = yearMap.get(dscaid);
			if(delta) return delta.delta;
		}
		return 0;
	}

	public getEntitlement(year: number, dscaid: number): number {
		const yearMap = this.entitlementByYear.get(year);
		if(yearMap) {
			const entitlement = yearMap.get(dscaid);
			if(entitlement) return entitlement.vacation;
		}
		return 0;
	}

	public getContactState(dscaid: number): FpDirClientState {
		return this.contactStates.get(dscaid);
	}

	public async getVacationDays(pointInTime: DateTime, dscaid: number): Promise<number> {
		const year = pointInTime.year;
		const contactState = this.contactStates.get(dscaid);
		const tm = ContactStateUtil.asTimeManagement(contactState);
		const yearMap = this.entitlementByYear.get(year);
		if(yearMap) {
			const entitlement = yearMap.get(dscaid);
			if(entitlement) return entitlement.vacation;
		}
		if(tm.getVacPerYearInDays(pointInTime)) return tm.getVacPerYearInDays(pointInTime);
		// get contract
		const contract = await this.app.store.hrContractStore.findContractForDate({ dscaid, date: pointInTime });
		if(!contract) return 0;
		return contract.data.vacation.entitlementDays;
	}

	public clear(): void {
		this.buckets.clear();
		this.correctionDeltaByYear.clear();
		this.entitlementByYear.clear();
		QUERY_CACHE.deletePrefix("FpApi.Resource.Duty.ScheduleBalanceService");
		QUERY_CACHE.deletePrefix("FpApi.Contact.ContactTargetWorkingTimeCorrectionService");
		QUERY_CACHE.deletePrefix("FpApi.Resource.Duty.DutyRosterVacationService");
	}

	public async load(year: number | number[], idList: number[]): Promise<void> {
		const years = castArray(year);

		for(const year of years) {
			const loadedData = await ServiceDataProcessor.getService(this.app.ctx, FpApi.Resource.Duty.ScheduleBalanceService).get({
				params: {
					from: year * 100 + 1,
					to: year * 100 + 12,
					linkType: "dscaid",
					linkId: idList.map(e => e.toString()),
				},
				cache: {
					maxAge: 1_000 * 60 * 60 // 1 hour
				}
			});
			// ------------------------ load correction delta for workhours and entitlements ------------------------
			await Promise.all([
				this.loadDelta(year),
				this.loadEntitlement(year, idList),
				this.loadContactStates(idList),
			]);
			// ------------------------ update buckets ------------------------

			const grouped = groupBy(loadedData, e => e.linkId);
			for(const dscaid in grouped) {
				const bucket = this.buckets.get(parseInt(dscaid));
				if(bucket) {
					for(const data of grouped[dscaid]) {
						bucket.insertBalance(data);
					}
				} else {
					this.buckets.set(parseInt(dscaid), new ScheduleBalanceContactBucket(parseInt(dscaid), grouped[dscaid], this));
				}
			}
		}
		// figure out what to
	}

	private async loadDelta(year: number): Promise<void> {
		const delta = await ServiceDataProcessor.getService(this.app.ctx, FpApi.Contact.ContactTargetWorkingTimeCorrectionService).getDelta({
			params: {
				year: year,
			},
			cache: {
				maxAge: 1_000 * 60 * 60 // 1 hour
			}
		});
		this.correctionDeltaByYear.set(year, new Map(delta.map(e => [ e.dscaid, e ])));
		if(!this.correctionDeltaByYear.get(year)) this.correctionDeltaByYear.set(year, new Map());
		delta.forEach(e => {
			this.correctionDeltaByYear.get(year).set(e.dscaid, e);
		});
	}

	private async loadEntitlement(year: number, idList: number[]): Promise<void> {
		const entitlement = await ServiceDataProcessor.getService(this.app.ctx, FpApi.Resource.Duty.DutyRosterVacationService).get({
			params: {
				year: year,
				dscaid: idList,
			},
			cache: {
				maxAge: 1_000 * 60 * 60 // 1 hour
			}
		});
		if(!this.entitlementByYear.get(year)) this.entitlementByYear.set(year, new Map());
		entitlement.forEach(e => {
			this.entitlementByYear.get(year).set(e.dscaid, e);
		});
	}

	private async loadContactStates(idList: number[]): Promise<void> {
		const states = await this.app.store.contactState.getIdList(idList);
		states.forEach(e => {
			this.contactStates.set(e.id, e);
		});
	}

	public updateBalance(balance: FpApi.Resource.Duty.ScheduleBalance): void {
		const bucket = this.buckets.get(+balance.linkId);
		if(bucket) {
			bucket.insertBalance(balance);
		} else {
			this.buckets.set(+balance.linkId, new ScheduleBalanceContactBucket(+balance.linkId, [ balance ], this));
		}
	}

	/**
	 * Subscribe to changes in the schedule balance
	 * @param dscaid the id of the resource
	 * @param from the start of the timespan
	 * @param to the end of the timespan
	 * @param query the query to subscribe to
	 * @param cb the callback to call when the query changes. @param updatedQueries are the queries that particularly changed in the timespan (using the source query. Syntax: dscaid:bucketType:timespan:query)
	*/
	public subscribe(type: "schedule", dscaid: number | number[] | "*", from: number | "*", to: number | "*", query: ScheduleQuery, cb: (updatedQueries: string[]) => void, id?: string): string;
	public subscribe(type: "checkIn", dscaid: number | number[] | "*", from: number | "*", to: number | "*", query: CheckInQuery, cb: (updatedQueries: string[]) => void, id?: string): string;
	public subscribe(type: "custom", dscaid: number | number[] | "*", from: number | "*", to: number | "*", query: CustomQuery, cb: (updatedQueries: string[]) => void, id?: string): string;
	public subscribe(type: string, dscaid: number | number[] | "*", from: number | "*", to: number | "*", query: string, cb: (updatedQueries: string[]) => void, id?: string): string {
		id = id || FpId.new();
		this.listeners.set(id, { reaction: cb, dscaid, from, to, query, type });
		return id;
	}

	private listeners: Map<string, {
		// queries that explicitly changed, so the reactor has more information
		reaction: (updatedQueries: string[]) => void,
		dscaid: number | number[] | "*",
		from: number | "*",
		to: number | "*",
		query: string,
		type: string,
	}> = new Map();

	public unsubscribe(id: string) {
		this.listeners.delete(id);
	}

	protected ensureBucket(dscaid: number) {
		if(this.buckets.has(dscaid)) return;
		this.buckets.set(dscaid, new ScheduleBalanceContactBucket(dscaid, [], this));
	}

	private queueTimeout: NodeJS.Timeout;
	private notificationQueue: string[] = [];
	// update string syntax: dscaid:bucketType:timespan:query
	public notify(updateString: string) {
		this.notificationQueue.push(updateString);
		if(this.queueTimeout) clearTimeout(this.queueTimeout);
		this.queueTimeout = setTimeout(() => {
			this.processQueue(); // process queue once notification has calmed down
		}, 100);
	}

	private processQueue() {
		const queue = [ ...this.notificationQueue ];
		// collect all listener ids where we need to notify
		// we only want to notify ONCE per listener
		const notify = new Map<string, string[]>();
		// clear queue
		this.notificationQueue = [];
		for(const updateString of queue) {
			const [ dscaid, bucketType, timespan, query ] = updateString.split(":");
			for(const [ id, listener ] of this.listeners) {
				if(!(listener.type === bucketType)) continue;
				if(!((listener.from === "*" ? Number.MIN_SAFE_INTEGER : listener.from) <= +timespan && (listener.to === "*" ? Number.MAX_SAFE_INTEGER: listener.to) >= +timespan)) continue;
				if(!BalanceBucket.isMatch(query, listener.query)) continue;
				if(!(listener.dscaid === "*" || (Array.isArray(listener.dscaid) && listener.dscaid.includes(+dscaid)) || listener.dscaid === +dscaid)) continue;
				if(!notify.has(id)) notify.set(id, []);
				notify.get(id).push(updateString);
			}
		}
		// notify all listeners
		for(const [ id, updates ] of notify) {
			this.listeners.get(id).reaction(updates);
		}
	}
}

// type BalanceEvent = { listeners: Array<() => void>, query: string, from: number, to: number, type: "schedule" | "checkIn" }
