import classNames from "classnames";
import React from "react";
import { useFormContext, useFormState } from "react-hook-form";
import styled, { css, useTheme } from "styled-components";
import { StyledButton } from "../../Components/Button/Button";
import { MenuStyled } from "../../Components/Dropdown/Dropdown";
import { Flex, FlexItem } from "../../Components/Flex";
import { isThemeColor } from "../../Css";
import { StyledToggleInput } from "../../Forms/ToggleInput/ToggleInput";
import { shareRef, useFieldHook, useGroupBodyProps } from "../../index";
const InternalInput = React.forwardRef(function Input(props, ref) {
    const bodyProps = useGroupBodyProps();
    const bootstrap = props.bootstrap ?? bodyProps?.bootstrap ?? true;
    if (props.type === "range") {
        return React.createElement("span", { style: { height: "20px", width: "100%" } },
            React.createElement(StyledRange, { ref: ref, ...props, variant: props.variant ?? "primary", autoComplete: "off" }));
    }
    return React.createElement(InputClasses, { className: "expand" },
        bootstrap ? React.createElement("input", { ref: ref, ...props, "data-1p-ignore": true, className: classNames("form-control", props.className) }) : React.createElement(StyledInput, { tickVariant: props.tickVariant, ref: ref, ...props, autoComplete: "off", "data-1p-ignore": true }),
        React.createElement(InternalBorder, { variant: props.variant ?? "primary" }),
        !bootstrap && React.createElement(Ticks, { tickVariant: props.tickVariant }),
        !bootstrap && React.createElement(TicksAnimation, { variant: props.variant ?? "primary" }));
});
const InternalDisplay = React.forwardRef(function InternalDisplay(props, ref) {
    const theme = useTheme();
    return React.createElement(InputClasses, { className: "expand" },
        React.createElement("div", { style: {
                borderBottom: "1px solid #ddd",
                padding: "4px 4px 0px 7px",
                width: "100%",
                fontSize: "1.25rem",
                color: props.disabled ? theme.fontMuted : undefined,
                background: props.disabled ? "rgba(239, 239, 239, 0.3)" : undefined,
            } }, props.children),
        React.createElement(InternalBorder, null),
        React.createElement(Ticks, { tickVariant: props.tickVariant }),
        React.createElement(TicksAnimation, { variant: props.variant }));
});
const InternalHookInput = React.forwardRef(function HookInput(props, ref) {
    const bodyProps = useGroupBodyProps();
    const bootstrap = props.bootstrap ?? bodyProps?.bootstrap ?? true;
    const { name } = useFieldHook() ?? {};
    const finalName = name ?? props.name;
    const { control, register } = useFormContext() ?? {};
    const stateProps = control ? { control: control } : null;
    const { errors, isSubmitting } = useFormState(stateProps) ?? {};
    const variant = errors[finalName] ? "danger" : undefined;
    const registration = register(finalName);
    if (props.type === "range") {
        return React.createElement("span", { style: { height: "20px", width: "100%" } },
            React.createElement(StyledRange, { disabled: isSubmitting, ref: shareRef(ref, registration.ref), ...register(finalName), ...props, variant: props.variant ?? "primary", autoComplete: "off" }));
    }
    return React.createElement(InputClasses, { className: "expand", variant: variant },
        bootstrap ? React.createElement("input", { disabled: isSubmitting, ref: shareRef(ref, registration.ref), ...registration, ...props, onChange: async (e) => {
                await registration.onChange(e);
                props.onChange?.(e);
            }, className: "form-control" }) : React.createElement(StyledInput, { tickVariant: props.tickVariant, disabled: isSubmitting, ...registration, ...props, ref: shareRef(ref, registration.ref), autoComplete: "off", onChange: async (e) => {
                await registration.onChange(e);
                props.onChange?.(e);
            } }),
        React.createElement(InternalBorder, { variant: variant }),
        React.createElement(Ticks, { tickVariant: props.tickVariant }),
        React.createElement(TicksAnimation, { variant: variant }));
});
const Ticks = styled.div `
	::before {
		content: "";
		display: block;
		position: absolute;
		height: 8px;
		width: 1px;
		left: 0;
		bottom: 0px;
		background: ${props => props.tickVariant ? isThemeColor(props.tickVariant) ? props.theme.color[props.tickVariant][500].main : "#dddddd" : "#dddddd"};
	}
	::after {
		content: "";
		display: block;
		position: absolute;
		height: 8px;
		width: 1px;
		right: 0px;
		bottom: 0px;
		background: ${props => props.tickVariant ? isThemeColor(props.tickVariant) ? props.theme.color[props.tickVariant][500].main : "#dddddd" : "#dddddd"};
	}
`;
const TicksAnimation = styled.div `
	transform: scaleY(0);
	transition: transform .1s cubic-bezier(0.77, 0, 0.175, 1);
	transition-delay: 0s;
	transform-origin: 50% 0%;
	display: block;
	width: 100%;
	position: absolute;
	::before {
		content: "";
		display: block;
		position: absolute;
		height: 8px;
		width: 1px;
		left: 0;
		bottom: 0px;
		background: ${p => p.variant ? isThemeColor(p.variant) ? p.theme.color[p.variant][500].main : p.variant : p.theme.color["primary"][500].main};
	}
	::after {
		content: "";
		display: block;
		position: absolute;
		height: 8px;
		width: 1px;
		right: 0px;
		bottom: 0px;
		background: ${p => p.variant ? isThemeColor(p.variant) ? p.theme.color[p.variant][500].main : p.variant : p.theme.color["primary"][500].main};
	}
`;
const InternalBorder = styled.div `
	display: block;
	position: absolute;
	height: 1px;
	width: 100%;
	top: 100%;
	background: ${p => p.variant ? isThemeColor(p.variant) ? p.theme.color[p.variant][500].main : p.variant : p.theme.color["primary"][500].main};
	transform: scaleX(0);
	transition: transform .1s cubic-bezier(0.77, 0, 0.175, 1);
	transition-delay: .1s;
	transform-origin:  50% 0%;
`;
const StyledInput = styled.input `
	&:not([type]),
	&[type=text],
	&[type=search],
	&[type=tel],
	&[type=number],
	&[type=url],
	&[type=email],
	&[type=password] {
		border: none;
		border-bottom: 1px solid  ${props => props.tickVariant ? isThemeColor(props.tickVariant) ? props.theme.color[props.tickVariant][500].main : "#dddddd" : "#dddddd"};
		// background: rgb(180 180 180 / 8%);
		padding: 4px 4px 0px 7px;
		// padding-left: 0;
		width: 100%;
		font-size: 1.25rem;

		&:hover {
			outline: none;
			// border-bottom-color: #cccccc;
			background: rgb(180 180 180 / 5%);
		}

		&:focus {
			outline: none;
			// background: rgb(180 180 180 / 12%);
		}
		&:disabled {
			color: #5d5d5d;
		}
	}

	&[type=color] {
		padding: 0;
	}

	::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		color: #5d5d5d;
		opacity: .5; /* Firefox */
	}
`;
const StyledRange = styled.input `
	-webkit-appearance: none;
	width: 100%;
	height: 19px;
	background: #e0e0e0;
	outline: none;
	opacity: 0.7;
	-webkit-transition: .2s;
	transition: opacity .2s;
	border-radius: 2rem;
	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 14px;
		height: 14px;
		border-radius: 2rem;
		background: ${e => e.theme.color[e.variant] ? e.theme.color[e.variant][500].main : e.variant};
		cursor: pointer;
	}

	&::-moz-range-thumb {
		width: 14px;
		height: 14px;
		border-radius: 2rem;
		background: white;
		cursor: pointer;
	}
`;
const InputClasses = styled.div `
	position: relative;
	${StyledInput}:focus + ${InternalBorder}, .expand:focus ${InternalBorder} {
		transition-delay: 0s;
		transform: scaleX(1);
	}

	${p => p.variant ? css `
	${StyledInput} + ${InternalBorder}, .expand ${InternalBorder} {
		transform: scaleX(1);
	}
	` : null}


	${StyledInput}:focus + ${InternalBorder} + ${Ticks} + ${TicksAnimation}, .expand:focus ${TicksAnimation} {
		transition-delay: .1s;
		transform: scaleY(1);
	}
`;
const Group = styled(Flex) `
${InputClasses} {
	flex: 1;
}
.css-1nlnmir-container {
	width: 100%;
}
`;
const InternalInputGroup = (props) => {
    const p = useGroupBodyProps();
    const bootstrap = props.bootstrap ?? p.bootstrap ?? true;
    if (bootstrap) {
        return React.createElement("div", { className: "input-group", onClick: props.onClick, style: props.style }, props.children);
    }
    return React.createElement(Group, { grow: true, ...props, style: { height: 24, width: "100%", ...(props.style ?? {}) } }, props.children);
};
const InnerAddon = (props) => {
    const p = useGroupBodyProps();
    const bootstrap = props.bootstrap ?? p.bootstrap ?? true;
    if (bootstrap) {
        return React.createElement(BootstrapAddon, { ...props, className: classNames(props.className, "input-group-addon"), style: { padding: "0px 12px", ...props.style } });
    }
    return React.createElement(StyledAddon, { ...props });
};
const BootstrapAddon = styled(FlexItem) `
${StyledButton} {
	border: none;
}
`;
const StyledAddon = styled(FlexItem) `
	z-index: 1;
	margin-bottom: -1px;
	border-bottom: 1px solid #ddd;
	color: rgb(120, 120, 120);
	line-height: 24px;
	min-width: 40px;
	padding: 0 12px;
	${StyledButton} {
		padding: 0px 12px;
		margin: 0px -12px;
		height: 100%;
		position: relative;
		z-index: 6;
	}
	${StyledToggleInput} {
		&{
			
		}
	}
	${MenuStyled} {
		z-index: 5;
	}
	&:first-child {
		position: relative;
		::before {
			isolation: isolate;
			z-index: 5;
			content: "";
			display: block;
			position: absolute;
			height: 8px;
			width: 1px;
			left: 0;
			bottom: 0px;
			background: #dddddd;
		}
	}
	&:last-child {
		position: relative;
		::after {
			content: "";
			isolation: isolate;
			z-index: 5;
			display: block;
			position: absolute;
			height: 8px;
			width: 1px;
			right: 0;
			bottom: 0px;
			background: #dddddd;
		}
	}
	&+& {
		position: relative;
		::before {
			isolation: isolate;
			z-index: 5;
			content: "";
			display: block;
			position: absolute;
			height: 8px;
			width: 1px;
			left: 0;
			bottom: 0px;
			background: #dddddd;
		}
	}
`;
const InputGroup = Object.assign(InternalInputGroup, {
    Addon: InnerAddon,
});
export const Input = Object.assign(InternalInput, {
    Hook: InternalHookInput,
    Group: InputGroup,
    Display: InternalDisplay,
});
Input.defaultProps = {
    autoComplete: "off",
};
