import { DateTime } from "luxon";
import React from "react";
import { Button, Flex, FlexItem, useTimezone } from "../../../Components";
import { DayPicker } from "../DayPicker";
import { DateRange } from "../util";
// TODO: Presets do not currently respect min/max/limit settings
export const StandardDateRangePresets = {
    today: {
        label: "Today",
        getRange: () => { return new DateRange(DateTime.now().startOf("day"), DateTime.now().endOf("day")); }
    },
    yesterday: {
        label: "Yesterday",
        getRange: () => { return new DateRange(DateTime.now().startOf("day").minus({ days: 1 }), DateTime.now().endOf("day").minus({ days: 1 })); }
    },
    thisWeek: {
        label: "This Week",
        getRange: () => { return new DateRange(DateTime.now().startOf("week"), DateTime.now().endOf("week")); }
    },
    lastWeek: {
        label: "Last Week",
        getRange: () => { return new DateRange(DateTime.now().startOf("week").minus({ weeks: 1 }), DateTime.now().endOf("week").minus({ weeks: 1 })); }
    },
    thisMonth: {
        label: "This Month",
        getRange: () => { return new DateRange(DateTime.now().startOf("month"), DateTime.now().endOf("month")); }
    },
    lastMonth: {
        label: "Last Month",
        getRange: () => { return new DateRange(DateTime.now().startOf("month").minus({ months: 1 }), DateTime.now().endOf("month").minus({ months: 1 })); }
    },
    last7days: {
        label: "Last 7 Days",
        getRange: () => { return new DateRange(DateTime.now().startOf("day").minus({ days: 6 }), DateTime.now().endOf("day")); }
    },
    last30days: {
        label: "Last 30 days",
        getRange: () => { return new DateRange(DateTime.now().startOf("day").minus({ days: 29 }), DateTime.now().endOf("day")); }
    },
    last90days: {
        label: "Last 90 days",
        getRange: () => { return new DateRange(DateTime.now().startOf("day").minus({ days: 89 }), DateTime.now().endOf("day")); }
    },
};
export const StandardDateRangePresetsAsArray = Array.from(Object.values(StandardDateRangePresets));
/**
 * There are some important notes when working in timezones
 * We should expect, that a incoming date is in the correct timezone. This means we need to convert it into the SYSTEM tiimezone.
 * When we put our new values out, we have them in the SYSTEM-Timezone. Thus we need to transfer them to our tz
 */
export const DateRangePicker = (props) => {
    const minDate = props.minDate ? props.minDate.startOf("day") : undefined;
    const maxDate = props.maxDate ? props.maxDate.endOf("day") : undefined;
    const tz = useTimezone(props.tz);
    const dateRangeParams = { min: minDate, max: maxDate, maxNumberOfDays: props.maxNumberOfDays };
    const modifiers = {
        selectedRange: {
            from: props.dateRange?.from?.toJSDate(),
            to: props.dateRange?.to?.toJSDate(),
        },
        selectedRangeStart: props.dateRange?.from?.toJSDate(),
        selectedRangeEnd: props.dateRange?.to?.toJSDate(),
        start: props.dateRange?.from?.toJSDate(),
        end: props.dateRange?.to?.toJSDate(),
    };
    // the date range is correct, but we need to take account the system timezones offset
    const selected = React.useMemo(() => {
        if (!props.dateRange)
            return [];
        const from = props.dateRange.start?.setZone("system", { keepLocalTime: true });
        const to = props.dateRange.end?.setZone("system", { keepLocalTime: true });
        return [{
                from: from.toJSDate(), to: to.minus({ minutes: 1 }).toJSDate(), // this is for display
            }];
    }, [props.dateRange, props.dateRange?.start, props.dateRange?.end]);
    const disabled = {
        before: minDate?.toJSDate(),
        after: maxDate?.toJSDate(),
    };
    return (React.createElement(Flex, null,
        (props.showPresets && props.presets?.length > 0) &&
            React.createElement(FlexItem, { shrink: true, style: {
                    padding: "1rem",
                    paddingRight: "0",
                    maxHeight: "200px",
                    overflowY: "auto",
                    direction: "rtl",
                } },
                React.createElement(Flex, { gap: "1rem", column: true }, props.presets.map((preset) => {
                    if (!preset.getRange().IsValid(dateRangeParams))
                        return null;
                    return (React.createElement(Button, { variant: "primary", size: "sm", outline: true, key: preset.label, onClick: (e) => {
                            let dateRange = preset.getRange().Limit(dateRangeParams);
                            if (e.shiftKey && props.dateRange.from) {
                                if (props.dateRange.from < dateRange.start) {
                                    dateRange = new DateRange(props.dateRange.from, dateRange.end).Limit(dateRangeParams);
                                }
                                else if (props.dateRange.from > dateRange.end) {
                                    dateRange = new DateRange(props.dateRange.from, dateRange.start).Limit(dateRangeParams);
                                }
                            }
                            props.onChange(dateRange);
                        } }, preset.label));
                }))),
        React.createElement(FlexItem, null,
            React.createElement(DayPicker, { className: props.className, numberOfMonths: props.numberOfMonths ?? 2, defaultMonth: props.dateRange?.start?.toJSDate() ?? new Date(), 
                /** This hides the today button from the default implementation of the DayPicker */
                footer: null, fromMonth: minDate?.toJSDate(), toMonth: maxDate?.toJSDate(), disabled: disabled, modifiers: modifiers, selected: selected, onDayClick: (date, _, e) => {
                    date = DateTime.fromJSDate(date, { zone: "system" }).setZone(tz, { keepLocalTime: true }).toJSDate(); // --> act like the date is in OUR timezone
                    if (!props.dateRange) {
                        const luxonDate = DateTime.fromJSDate(date, { zone: tz });
                        const dateRange = new DateRange(luxonDate, luxonDate).Limit(dateRangeParams);
                        const limitedRange = dateRange.Limit(dateRangeParams);
                        props.onChange(limitedRange);
                    }
                    else {
                        const luxonDate = DateTime.fromJSDate(date, { zone: tz });
                        const sameDay = props.dateRange.from.startOf("day").equals(props.dateRange?.to.startOf("day"));
                        let dateRange;
                        if (sameDay) {
                            dateRange = new DateRange(props.dateRange.from, luxonDate).Limit(dateRangeParams);
                        }
                        else {
                            dateRange = new DateRange(luxonDate, luxonDate).Limit(dateRangeParams);
                        }
                        const limitedRange = dateRange.Limit(dateRangeParams);
                        props.onChange(limitedRange);
                    }
                }, onWeekNumberClick: props.disableWeekSelect ? undefined : (_, days) => {
                    const dateRange = new DateRange(DateTime.fromJSDate(days[0]), DateTime.fromJSDate(days[6])).Limit(dateRangeParams);
                    props.onChange(dateRange);
                } }))));
};
