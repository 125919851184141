import { apiManager, FpApi } from "@tcs-rliess/fp-core";
import { uniq } from "lodash-es";

import { BaseStoreMultiSync } from "../../BaseStoreMultiSync";

export class SystemCategoryStore extends BaseStoreMultiSync<FpApi.System.SystemCategory, number> {
	protected fetchAll(): Promise<FpApi.System.SystemCategory[]> {
		return apiManager
			.getService(FpApi.System.SystemCategoryService)
			.get(this.app.ctx);
	}

	protected itemId(item: FpApi.System.SystemCategory): number {
		return item.id;
	}

	public getTypes(): string[] {
		const all = this.getAll();
		return uniq(all.map(c => c.type));
	}

	public async getType(type: string): Promise<FpApi.System.SystemCategory[]> {
		const all = this.getAll();
		return all.filter(c => c.type === type);
	}

	public getTypeSync(type: string): FpApi.System.SystemCategory[] {
		const all = this.getAll();
		return all.filter(c => c.type === type);
	}
}
