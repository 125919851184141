import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faAlignLeft, faAnchor, faAngleDoubleDown, faAngleDoubleRight, faAngleDoubleUp, faAngleLeft, faAngleUp, faArchive, faArrowAltFromLeft, faArrowAltToRight,
	faArrowDown, faArrowFromLeft, faArrowLeft, faArrowRight, faArrows, faArrowToRight, faArrowUp, faAt, faBalanceScale, faBan, faBars, faBell, faBells, faBolt,
	faBook, faBookAlt, faBoxFull, faBriefcase, faBuilding, faCalculator, faCalendar, faCalendarAlt, faCalendarCheck, faCalendarExclamation, faCaretDown,
	faCaretLeft, faCaretRight, faCartArrowDown, faCertificate, faChairOffice, faChalkboardTeacher, faChartBar, faChartLine, faChartPie, faCheck, faCheckCircle,
	faCheckSquare, faChevronDoubleLeft, faChevronDoubleRight, faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faCircle, faCity, faClipboard,
	faClipboardList, faClock, faCloudUpload, faCodeBranch, faCog, faCogs, faColumns, faComment, faCommentLines, faCommentPlus, faComments, faCommentsAlt,
	faCommentSlash, faCompressAlt, faCopy, faCreditCard, faCreditCardFront, faCrosshairs, faCrown, faCube, faCubes, faDatabase, faDigitalTachograph,
	faDoorClosed, faDotCircle, faDownload, faDrone, faDroneAlt, faEdit, faEllipsisV, faEnvelope, faExclamation, faExclamationTriangle, faExpandAlt,
	faExternalLink, faEye, faFax, faFile, faFileAlt, faFileCode, faFileExcel, faFileImage, faFileInvoice, faFilePdf, faFileSignature, faFileWord, faFilter,
	faFistRaised, faFlag, faFlask, faFolder, faFolderOpen, faFont, faGhost, faGift, faGlobe, faGlobeEurope, faGraduationCap, faHandHeart, faHandHoldingBox,
	faHandHoldingMagic, faHandPointRight, faHandshake, faHandsHelping, faHashtag, faHdd, faHeadphones, faHeadphonesAlt, faHelicopter, faHistory, faHome,
	faHospital, faHospitalAlt, faHourglassHalf, faICursor, faInbox, faInfoCircle, faInfoSquare, faKey, faLightbulb, faLink, faList, faListAlt, faLocationArrow,
	faLock, faLockOpen, faLongArrowRight, faMagnifyingGlassMinus, faMap, faMapMarker, faMapSigns, faMinus, faMinusCircle, faMinusSquare, faMobile,
	faMoneyBillAlt, faNewspaper, faPaperclip, faPaperPlane, faParagraph, faPencil, faPencilAlt, faPercent, faPhone, faPhoneArrowDownLeft, faPhoneArrowUpRight,
	faPlane, faPlus, faPlusSquare, faPrint, faProjectDiagram, faPuzzlePiece, faQuestion, faQuestionCircle, faReceipt, faRepeat, faReply, faReplyAll, faRss,
	faSave, faSearch, faShare, faShareSquare, faShield, faShieldCheck, faShip, faShoppingBag, faShoppingCart, faSigma, faSignature, faSignOut, faSignOutAlt,
	faSitemap, faSlidersH, faSpinner, faSquare, faStar, faStepBackward, faStepForward, faStickyNote, faStopwatch, faSuitcase, faSync, faTable, faTablet,
	faTabletAlt, faTachometer, faTachometerAlt, faTag, faTasks, faTh, faThList, faThumbsUp, faTicket, faTimes, faTint, faTrash, faUndo, faUnlink, faUnlock,
	faUpload, faUser, faUserCheck, faUserLock, faUsers, faUsersClass, faUsersCog, faUserSecret, faWarehouse, faWrench
} from "@fortawesome/pro-solid-svg-icons";

library.add(
	faAlignLeft, faAnchor, faAngleDoubleDown, faAngleDoubleRight, faAngleDoubleUp, faAngleLeft, faAngleUp, faArchive, faArrowAltFromLeft, faArrowAltToRight,
	faArrowDown, faArrowFromLeft, faArrowLeft, faArrowRight, faArrows, faArrowToRight, faArrowUp, faAt, faBalanceScale, faBan, faBars, faBell, faBells, faBolt,
	faBook, faBookAlt, faBoxFull, faBriefcase, faBuilding, faCalculator, faCalendar, faCalendarAlt, faCalendarCheck, faCalendarExclamation, faCaretDown,
	faCaretLeft, faCaretRight, faCartArrowDown, faCertificate, faChairOffice, faChalkboardTeacher, faChartBar, faChartLine, faChartPie, faCheck, faCheckCircle,
	faCheckSquare, faChevronDoubleLeft, faChevronDoubleRight, faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faCircle, faCity, faClipboard,
	faClipboardList, faClock, faCloudUpload, faCodeBranch, faCog, faCogs, faColumns, faComment, faCommentLines, faCommentPlus, faComments, faCommentsAlt,
	faCommentSlash, faCompressAlt, faCopy, faCreditCard, faCreditCardFront, faCrosshairs, faCrown, faCube, faCubes, faDatabase, faDigitalTachograph,
	faDoorClosed, faDotCircle, faDownload, faDrone, faDroneAlt, faEdit, faEllipsisV, faEnvelope, faExclamation, faExclamationTriangle, faExpandAlt,
	faExternalLink, faEye, faFax, faFile, faFileAlt, faFileCode, faFileExcel, faFileImage, faFileInvoice, faFilePdf, faFileSignature, faFileWord, faFilter,
	faFistRaised, faFlag, faFlask, faFolder, faFolderOpen, faFont, faGhost, faGift, faGlobe, faGlobeEurope, faGraduationCap, faHandHeart, faHandHoldingBox,
	faHandHoldingMagic, faHandPointRight, faHandshake, faHandsHelping, faHashtag, faHdd, faHeadphones, faHeadphonesAlt, faHelicopter, faHistory, faHome,
	faHospital, faHospitalAlt, faHourglassHalf, faICursor, faInbox, faInfoCircle, faInfoSquare, faKey, faLightbulb, faLink, faList, faListAlt, faLocationArrow,
	faLock, faLockOpen, faLongArrowRight, faMagnifyingGlassMinus, faMap, faMapMarker, faMapSigns, faMinus, faMinusCircle, faMinusSquare, faMobile,
	faMoneyBillAlt, faNewspaper, faPaperclip, faPaperPlane, faParagraph, faPencil, faPencilAlt, faPercent, faPhone, faPhoneArrowDownLeft, faPhoneArrowUpRight,
	faPlane, faPlus, faPlusSquare, faPrint, faProjectDiagram, faPuzzlePiece, faQuestion, faQuestionCircle, faReceipt, faRepeat, faReply, faReplyAll, faRss,
	faSave, faSearch, faShare, faShareSquare, faShield, faShieldCheck, faShip, faShoppingBag, faShoppingCart, faSigma, faSignature, faSignOut, faSignOutAlt,
	faSitemap, faSlidersH, faSpinner, faSquare, faStar, faStepBackward, faStepForward, faStickyNote, faStopwatch, faSuitcase, faSync, faTable, faTablet,
	faTabletAlt, faTachometer, faTachometerAlt, faTag, faTasks, faTh, faThList, faThumbsUp, faTicket, faTimes, faTint, faTrash, faUndo, faUnlink, faUnlock,
	faUpload, faUser, faUserCheck, faUserLock, faUsers, faUsersClass, faUsersCog, faUserSecret, faWarehouse, faWrench
);
