import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faAddressBook, faAnchor, faAngleLeft, faBan, faBars, faBook, faBookmark, faBoxesAlt, faBuilding, faCalendar, faCalendarAlt, faCaretSquareDown,
	faCertificate, faChartBar, faChartLine, faCheckSquare, faClipboard, faClock, faCog, faCommentPlus, faComments, faCommentSlash, faCopy, faCreditCard,
	faDotCircle, faDownload, faEllipsisV, faEnvelope, faExternalLinkSquare, faFile, faFileImage, faFilePdf, faGlobe, faGraduationCap, faHandPointRight,
	faHashtag, faInboxIn, faInboxOut, faIndustry, faInfinity, faKey, faLanguage, faMapSigns, faMedkit, faMobile, faMoneyBill, faPaperclip, faPencil, faPlane,
	faPlus, faProjectDiagram, faQuestionCircle, faSearch, faServer, faShieldCheck, faSigma, faSitemap, faSquare, faSuitcase, faTag, faTrash, faUpload, faUser,
	faUserCheck, faUserGraduate, faUsers, faUserTie, faWarehouseAlt, faWindowRestore, faFlag, faCrown, faCircle, faFont, faMinus, faICursor, faParagraph,
	faTint, faExchange, faAt, faHelicopter, faDrone, faDroneAlt, faUserSecret, faReply, faStar, faReplyAll, faPercent, faTicket, faGlobeEurope, faCity, faWarehouse,
	faHospital, faUsersClass, faHospitalAlt, faExternalLink, faSuitcaseRolling, faUserShield, faHeadphonesAlt, faChairOffice, faCreditCardFront, faDoorClosed,
	faMagnifyingGlassMinus
} from "@fortawesome/pro-regular-svg-icons";

library.add(
	faAddressBook, faAnchor, faAngleLeft, faBan, faBars, faBook, faBookmark, faBoxesAlt, faBuilding, faCalendar, faCalendarAlt, faCaretSquareDown,
	faCertificate, faChartBar, faChartLine, faCheckSquare, faClipboard, faClock, faCog, faCommentPlus, faComments, faCommentSlash, faCopy, faCreditCard,
	faDotCircle, faDownload, faEllipsisV, faEnvelope, faExternalLinkSquare, faFile, faFileImage, faFilePdf, faGlobe, faGraduationCap, faHandPointRight,
	faHashtag, faInboxIn, faInboxOut, faIndustry, faInfinity, faKey, faLanguage, faMapSigns, faMedkit, faMobile, faMoneyBill, faPaperclip, faPencil, faPlane,
	faPlus, faProjectDiagram, faQuestionCircle, faSearch, faServer, faShieldCheck, faSigma, faSitemap, faSquare, faSuitcase, faTag, faTrash, faUpload, faUser,
	faUserCheck, faUserGraduate, faUsers, faUserTie, faWarehouseAlt, faWindowRestore, faFlag, faCrown, faCircle, faFont, faMinus, faICursor, faParagraph,
	faTint, faExchange, faAt, faHelicopter, faDrone, faDroneAlt, faUserSecret, faReply, faStar, faReplyAll, faPercent, faTicket, faGlobeEurope, faCity, faWarehouse,
	faHospital, faUsersClass, faHospitalAlt, faExternalLink, faSuitcaseRolling, faUserShield, faHeadphonesAlt, faChairOffice, faCreditCardFront, faDoorClosed,
	faMagnifyingGlassMinus
);
