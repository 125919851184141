import { ResolveConfig } from "../Resolver";

export const SCHEDULER_RESOLVER_CONFIG: ResolveConfig = {
	pathLinkType: "fpLinkType",
	pathBase: "fpData",
	types: {
		// resource duty
		dsrdid: {
			paths: [{
				// aircraft use the old missions category
				resolvedPath: "dscatidType",
				when: i => i.linkIdType === "fpvid",
				linkType: "dscatid:old",
				linkId: "dscatidType"
			}, {
				// all other items use the new categories
				resolvedPath: "dscatidType",
				when: i => i.linkIdType !== "fpvid",
				linkType: "dscatid",
				linkId: "dscatidType"
			}, {
				// shift
				resolvedPath: "dsrsid",
				linkType: "dsrsid",
				linkId: "dsrsid",
			}, {
				resolvedPath: "link",
				linkType: "!relation",
				linkId: row => ({ type: row.linkIdType, [row.linkIdType]: parseInt(row.linkId) }),
			}],
		},
		// resource duty wish
		dsrdwid: {
			paths: [{
				// shift
				resolvedPath: "dsrsid",
				linkType: "dsrsid",
				linkId: "dsrsid",
			}],
		},
		// resource duty schedule
		dsrschid: {
			paths: [{
				// resource
				resolvedPath: "link",
				linkType: "!relation",
				linkId: row => ({ type: row.linkType, [row.linkType]: parseInt(row.linkId) }),
			}, {
				// category
				resolvedPath: "dscatid",
				linkType: "dscatid",
				linkId: "dscatid",
			}, {
				// shift
				resolvedPath: "data.dsrsid",
				linkType: "dsrsid",
				linkId: "data.dsrsid",
			}, {
				// setup
				resolvedPath: "data.dsrlsid",
				linkType: "dsrlsid",
				linkId: "data.dsrlsid",
			}, {
				// location
				resolvedPath: "data.fpdirloc",
				linkType: "fpdirloc",
				linkId: "data.fpdirloc",
			}],
		},
		// calendar event
		dseid: {
			paths: [{
				resolvedPath: "data.dscaid_customer",
				linkType: "dscaid",
				linkId: "data.dscaid_customer",
			}, {
				resolvedPath: "dscatid_category",
				linkType: "dscatid:old",
				linkId: "dscatid_category",
			}, {
				resolvedPath: "link",
				linkType: "!relation",
				linkId: row => {
					const toNumber = (id: string, type: string) => {
						if([ "fpvid", "dscalid.aircraft", "dscalid.other" ].includes(type)) {
							return +id;
						}
						return id;
					};
					const e = (row.resources?.map(r => ({ type: r.link_type, [r.link_type]: toNumber(r.link_id, r.link_type) })) ?? [])
						.filter(e => [ "dscaid", "fpvid", "dscatid", "dscatid.old", "dscalid.aircraft", "dscalid.other" ].includes(e.type));
					return e;
				},
			}, {
				resolvedPath: "state",
				linkType: "!relation",
				linkId: row => {
					const e = (row.resources ?? []).filter(e => e.link_type === "dscaid" && e.link_id).map(e => ({ type: "dscaid:state", ["dscaid:state"]: +e.link_id }));
					return e;
				},
			}, {
				linkType: "fplaid",
				linkId: "waypoints.fplaid",
			}, {
				linkType: "fplaid",
				linkId: "waypoints.enroute.fplaid",
			}, {
				resolvedPath: "data.training.dscatid_duty_type",
				linkType: "dscatid",
				linkId: "data.training.dscatid_duty_type",
			}, {
				resolvedPath: "fpdirloc",
				linkType: "fpdirloc",
				linkId: "fpdirloc"
			}, {
				resolvedPath: "certificates",
				linkType: "!relation",
				linkId: row => {
					const e = (row.resources ?? []).filter(e => e.link_type === "dscdid" && e.link_id).map(e => ({ type: "dscdid", ["dscdid"]: +e.link_id }));
					return e;
				},
			}],
		},
		// aircraft
		fpvid: {
			paths: [{
				resolvedPath: "fpdbvmid",
				linkType: "fpdbvmid",
				linkId: "fpdbvmid",
			}, {
				resolvedPath: "fpdirloc",
				linkType: "fpdirloc",
				linkId: "fpdirloc",
			}],
		},
		// setup resource
		dsrdsidRes: {
			paths: [{
				resolvedPath: "fpdbvmid",
				linkType: "fpdbvmid",
				linkId: "data.fpvid.fpdbvmid",
			}],
		},
	},
};
