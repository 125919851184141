import { EventStore, ProjectModel, ResourceStore } from "@bryntum/schedulerpro-thin";
import { apiManager, FpApi, FPEvent, TimeSpan } from "@tcs-rliess/fp-core";
import { fpLog } from "@tcs-rliess/fp-log";
import Lock from "async-lock";
import EventEmitter from "events";
import { cloneDeep, debounce, remove } from "lodash-es";
import { DateTime } from "luxon";
import { computed, observable } from "mobx";

import { FleetplanApp } from "../../FleetplanApp";
import { handleError } from "../../handleError";
import type { SchedulerState } from "../../modules/Scheduler/SchedulerState";
import { EventStreamImpact } from "../mqtt";
import { Resolver } from "../Resolver";

import {
	ActivityBuilder, AircraftLegBuilder, BaseBuilder, BuilderState, CalendarEventsBuilder, CertificateBuilder, CrewLegBuilder, HolidaySetBuilder,
	RangeBuilderState, ResourceBuilder, ResourceDutyBuilder, ResourceDutyScheduleBuilder, ResourceDutyWishBuilder, WeekendBuilder,
} from "./Builder";
import { CALENDAR_CONFIGURATION_EMPTY, CalendarConfiguration } from "./CalendarConfiguration";
import { FpResourceModel } from "./models";
import { SCHEDULER_RESOLVER_CONFIG } from "./SCHEDULER_RESOLVER_CONFIG";
import { SchedulerBackendStore } from "./SchedulerBackendStore";

/**
 * @emits "set-project-model" a new project model has been set
 * @emits "added-resources" adding new resources to an existing project model
 * @emits "loaded-range" after loading some ranges
 * @emits "removed-events" removing an event because of an event
 * @emits "added-events" adding events because of loading or a push
 */
export class SchedulerStore extends EventEmitter {
	/**
	 * ProjectModel to be handed into the bryntum calendar/scheduler
	 */
	@observable.ref public projectModel: ProjectModel;
	public readonly backendStore: SchedulerBackendStore;
	public get configuration(): CalendarConfiguration { return this.#configuration; }
	#configuration = CALENDAR_CONFIGURATION_EMPTY;

	@observable private loading = 0
	@computed public get isLoading(): boolean { return this.loading > 0; }

	private app: FleetplanApp;
	private lock = new Lock();
	private log = fpLog.child("SchedulerStore")

	private displayed: TimeSpan
	private requested: TimeSpan[] = [];
	private loaded: TimeSpan[] = [];

	private resourceBuilder: ResourceBuilder;
	private builders: BaseBuilder[];
	private controller = new AbortController();

	private schedulerState: SchedulerState;
	public get tz(): string { return this.schedulerState.tz; }

	constructor(app: FleetplanApp, schedulerState: SchedulerState) {
		super();

		this.app = app;
		this.schedulerState = schedulerState;
		this.backendStore = new SchedulerBackendStore(this.app);

		this.createProjectModel();

		// setup builders
		this.resourceBuilder = new ResourceBuilder(this, this.app);
		this.builders = [
			new ActivityBuilder(this, this.app),
			new AircraftLegBuilder(this, this.app),
			new CalendarEventsBuilder(this, this.app),
			new CrewLegBuilder(this, this.app),
			new HolidaySetBuilder(this, this.app),
			new ResourceDutyBuilder(this, this.app),
			new ResourceDutyScheduleBuilder(this, this.app),
			new ResourceDutyWishBuilder(this, this.app),
			new WeekendBuilder(this, this.app),
			new CertificateBuilder(this, this.app),
		];
	}

	private debounceQueue(builder: BaseBuilder): [(item: unknown) => void, (item: unknown) => void] {
		const queueUpdate: unknown[] = [];
		const queueDelete: unknown[] = [];

		const handlePut = debounce(() => {
			const items = queueUpdate.splice(0);
			this.handlePut(builder, items).catch(handleError);
		}, 1000);

		const handleDelete = debounce(() => {
			const idList = queueDelete.splice(0);
			this.handleDelete(builder, idList).catch(handleError);
		}, 1000);

		return [
			(item: unknown) => {
				queueUpdate.push(item);
				handlePut();
			},
			(id: unknown) => {
				queueUpdate.push(id);
				handleDelete();
			},
		];
	}

	/**
	 * Subscribe to the stores and events stream as needed.
	 * @returns cleanup functio
	 */
	public subscribe(): () => void {
		// --------------------------------------------------
		// events
		// --------------------------------------------------
		const [ eventHandlePut, eventHandleDelete ] = this.debounceQueue(new CalendarEventsBuilder(this, this.app));

		const eventPut = (item: FPEvent): void => {
			// no date
			if (item.date_start == null || item.date_end == null) return;
			// check if intersects with loaded
			const timeSpan = new TimeSpan(DateTime.fromISO(item.date_start), DateTime.fromISO(item.date_end));
			const intersects = this.loaded.some(l => l.intersects(timeSpan));
			if (intersects === false) return;

			eventHandlePut(item);
		};
		const eventDelete = (id: string): void => {
			eventHandleDelete(id);
		};
		this.app.store.event.addListener("put", eventPut);
		this.app.store.event.addListener("delete", eventDelete);

		// --------------------------------------------------
		// resource duty schedule
		// --------------------------------------------------
		const resourceScheduleEventStream = this.app.eventStream.subscribe({
			filter: { a: "fp-api/resource", s: "schedule" },
			impact: EventStreamImpact.SomeUsers,
			callback: items => {
				// check if intersects with loaded
				const idList: number[] = [];

				for (const item of items) {
					const id = parseInt(item.rs);

					switch (item.t) {
						case "delete": {
							this.app.store.resource.schedule.remove(id);
							break;
						}
						case "update": {
							const timeSpan = new TimeSpan(DateTime.fromMillis(parseInt(item.st)), DateTime.fromMillis(parseInt(item.en)));
							if (this.loaded.some(l => l.intersects(timeSpan))) {
								idList.push(id);
							}
							break;
						}
					}
				}

				// skip making the request if we have no id's
				if (idList.length === 0) {
					return;
				}

				apiManager
					.getService(FpApi.Resource.Duty.ScheduleService)
					.getIdList(this.app.ctx, { idList })
					.then(items => {
						items.forEach(item => this.app.store.resource.schedule.update(item));
						this.reapplyFilters();
					})
					.catch(handleError);
			},
		});

		const [ resourceScheduleHandlePut, resourceScheduleHandleDelete ] = this.debounceQueue(new ResourceDutyScheduleBuilder(this, this.app));
		const resourceSchedulePut = (item: FpApi.Resource.Duty.Schedule): void => { resourceScheduleHandlePut(item); };
		const resourceScheduleDelete = (id: string): void => { resourceScheduleHandleDelete(id); };
		this.app.store.resource.schedule.addListener("put", resourceSchedulePut);
		this.app.store.resource.schedule.addListener("delete", resourceScheduleDelete);

		// --------------------------------------------------
		// resource duty
		// --------------------------------------------------
		const resourceDutyEventStreamUpdate = this.app.eventStream.subscribe({
			filter: { a: "fp-api/resource", s: "duty", t: "update" },
			impact: EventStreamImpact.SomeUsers,
			callback: items => {
				const idList = items
					.filter(item => {
						const timeSpan = new TimeSpan(
							DateTime.fromMillis(parseInt(item.st)),
							DateTime.fromMillis(parseInt(item.en)),
						);
						return this.loaded.some(l => l.intersects(timeSpan));
					})
					.map(item => item.rd);

				apiManager
					.getService(FpApi.Resource.Duty.DutyService)
					.getIdList(this.app.ctx, { idList })
					.then(items => items.forEach(item => this.app.store.resource.duty.update(item)))
					.catch(handleError);
			},
		});
		const resourceDutyEventStreamDelete = this.app.eventStream.subscribe({
			filter: { a: "fp-api/resource", s: "duty", t: "delete" },
			impact: EventStreamImpact.NoNetworkReaction,
			callback: items => {
				for (const item of items) {
					this.app.store.resource.duty.remove(item.rd);
				}
			},
		});

		const [ resourceDutyHandlePut, resourceDutyHandleDelete ] = this.debounceQueue(new ResourceDutyBuilder(this, this.app));
		const resourceDutyPut = (duty: FpApi.Resource.Duty.Duty): void => { resourceDutyHandlePut(duty); };
		const resourceDutyDelete = (id: string): void => { resourceDutyHandleDelete(id); };
		this.app.store.resource.duty.addListener("put", resourceDutyHandlePut);
		this.app.store.resource.duty.addListener("delete", resourceDutyHandleDelete);

		// --------------------------------------------------
		// cleanup
		// --------------------------------------------------
		return () => {
			// events
			this.app.store.event.removeListener("put", eventPut);
			this.app.store.event.removeListener("delete", eventDelete);
			// resource schedule
			resourceScheduleEventStream();
			this.app.store.resource.schedule.removeListener("put", resourceSchedulePut);
			this.app.store.resource.schedule.removeListener("delete", resourceScheduleDelete);
			// resource duty
			resourceDutyEventStreamUpdate();
			resourceDutyEventStreamDelete();
			this.app.store.resource.duty.removeListener("put", resourceDutyPut);
			this.app.store.resource.duty.removeListener("delete", resourceDutyDelete);
		};
	}

	public getResources(linkType: string, linkId: string | number): Set<FpResourceModel> {
		return this.resourceBuilder.getResources(linkType, linkId);
	}

	public getRange(): TimeSpan {
		return new TimeSpan(
			DateTime.min(...this.loaded.map(l => l.start)),
			DateTime.max(...this.loaded.map(l => l.end)),
		);
	}

	public setConfiguration(configuration: CalendarConfiguration): void {
		if (this.lock.isBusy("store")) {
			this.log.info("called setConfiguration while store was busy!");
		}

		// abort any existing loads
		this.controller.abort("setConfiguration");
		this.controller = new AbortController();

		this.displayed = null;
		this.requested = [];
		this.loaded = [];

		this.createProjectModel();

		// set new config
		// clone it so we have our own instance no one can manipulate, also we don't leak out any thing we might set into the object
		this.#configuration = cloneDeep(configuration);
		this.builders.forEach(b => b.updateConfiguration());
	}

	public async loadRange(params: { from: DateTime; to: DateTime; }): Promise<void> {
		// set loading
		this.loading++;

		// keep a reference to the current instances of these
		const projectModel = this.projectModel;
		const signal = this.controller.signal;

		try {

			// new displayed time span
			const displayed = new TimeSpan(params.from, params.to);
			// always load at least the current month
			const request = new TimeSpan(params.from, params.to).roundTo("month");
			// drop out if we're not missing anything
			const requestZones = this.findMissingZone(this.requested, request);

			if (this.displayed != null && +this.displayed.start === +displayed.start && +this.displayed.end === +displayed.end) {
				// displayed ranged hasn't changed
				return;
			}

			await this.lock.acquire("store", async () => {
				// figure out what we need to load
				const loadingZones = this.findMissingZone(this.loaded, request);

				// -------------------------------------------------------------------------------------------------------------------------------------------------
				// resources
				// -------------------------------------------------------------------------------------------------------------------------------------------------
				if (this.displayed == null || (+this.displayed.start !== +displayed.start || +this.displayed.end !== +displayed.end)) {
					// first call (`this.displayed == null`) or displayed range changed
					// -> update resources
					this.displayed = displayed;

					const added = await this.resourceBuilder.buildRange(params.from, params.to);
					this.emit("added-resources", added);

					// added a resource, load it's events for the entire currently loaded range
					if (added.length) {
						const resourceScheduler = new Map<string, Set<FpResourceModel<unknown>>>();
						added.forEach(resource => {
							const key = `${resource.fpLinkType}:${resource.fpLinkId}`;
							if (resourceScheduler.has(key)) resourceScheduler.get(key).add(resource);
							else resourceScheduler.set(key, new Set([ resource ]));
						});

						await this.loadZones({
							projectModel: projectModel,
							resourceScheduler: resourceScheduler,
							loadingZones: this.loaded,
						}, signal);
					}
				}

				// -------------------------------------------------------------------------------------------------------------------------------------------------
				// events
				// -------------------------------------------------------------------------------------------------------------------------------------------------

				requestZones.forEach(z => this.addZone(this.requested, z));

				try {
					// load zones
					await this.loadZones({
						projectModel: projectModel,
						resourceScheduler: this.resourceBuilder.resourceScheduler,
						loadingZones: loadingZones,
					}, signal);
					this.emit("loaded-range", loadingZones);
				} catch (e) {
					this.requested = [];
					throw e;
				}
			});
		} finally {
			// decrease loading
			this.loading--;
		}
	}

	/**
	 * call `loadRange` instead
	 * 
	 * @param params 
	 * @returns 
	 */
	private async loadZones(params: {
		projectModel: ProjectModel;
		resourceScheduler: Map<string, Set<FpResourceModel>>;
		loadingZones: TimeSpan[];
	}, signal: AbortSignal): Promise<void> {
		const { projectModel, loadingZones, resourceScheduler } = params;

		if (loadingZones.length === 0) return;

		// `reapplyFilterOnAdd` and `reapplyFilterOnUpdate` heavily impact performance on adding items to a store
		// they cause a large number of filter events and renders
		// ---
		// it's not documented but it seems you can change them during runtime
		(projectModel.eventStore as any).reapplyFilterOnAdd = false;
		(projectModel.eventStore as any).reapplyFilterOnUpdate = false;

		// get events
		for (const loadingZone of loadingZones) {
			await this.loadZone({ projectModel, loadingZone, resourceScheduler }, signal);

			if (signal.aborted) continue;
		}

		// first filter
		// this will set events correctly hidden as defined by the filter
		// do this first to we don't render them, filter, hide them -> avoid have them show up for a second, and "flash" before we then hide them again
		(projectModel.eventStore as any).filter();
		// now commit, this will render the calendar
		await projectModel.commitAsync();
		// 2023-11-17 - [PR] secondary filter. If not filtered, config changes somehow won't render out all events
		(projectModel.eventStore as any).filter();

		// finally switch automatic filtering back on, AFTER we ran commit
		// commit will generate change events, each change event would trigger a full refilter
		(projectModel.eventStore as any).reapplyFilterOnAdd = true;
		(projectModel.eventStore as any).reapplyFilterOnUpdate = true;
	}

	/**
	 * call `loadRange` instead
	 * 
	 * @param params 
	 * @returns 
	 */
	private async loadZone(params: {
		projectModel: ProjectModel,
		resourceScheduler: Map<string, Set<FpResourceModel>>;
		loadingZone: TimeSpan;
	}, signal: AbortSignal): Promise<void> {
		const { projectModel, loadingZone, resourceScheduler } = params;

		// sanity check
		if (loadingZone.start > loadingZone.end) return;

		// build state
		const state = new RangeBuilderState({
			from: loadingZone.start,
			to: loadingZone.end,
			resourceScheduler,
		});

		// run builders
		await Promise.all(this.builders.map(b => b.buildRange(state)));

		// apply state to stores
		await this.applyBuilderState(projectModel, state, signal);

		// and mark as loaded
		this.addZone(this.loaded, loadingZone);
	}

	public reapplyFilters(): void {
		// 2023-11-14 - [PR] docs told us, that its possible to just call filter() on the store. Typescript definition is missing for this method.
		(this.projectModel.eventStore as any).filter();
		// 2023-11-14 - [PR] calling commit triggers a re-render of the calendar
		this.projectModel.eventStore.commit();
	}

	/**
	 * Finds parts of the given time span that are missing and not currently loaded into the state
	 * @param timeSpan 
	 * @returns 
	 */
	private findMissingZone(loaded: TimeSpan[], timeSpan: TimeSpan): TimeSpan[] {
		if (loaded.length === 0) {
			// nothing loaded, so everything is missing
			return [ timeSpan ];
		}

		const intersecting = loaded.filter(loaded => {
			return loaded.start === timeSpan.end
				|| loaded.end === timeSpan.start
				|| loaded.intersects(timeSpan);
		});

		let missing = [ timeSpan ];
		for (const loaded of intersecting) {
			missing = missing.flatMap(m => loaded.missing(m));

			if (missing.length === 0) return missing;
		}

		return missing;
	}

	private addZone(loaded: TimeSpan[], timeSpan: TimeSpan): void {
		if (loaded.length === 0) {
			// nothing loaded yet, just add this one
			loaded.push(timeSpan);
			return;
		}

		// find all loaded time spans intersecting
		const intersecting = remove(loaded, loaded => {
			return loaded.start === timeSpan.end
				|| loaded.end === timeSpan.start
				|| loaded.intersects(timeSpan);
		});

		// merge with found intersecting, and given time span into one covering all
		loaded.push(
			new TimeSpan(
				DateTime.min(timeSpan.start, ...intersecting.map(i => i.start)),
				DateTime.max(timeSpan.end, ...intersecting.map(i => i.end)),
			)
		);
	}

	private async handlePut(builder: BaseBuilder, items: unknown[]): Promise<void> {
		// this messes with things, we would try to add events (and assignments more importantly) while we don't yet have resources
		if (this.resourceBuilder.resourceScheduler == null) return;
		await this.lock.acquire("store", async () => {
			const state = new BuilderState({
				resourceScheduler: this.resourceBuilder.resourceScheduler,
			});

			await builder.handlePut(state, items);

			// no events generated
			// for example the config isn't enabled etc.
			if (state.isEmpty()) {
				return;
			}
			this.applyBuilderState(this.projectModel, state).catch(handleError);
		});
	}

	private async handleDelete(builder: BaseBuilder, idList: unknown[]): Promise<void> {
		if (this.resourceBuilder.resourceScheduler == null) return;

		await this.lock.acquire("store", async () => {
			const state = new BuilderState({
				resourceScheduler: this.resourceBuilder.resourceScheduler,
			});

			await builder.handleDelete(state, idList);
			this.emit("removed-events", idList);

			await Resolver.resolve({
				app: this.app,
				config: SCHEDULER_RESOLVER_CONFIG,
				items: [
					...state.events,
					...state.timeRanges,
					...state.resourceTimeRanges,
				],
			});

			this.projectModel.eventStore.add(state.events);
			this.projectModel.assignmentStore.add(state.assignments);
			this.projectModel.timeRangeStore.add(state.timeRanges);
			this.projectModel.resourceTimeRangeStore.add(state.resourceTimeRanges);
		});
	}

	private async applyBuilderState(projectModel: ProjectModel, state: BuilderState, signal?: AbortSignal): Promise<void> {
		this.beginBatch();
		try {
			await Resolver.resolve({
				app: this.app,
				config: SCHEDULER_RESOLVER_CONFIG,
				items: [
					// flatten events with children
					...state.events.flatMap(e => [ e, ...(e.allChildren ?? []) ]),
					...state.timeRanges,
					...state.resourceTimeRanges,
				],
			});

			// if signal was aborted, don't update project model
			if (signal != null && signal.aborted) return;

			// events ----------------------------------------------------------------------------------------------------------------------------------------------

			// FP-15525 deleting duty roster not working
			// disabled the filter, only needed when the store is `event: true`, will also need to update events, instead of just dropping them
			// currently event update via push would be lost

			// const events = state.events.filter(event => {
			// 	// we can't the same event we already added again
			// 	const existing = this.projectModel.eventStore.getById(event.id);
			// 	return existing == null;
			// });
			projectModel.eventStore.add(state.events);
			this.emit("added-events", state.events);

			// assignments -----------------------------------------------------------------------------------------------------------------------------------------
			// drop assignment that are already in the store
			// the scheduler throws an error if we try to add an assignment that already exists
			const assignments = state.assignments.filter(assignment => {
				return !projectModel.assignmentStore.isEventAssignedToResource(assignment.eventId, assignment.resourceId);
			});
			projectModel.assignmentStore.add(assignments);

			// timeRanges ------------------------------------------------------------------------------------------------------------------------------------------
			projectModel.timeRangeStore.add(state.timeRanges);
			// resourceTimeRanges ----------------------------------------------------------------------------------------------------------------------------------
			projectModel.resourceTimeRangeStore.add(state.resourceTimeRanges);
		} finally {
			this.endBatch();
		}
	}

	private createProjectModel(): void {
		this.projectModel = new ProjectModel({
			resourceStore: new ResourceStore({
				tree: true,
				reapplySortersOnAdd: true,
				reapplyFilterOnAdd: true,
				reapplyFilterOnUpdate: true,
			}),
			eventStore: new EventStore({
				// 2024-04-16 [ET] FP-15497 nested events case problems with calendar views, disabled until resolution found
				//                 https://forum.bryntum.com/viewtopic.php?t=28741
				// tree: true,
				reapplyFilterOnAdd: true,
				reapplyFilterOnUpdate: true,
			}),
		});

		this.emit("set-project-model", this.projectModel);
	}

	private beginBatch(): void {
		this.projectModel.eventStore.beginBatch();
		this.projectModel.resourceTimeRangeStore.beginBatch();
		this.projectModel.resourceStore.beginBatch();
	}

	private endBatch(): void {
		this.projectModel.eventStore.endBatch();
		this.projectModel.resourceTimeRangeStore.endBatch();
		this.projectModel.resourceStore.endBatch();
	}
}
