import Color from "color";
import { omit } from "lodash";
import React from "react";
import styled, { css, useTheme } from "styled-components";
import { getSize, isThemeColor } from "../../Css";
const COLOR_DEFAULT = "#777777";
export const LabelStyled = styled.span `
	display: inline-block;
	text-transform: uppercase;
	padding: 4px 7px;
	font-size: 0.85em;
	font-weight: 400;
	line-height: 1;
	text-align: left;
	white-space: nowrap;
	vertical-align: baseline;

	color: ${p => p.fontColor};
	& a {
		color: ${p => p.fontColor};
	}

	${p => {
    if (p.fixedWidth) {
        return css `
					width: ${getSize(p.fixedWidth)}px;
				`;
    }
    else {
        return "";
    }
}}
`;
export const Label = React.forwardRef(function Label(props, ref) {
    const passedProps = omit(props, "color", "fullWidth");
    const theme = useTheme();
    let background;
    let fontColor = "#ffffff";
    let variant;
    let gradiant = "";
    if (Array.isArray(props.variant)) {
        variant = props.variant[0];
        let l = props.variant[0];
        let r = props.variant[1];
        if (isThemeColor(props.variant[0])) {
            l = theme.color[props.variant[0]][props.shade?.[0] ?? 500].main;
        }
        if (isThemeColor(props.variant[1])) {
            r = theme.color[props.variant[1]][props.shade?.[1] ?? 500].main;
        }
        if (props.backgroundTheme === "split") {
            gradiant = `linear-gradient(to bottom right, ${l || COLOR_DEFAULT}, ${l || COLOR_DEFAULT} 50%, ${r || COLOR_DEFAULT} 50%, ${r || COLOR_DEFAULT})`;
        }
        else if (props.backgroundTheme === "striped") {
            gradiant = `repeating-linear-gradient(-45deg, ${l || COLOR_DEFAULT}, ${l || COLOR_DEFAULT} 10px, ${r || COLOR_DEFAULT} 10px, ${r || COLOR_DEFAULT} 20px)`;
        }
        else if (props.backgroundTheme === "outline") {
            gradiant = `linear-gradient(to bottom right, ${l || COLOR_DEFAULT}, ${l || COLOR_DEFAULT} 50%, ${r || COLOR_DEFAULT} 50%, ${r || COLOR_DEFAULT})`;
            background = Color("transparent");
        }
    }
    else {
        variant = props.variant;
    }
    if (isThemeColor(variant)) {
        background = Color(theme.color[variant][props.shade ?? 500].main);
        fontColor = "#ffffff";
    }
    else {
        try {
            background = Color(variant ?? COLOR_DEFAULT);
        }
        catch (e) {
            background = Color(COLOR_DEFAULT);
        }
        fontColor = background.isLight() ? "#000000" : "#ffffff";
    }
    // const borderColor = background.lightness() > 80 ? background.lightness(80) : background;
    if (props.backgroundTheme === "outline") {
        background = Color("transparent");
    }
    return React.createElement(LabelStyled, { ...passedProps, ref: ref, className: `${props.className} fp-label`, fontColor: fontColor, style: {
            background: gradiant || background.string(),
            // borderColor: borderColor.string(),
            width: props.fullWidth ? "100%" : undefined,
            ...props.style,
        } });
});
Label.defaultProps = {
    backgroundTheme: "split",
};
