var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { debounce as debouncedFunc } from "lodash";
import { action, observable } from "mobx";
import React from "react";
/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOutsideAlerter(ref, callback) {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            callback(event);
        }
    }
    React.useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });
}
export const useWidthHeight = (ref) => {
    const [width, setWidth] = React.useState(0);
    const [height, setHeight] = React.useState(0);
    React.useEffect(() => {
        setTimeout(() => {
            setWidth(ref.current.offsetWidth);
            setHeight(ref.current.offsetHeight);
        });
        const handleResize = () => {
            setWidth(ref.current.offsetWidth);
            setHeight(ref.current.offsetHeight);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [ref]);
    return { width, height };
};
/**
 * @deprecated use usePanel instead
 * @returns
 */
export function usePanel_DEPRECATED(callbacks) {
    const [params, setParams] = React.useState({ open: false, props: null });
    const closePanel = React.useCallback(() => {
        if (!params.open)
            return;
        setParams({ open: false, props: null });
        callbacks?.onClose?.(params.props);
    }, [callbacks, params.open]);
    const openPanel = React.useCallback((props) => {
        if (callbacks?.reopen && params.open) {
            setParams({ open: false, props: props });
            callbacks?.onClose?.(params.props);
            setTimeout(() => {
                setParams({ open: true, props: props });
                callbacks?.onOpen?.(props);
            }, 100);
        }
        else {
            if (params.open)
                return;
            setParams({ open: true, props: props });
            callbacks?.onOpen?.(props);
        }
    }, [callbacks, params.open]);
    return {
        params: params,
        openPanel: openPanel,
        closePanel: closePanel,
    };
}
/** class based controller instead of hook controller */
export class PanelController {
    constructor() {
        this.controllers = [];
    }
    register(controller) {
        this.controllers.push(controller);
    }
    unregister(controller) {
        this.controllers = this.controllers.filter((c) => c !== controller);
    }
    peek() {
        // each must return true to close
        return this.controllers.every((c) => c());
    }
    openPanel(props) {
    }
    closePanel() {
    }
}
__decorate([
    observable.shallow
], PanelController.prototype, "controllers", void 0);
__decorate([
    action
], PanelController.prototype, "register", null);
__decorate([
    action
], PanelController.prototype, "unregister", null);
export function usePanel(options) {
    const [params, setParams] = React.useState({ open: false, props: null });
    const [controllers, setControllers] = React.useState([]);
    const registerController = React.useCallback((controller) => {
        setControllers((controllers) => [...controllers, controller]);
    }, []);
    const unregisterController = React.useCallback((controller) => {
        setControllers((controllers) => controllers.filter((c) => c !== controller));
    }, []);
    const peek = React.useCallback(() => {
        // each must return true to close
        return controllers.every((c) => c());
    }, [controllers]);
    const closePanel = React.useCallback(() => {
        if (!params.open)
            return;
        setParams({ open: false, props: null });
        options?.onClose?.(params.props);
    }, [options, params.open]);
    const openPanel = React.useCallback((props) => {
        if (options?.reopen && params.open) {
            setParams({ open: false, props: props });
            options?.onClose?.(params.props);
            setTimeout(() => {
                setParams({ open: true, props: props });
                options?.onOpen?.(props);
            }, 100);
        }
        else {
            if (params.open)
                return;
            setParams({ open: true, props: props });
            options?.onOpen?.(props);
        }
    }, [options, params.open]);
    return {
        params: params,
        openPanel: openPanel,
        closePanel: closePanel,
        controller: {
            register: registerController,
            unregister: unregisterController,
            openPanel: openPanel,
            closePanel: closePanel,
            peek,
        },
    };
}
const UsePanelControllerContext = React.createContext(null);
export const usePanelController = () => React.useContext(UsePanelControllerContext);
export const UsePanelControllerProvider = UsePanelControllerContext.Provider;
export function useAsyncMemo(loader, dependencies, options) {
    const { onError, onResolve } = options ?? {};
    const [response, setResponse] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [hasResolved, setHasResolved] = React.useState(false);
    const version = React.useRef(0);
    const reload = React.useCallback(async () => {
        const currentVersion = ++version.current;
        setHasResolved(false);
        if (options?.requirements?.some((e) => !e)) {
            return;
        }
        try {
            const response = await loader();
            if (currentVersion !== version.current)
                return;
            setError(null);
            setResponse(response);
        }
        catch (error) {
            if (currentVersion !== version.current)
                return;
            setResponse(null);
            setError(error);
        }
        finally {
            if (currentVersion === version.current) {
                setHasResolved(true);
                onResolve?.({ response: response, error: error });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...dependencies, ...(options?.requirements ?? [])]);
    React.useEffect(() => {
        if (error) {
            if (onError)
                onError(error);
            else
                throw error;
        }
    }, [error, onError]);
    React.useEffect(() => {
        void reload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...dependencies]);
    return { response, error, hasResolved, reload };
}
/**
 * Subscribe to any window event. Hook will automatically unsubscribe on unmount.
 * @param eventType
 * @param listener
 * @param options
 */
export function useWindowEvent(eventType, listener, options) {
    React.useEffect(() => {
        function listenerWrapper(ev) {
            return listener.call(this, ev);
        }
        window.addEventListener(eventType, listenerWrapper, options);
        return () => {
            window.removeEventListener(eventType, listenerWrapper, options);
        };
    }, [eventType, listener, options]);
}
/**
 * Subscribe to any window event. Hook will automatically unsubscribe on unmount.
 * @param eventType
 * @param listener
 * @param options
 */
export function useHTMLElementEvent(target, eventType, listener, options) {
    React.useLayoutEffect(() => {
        function listenerWrapper(ev) {
            return listener.call(this, ev);
        }
        target?.addEventListener(eventType, listenerWrapper, options);
        const t = target;
        return () => {
            t?.removeEventListener(eventType, listenerWrapper, options);
        };
    });
}
/**
 * Subscribe to any window event. Hook will automatically unsubscribe on unmount.
 * @param eventType
 * @param listener
 * @param options
 */
export function useDocumentEvent(eventType, listener, options) {
    React.useEffect(() => {
        function listenerWrapper(ev) {
            return listener.call(this, ev);
        }
        document.addEventListener(eventType, listenerWrapper, options);
        return () => {
            document.removeEventListener(eventType, listenerWrapper, options);
        };
    }, [eventType, listener, options]);
}
export function useDebouncedMemo(factory, deps, debounce) {
    const [state, setState] = React.useState(factory());
    const debouncedSetState = React.useCallback(debouncedFunc(setState, debounce), []);
    React.useEffect(() => {
        debouncedSetState(factory());
    }, deps);
    return state;
}
const COUNTDOWN_TICK_INTERVAL = 1000;
export function useCountdown(params) {
    const [timeRemaining, setTimeRemaining] = React.useState(null);
    const interval = React.useRef();
    const pause = React.useCallback(() => {
        if (interval.current) {
            clearInterval(interval.current);
            interval.current = null;
        }
    }, []);
    const cancel = React.useCallback(() => {
        if (timeRemaining == null)
            return;
        if (interval.current) {
            clearInterval(interval.current);
            interval.current = null;
        }
        setTimeRemaining(null);
    }, [timeRemaining]);
    const onTick = React.useCallback(() => {
        setTimeRemaining(c => {
            const v = c - 1000;
            if (v <= 0) {
                pause();
                params?.onZero?.();
            }
            return v;
        });
    }, [pause, params?.onZero]);
    const resume = React.useCallback(() => {
        if (timeRemaining == null)
            return;
        clearInterval(interval.current);
        interval.current = setInterval(onTick, COUNTDOWN_TICK_INTERVAL);
    }, [onTick, timeRemaining]);
    const start = React.useCallback((timeRemaining = params.defaultTimeRemaining) => {
        if (!timeRemaining)
            return;
        setTimeRemaining(timeRemaining);
        clearInterval(interval.current);
        interval.current = setInterval(onTick, COUNTDOWN_TICK_INTERVAL);
    }, [onTick, params.defaultTimeRemaining]);
    const skip = React.useCallback(() => {
        if (timeRemaining == null)
            return;
        cancel();
        params?.onZero?.();
    }, [cancel, params?.onZero, timeRemaining]);
    React.useEffect(() => {
        return () => {
            if (interval.current)
                clearInterval(interval.current);
        };
    }, []);
    return {
        timeRemaining,
        /**
         * Starts the countdown with the specified time or with the default time specified in the params
         * */
        start,
        /**
         * Pauses the countdown if it is currently running
         * */
        pause,
        /**
         * Resumes the countdown if it is currently running
         * */
        resume,
        /**
         * Cancels the countdown if it is currently running without invoking the onZero callback
         * */
        cancel,
        /**
         * Stops the countdown if and only if it is currently running and then invokes the onZero callback
         * */
        skip,
    };
}
export function useDebouncedKeyValueState(initialState, delay) {
    const [state, setState] = React.useState(initialState);
    const [instantState, setInstantState] = React.useState(initialState);
    const timer = React.useRef(null);
    const setValue = React.useCallback((key, value) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        let nextState;
        setInstantState((prev) => {
            const newState = { ...prev, [key]: value };
            nextState = newState;
            return newState;
        });
        timer.current = setTimeout(() => {
            setState(nextState);
        }, delay);
    }, [delay]);
    return [state, instantState, setValue];
}
// type Build<T, K extends (keyof T)[]> = Pick<T, K[number]>;
// function genericSearch<T extends Record<string, unknown>, K extends keyof T>(query: any, fields: Array<K>): Pick<T, K>[] {
// 	return [];
// }
// const test = genericSearch({ a: 1, b: 2 }, [ "a" ]);
