import { apiManager, DirectoryNode, FpApi, FpDirNodeKind, GetReadsResponse, TreeUtil } from "@tcs-rliess/fp-core";
import { QUERY_CACHE } from "@tcs-rliess/fp-query";
import { cloneDeep } from "lodash-es";
import { action, computed, observable } from "mobx";
import React from "react";

import { FleetplanApp } from "../../../FleetplanApp";
import { handleError } from "../../../handleError";
import { FpReadsClientDataProcessor } from "../../../lib";

export interface TreeNode extends Omit<DirectoryNode, "data" | "children"> {
	expanded: boolean;
	active: boolean;
	parentid: number;
	typeSysCat?: string;
	ignore?: boolean;
	data?: {
		parentid?: number;
		dssid?: number;
	}
	children?: TreeNode[];
	$revision?: FpApi.ControlledDocument.Revision;
}

export interface DockedViewState {
	type: string;
	action: string;
	id?: number;
	props?: any;
}

type PermissionScope = "reader" | "author" | "editor" | "edit_permissions" | "manage_menu" | "read_tracking" | "manager" | "dsmanualmanager" | "copy" | "delete";

interface GetDssidResponse {
	rows: Array<{
		dscid: number;
		dsmid: number;
		dssid: number;
		permissions: Array<FpApi.Security.Policy>;
	}>;
	scope: "permissions";
	type: "list";
}

interface Permission {
	dscid: number;
	dssid: number;
	dsmid: number;
	permissions: Array<FpApi.Security.Policy>;
}

export const ControlledDocumentStateContext = React.createContext<ControlledDocumentState>(null);
export class ControlledDocumentState {
	private app: FleetplanApp;

	public initialLoadComplete = false;
	public directoryId: number = null;
	public defaultDirectoryParentId: number = null;
	private assignableInDirectory = null; // we need to preserve this in case we delete this (because its empty) and need to re-create it!
	private readsClient: FpReadsClientDataProcessor;
	@observable public util: TreeUtil<TreeNode, number>;
	@observable.ref workflows: { [id: number]: FpApi.ControlledDocument.ArticleWorkflowState[] } = {};
	@observable.ref documents: { [id: number]: FpApi.ControlledDocument.Document } = {};
	@observable.shallow reads: { [key: number]: GetReadsResponse["reads"] } = {};
	@observable.shallow hasToRead: { [key: number]: FpApi.ControlledDocument.RevisionRead[] } = {};
	@observable.shallow permissions: { [key: number]: Permission } = {};

	@observable private loadingCtr = 0;
	@computed get loading(): boolean {
		return this.loadingCtr > 0;
	}
	@action
	setLoading(loading: boolean): void {
		if (loading) {
			this.loadingCtr++;
		} else {
			this.loadingCtr--;
		}
	}

	@action
	setWorkflows(workflows: { [id: number]: FpApi.ControlledDocument.ArticleWorkflowState[] }): void {
		this.workflows = workflows;
	}

	public directoryTree;

	@observable activeNode: TreeNode;
	@action.bound public setActiveNode(id: number): void {
		const oldNode = this.util.findByProperty("active", true);
		if (oldNode) this.util.setNode(oldNode.id, "active", false);

		// expand all nodes for the user's convenience
		this.util.findParents(id).forEach((node) => {
			this.util.setNode(node.id, "expanded", true);
		});

		// set node to active
		this.util.setNode(id, "active", true);
		this.refreshTree();

		this.activeNode = this.util.findKey(id);
	}

	// docked views
	@observable public dockedView: DockedViewState[] = [];
	@action.bound public setDockedView(dv: DockedViewState): void {
		const existingDV = this.dockedView.findIndex(e => e.type === dv.type);
		if (existingDV > -1) {
			this.dockedView[existingDV] = dv;
		} else {
			this.dockedView.push({
				...dv,
			});
		}
	}
	@action.bound public closeDockedView(type: string): void {
		const idx = this.dockedView.findIndex(v => v.type === type);
		if (idx > -1) this.dockedView.splice(idx, 1);
	}

	constructor(app: FleetplanApp) {
		this.app = app;
		this.readsClient = new FpReadsClientDataProcessor(app, { use: "ControlledDocumentState" });

		QUERY_CACHE.addListener("delete", key => {
			if (key.startsWith("FpReadsClient.getReads")) {
				this.reads = {};
			}
		});
	}

	async reload() {
		this.setLoading(true);

		await this.createUtil(null, null, true);

		// delete "Unassigned" if its empty!
		const unassignedItem = this.util.findByProperty("name", "Unassigned");
		if (unassignedItem && !unassignedItem.children.length) {
			this.assignableInDirectory = cloneDeep(unassignedItem);
			this.util.removeNodeByKey(unassignedItem.id);
		}

		// sort and refresh after creating
		this.refreshTree();

		this.setLoading(false);
	}

	async ensureUnassigned() {
		const unassignedItem = this.util.findByProperty("name", "Unassigned");
		if (!unassignedItem && this.assignableInDirectory) {
			this.util.insertUnderParent(this.assignableInDirectory, 0);
		} else if (!unassignedItem && !this.assignableInDirectory) {
			await this.addEditNode({
				name: "Unassigned",
				parentid: this.defaultDirectoryParentId,
				kind: FpDirNodeKind.Group,
				expanded: false,
				active: false,
				id: null,
				data: {},
				type: "dscatid:-1"
			});
		}
	}

	// tree related
	async init() {
		this.initialLoadComplete = true;
		this.setLoading(true);

		await this.createUtil(null, null, true);

		// if nothing is active, set overview to active (overview = id 0)
		if (!this.util.findByProperty("active", true)) {
			this.setActiveNode(0);
		}

		// delete "Unassigned" if its empty!
		const unassignedItem = this.util.findByProperty("name", "Unassigned");
		if (unassignedItem && !unassignedItem.children.length) {
			this.assignableInDirectory = cloneDeep(unassignedItem);
			this.util.removeNodeByKey(unassignedItem.id);
		}

		// sort and refresh after creating
		this.refreshTree();

		// side effects for the grid
		this.setLoading(false);
	}

	async createUtil(archived = null, permissions = null, force = null): Promise<void> {
		this.util = new TreeUtil<TreeNode, number>([
			{
				id: 0,
				name: "Overview",
				type: "OVERVIEW",
				kind: FpDirNodeKind.Group,
				expanded: false,
				active: false,
				parentid: 0,
				// parent: null
			},
			...await this.generateTree(archived, permissions, force)
		], {
			getKey: (e) => e.id,
			getChildren: (e) => e.children,
			compare: (a, b) => {
				if (a.type === "OVERVIEW" && b.type !== "OVERVIEW") return -1;
				if (a.type !== "OVERVIEW" && b.type === "OVERVIEW") return 1;

				if (a.type === "CONTROLLED_DOCUMENT" && b.type !== "CONTROLLED_DOCUMENT") return -1;
				if (a.type !== "CONTROLLED_DOCUMENT" && b.type === "CONTROLLED_DOCUMENT") return 1;

				// sort by name
				if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
				if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
			}
		});
	}

	// sort and re-render
	public refreshTree(): void {
		this.util.sort();
		this.util = this.util.cloneShallow();
	}

	public getReadState(dscaid: number, dssid: number): GetReadsResponse["reads"]["0"]["documents"]["0"] {
		return this.reads[dscaid] && this.reads[dscaid].length ? this.reads[dscaid][0]?.documents.find(e => e.id === dssid) : null;
	}

	async loopNodes(nodes: TreeNode[]) {
		for await (const node of nodes) {
			if (node.type === "CONTROLLED_DOCUMENT" || node.data.dssid) {
				const doc = this.documents[node.data.dssid];
				if (doc) {
					node.name = doc.shortName && doc.shortName.length ? doc.shortName : doc.name;
					node.$revision = doc.$currentRevision;
				} else {
					node.ignore = true;
					node.name = `document id: ${node.data.dssid}`;
				}
			} else {
				if (node.children) await this.loopNodes(node.children);
			}
		}
	}

	async loadDirectory(): Promise<TreeNode[]> {
		// abort if no directory found
		if (this.app.customer.fpdir.document == null) return [];

		// find tree for controlled documents
		this.directoryId = this.app.customer.fpdir.document;
		let directoryTree = (await (await fetch(`/api/dir/trees/${this.app.ctx.dscid}/${this.directoryId}`)).json()).tree;

		// documents module: only groups are relevant
		const directoryRootElement = directoryTree.find(e => e.type === "SYSTEM" && e.kind === "GROUP");
		this.defaultDirectoryParentId = directoryRootElement.id;
		directoryTree = directoryRootElement.children ?? [];

		await this.fetchPermissions();

		await this.fetchDocuments();

		await this.fetchHasToRead();

		await this.loopNodes(directoryTree);

		return directoryTree;
	}

	// function is used to filter out archived children
	private generateTreeChildren(node: TreeNode, archived = false, permissions?: Permission[]) {
		const children = node.children?.filter(c => {
			return this.isAllowedNode(c, permissions);
		});

		return {
			...node,
			children: children.map(c => this.generateTreeChildren(c, archived, permissions))
		};
	}

	private isAllowedNode(node: TreeNode, permissions: Permission[]): boolean {
		if (node.type === "CONTROLLED_DOCUMENT" || node.data?.dssid) {
			if (permissions) {
				return this.findPermissionInPermissions(permissions, node.data.dssid, "reader");
			}

			return this.isAllowed(node.data.dssid, "reader");
		}

		return true;
	}

	async generateTree(archived = false, permissions?: Permission[], force?: boolean): Promise<TreeNode[]> {
		if (!this.directoryTree || force) this.directoryTree = await this.loadDirectory();

		// get all root objects from certificate directory
		const cleanedDirectory = this.directoryTree.reduce((curr, node) => {
			if (node.ignore) return curr;
			if (node.data.archived && (node.data.archived !== archived)) return curr;

			if (!node.data.parentid) {
				curr.push(this.generateTreeChildren(node, archived, permissions));
			}

			return curr;
		}, []) as TreeNode[];

		// get all root objects from main directory and combine it with items from the certificate directory
		const generateItems = async (items): Promise<TreeNode[]> => {
			return await Promise.all(items.filter(el => el.kind === "GROUP" && el.type.startsWith("dscatid:")).map(async (el) => {
				const sysCat = await this.app.store.systemCategory.getId(+el.type.split(":")[1]);
				return {
					...el,
					typeSysCat: sysCat.idName,
					children: [
						...await generateItems(el.children),
						...this.directoryTree.filter(d => ((d.data.parentid && d.data.parentid === el.id) || d.parentid === el.id) && ((d.data.archived && (d.data.archived === archived)) || !d.data.archived)).map(e => this.generateTreeChildren(e, archived, permissions)).filter(e => e && !e.ignore)
					]
				};
			}));
		};

		const otherItems = await generateItems(this.app.directory.find(e => e.type === "SYSTEM" && e.kind === "GROUP").children);

		const directoriesMerged = [ ...cleanedDirectory, ...otherItems ];

		return directoriesMerged;
	}

	getTree(userPermissions: Permission[]) {
		return this.util?.filterBottomUp((node) => this.isAllowedNode(node, userPermissions), "children");
	}

	@action
	async fetchDocuments(): Promise<any> {
		try {
			const docs = await this.app.store.controlledDocument.document.getAll();

			const dssridList = docs
				.filter(d => d.$approvalRevision != null)
				.map(d => d.$approvalRevision.dssrid);

			try {
				const workflows = await apiManager.getService(FpApi.ControlledDocument.WorkflowService).getArticleState(this.app.ctx, { dssridList });
				const wflows = Object.entries(workflows).reduce((sum, [ id, state ]) => {
					sum[+id] = state;

					return sum;
				}, {} as { [id: number]: FpApi.ControlledDocument.ArticleWorkflowState[] });

				this.setWorkflows(wflows);
			} catch (err) {
				handleError(err);
			} finally {
				for (const doc of docs) {
					this.documents[doc.dssid] = doc;
				}

				// trigger effects
				this.documents = { ...this.documents };
			}
		} catch (err) {
			handleError(err);
		}
	}

	async fetchSingleDocument(dssid): Promise<FpApi.ControlledDocument.Document> {
		try {
			const service = apiManager.getService(FpApi.ControlledDocument.DocumentService);
			const doc = await service.getId(this.app.ctx, {
				dssid: dssid
			});

			this.documents[doc.dssid] = doc;
			return doc;
		} catch (err) {
			handleError(err);
		}
	}

	async fetchHasToRead(dscaid = this.app.ctx.dscaid): Promise<any> {
		try {
			// this.setLoading(true);
			const service = apiManager.getService(FpApi.ControlledDocument.ReadService);
			const hasToRead = await service.get(this.app.ctx, {
				dscaidList: [ dscaid ],
			});

			this.hasToRead[dscaid] = hasToRead;

			// read tracking
			if (this.hasToRead[dscaid].length) {
				const readIds = this.hasToRead[dscaid].map(e => e.dssrid);
				const readTrackingItems = Object
					.values(this.documents)
					.filter(e => e.$currentRevision && readIds.includes(e.$currentRevision.dssrid) && e.settings.readTracking.enabled)
					.map(e => ({ id: e.dssid, _r: e.$currentRevision?.dssrid }));

				if (readTrackingItems.length) {
					this.reads[dscaid] = await this.fetchReads([ dscaid ], readTrackingItems);
				}
			}
		} catch (err) {
			console.error(err);
		} finally {
			// this.setLoading(false);
		}
	}

	async getReads(dscaids: number[]): Promise<Array<{ dscaid: number, reads?: GetReadsResponse["reads"] }>> {
		for await (const dscaid of dscaids) {
			if (!this.reads[dscaid]) await this.fetchHasToRead(dscaid);
		}

		return dscaids.map((dscaid) => ({ dscaid, reads: this.reads[dscaid] }));
	}

	async fetchReads(users: number[], documents: Array<{ id: number, _r: number }>): Promise<GetReadsResponse["reads"]> {
		const response = await this.readsClient.getReads({
			params: {
				documents: documents as any,
				includeRead: true,
				includeUnread: true,
				users: users,
			},
			cache: {
				maxAge: Infinity,
			},
		});

		return response.reads;
	}

	async fetchPermissions(): Promise<void> {
		const resp = await fetch("/api/cd/permissions");

		for (const permission of (await resp.json()).rows) {
			this.permissions[permission.dssid] = permission;
		}
	}

	async fetchDssidPermissions(dssid: number): Promise<FpApi.Security.Policy[]> {
		const resp = await fetch(`/api/cd/permissions/${dssid}`);
		const json: GetDssidResponse = await resp.json();

		if (json.rows?.length) {
			return json.rows.find(e => e.dssid === dssid)?.permissions;
		} else {
			return [];
		}
	}

	async fetchDscaidPermissions(dscaid: number): Promise<Permission[]> {
		const resp = await fetch(`/api/cd/permissions/dscaid/${dscaid}`);
		const json = await resp.json();

		if (json.rows?.length) {
			return json.rows;
		} else {
			return [];
		}
	}

	// folder related
	public async addEditNode(folder: TreeNode, isEdit?: boolean, ignore?: boolean): Promise<void> {
		if (!this.app.ctx.hasRole("dsmanualmanager")) return alert("Cannot add/edit node: You need the role 'dsmanualmanager'");
		this.setLoading(true);

		// create different objects depending on action
		const res = await fetch(`/api/dir/trees/${this.app.ctx.dscid}/${this.directoryId}`, {
			method: isEdit ? "PUT" : "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				trees_item: folder
			})
		});

		const json = await res.json();
		if (json.status && json.status !== 200) {
			return handleError(json);
		}

		let node = {
			...folder,
			id: json.rows[0].id,
			children: []
		};

		// delete the old node in the tree to re-create it
		if (isEdit) {
			this.util.walk((e) => {
				if (e.node.children) {
					// move node
					const childIndex = e.node.children.findIndex(e => e.id === node.id);
					if (childIndex > -1) {
						// re-assign children to node!
						node = {
							...node,
							children: e.node.children[childIndex].children
						};

						e.node.children.splice(childIndex, 1);
					}
				}
			});
		}

		if (!ignore) {
			if (node.type === "CONTROLLED_DOCUMENT" || node.data.dssid) {
				const doc = this.documents[node.data.dssid];
				if (doc) {
					node.name = doc.shortName && doc.shortName.length ? doc.shortName : doc.name;
					node.$revision = doc.$currentRevision;
				} else {
					node.name = `document id: ${node.data.dssid}`;
				}
			}

			// create new node in tree
			this.util.insertUnderParent(node, node.data?.parentid ?? node.parentid);

			// expand all nodes for the user's convenience
			this.util.findParents(node.id).forEach((node) => {
				this.util.findKey(node.id).expanded = true;
			});
		}

		// refresh tree
		if (isEdit || !ignore) this.refreshTree();

		this.setLoading(false);
	}

	public async deleteNode(id: number): Promise<void> {
		this.setLoading(true);
		return fetch(`/api/dir/trees/${this.app.ctx.dscid}/${this.directoryId}/${id}?editor=${this.app.ctx.dscaid}`, {
			method: "DELETE"
		})
			.then(() => {
				this.util.removeNodeByKey(id);

				// refresh tree
				this.refreshTree();
			})
			.catch(handleError)
			.finally(() => this.setLoading(false));
	}

	public async deleteDocument(node: TreeNode): Promise<void> {
		const document = this.documents[node.data.dssid];
		if (!document) return alert("Delete: Document not found");

		const formData = new FormData();
		formData.append("_method", "delete");

		try {
			const result = await fetch(`/dynasite.cfm?dscmd=manual_manual_manual_api&dssid=${document.dssid}`, {
				method: "POST",
				body: formData
			});

			if (result.status !== 200 && result.status === 400) {
				return alert("Please delete Document in Mobile Directory first!");
			}

			await this.deleteNode(node.id);

			await this.putPermissions(document.dssid, document.dsmid, []);

			delete this.documents[node.data.dssid];
		} catch (err) {
			handleError(err);
		}
	}

	public async putPermissions(dssid: number, dsmid: number, permissions): Promise<void> {
		const b = await fetch(`/api/cd/permissions/${dssid}?dsmid=${dsmid}`, {
			method: "PUT",
			body: JSON.stringify(permissions),
			headers: {
				"Content-Type": "application/json"
			}
		});

		const json = await b.json();

		// set permissions in store
		this.permissions[dssid] = json.rows[0];

		return json;
	}

	public async copySite(dssid: number): Promise<boolean> {
		try {
			await fetch(`/dynasite.cfm?dscmd=site_site_site_copyconf&siteid=${dssid}`, {
				method: "POST"
			});

			return true;
		} catch (err) {
			handleError(err);
			return false;
		}
	}

	public nodeTypeToString(type: string) {
		return type === "FOLDER" ? "Folder" : type === "FILE" ? "File Node" : type === "CONTROLLED_DOCUMENT" ? "Document" : "";
	}

	isAllowedBypass() {
		if (this.app.ctx.isSuperUser) return true;
		if (this.app.ctx.hasProjectPermission(FpApi.Security.ProjectPermission.GlobalManager)) return true;
		// if (this.app.ctx.hasRole("dsmanualmanager")) return true;

		return false;
	}

	isAllowed(dssid: number, scope: PermissionScope): boolean {
		if (this.isAllowedBypass()) return true;

		const flag = this.findHighestPermission("dssid", dssid);

		return this.checkPermissionScope(flag, scope);
	}

	isAllowedDsmid(dsmid: number, scope: PermissionScope): boolean {
		if (this.isAllowedBypass()) return true;

		const flag = this.findHighestPermission("dsmid", dsmid);

		return this.checkPermissionScope(flag, scope);
	}

	private checkPermissionScope(flag: number, scope: PermissionScope): boolean {
		if (scope === "reader") {
			if (this.isAllowedBitmaskCheck(flag, FpApi.Security.PermissionSiteLvl.Reader)) return true;
		} else if (scope === "author") {
			if (this.isAllowedBitmaskCheck(flag, FpApi.Security.PermissionSiteLvl.Author)) return true;
		} else if (scope === "editor") {
			if (this.isAllowedBitmaskCheck(flag, FpApi.Security.PermissionSiteLvl.Editor)) return true;
		} else if (scope === "read_tracking") {
			if (this.isAllowedBitmaskCheck(flag, FpApi.Security.PermissionSiteLvl.ReadTracking)) return true;
		} else if (scope === "manager") {
			if (this.isAllowedBitmaskCheck(flag, FpApi.Security.PermissionSiteLvl.Manager)) return true;
		} else if (scope === "edit_permissions") {
			return this.app.ctx.hasRole("dssecurity");
		} else if (scope === "dsmanualmanager") {
			return this.app.ctx.hasRole("dsmanualmanager");
		} else if (scope === "manage_menu") {
			return this.app.ctx.hasRole("dsmenu") && this.app.ctx.hasRole("dsmanualmanager");
		} else if (scope === "delete") {
			return this.app.ctx.hasRole("dsmenu") && this.app.ctx.hasRole("dsmanualmanager") && this.app.ctx.hasRole("dssecurity");
		} else if (scope === "copy") {
			return this.app.ctx.hasRole("dsmanualmanager") && this.isAllowedBitmaskCheck(flag, FpApi.Security.PermissionSiteLvl.Manager);
		}

		return false;
	}

	private findHighestPermission(key: "dssid" | "dsmid", value) {
		const permissions = key === "dssid" ? this.permissions[value] : Object.values(this.permissions).find(e => e[key] === value);
		if (!permissions) return 0;

		return this.app.ctx.getPermission("dssid", value.toString(), { policies: permissions.permissions });
	}

	public findPermissionInPermissions(permissions: Permission[], dssid: number, scope: PermissionScope) {
		const found = permissions.find(e => e.dssid === dssid);

		if (found) {
			const max = Math.max(0, ...found?.permissions.map(e => e.action));
			return this.checkPermissionScope(max, scope);
		}

		return false;
	}

	isAllowedBitmaskCheck(flag: number, lvl: number): boolean {
		return ((flag & lvl) === lvl);
	}
}
