import { ApiError } from "@tcs-rliess/fp-core";
import { observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";

import { handleError } from "../handleError";

@observer
export class ErrorBoundary extends React.Component<{ children?: React.ReactNode; }> {
	@observable
	private error: Error;
	private errorInfo: React.ErrorInfo;

	@observable
	private showDetails = false;

	public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
		this.error = error;
		this.errorInfo = errorInfo;

		handleError(new ApiError(
			0,
			"REACT_ERROR_BOUNDARY",
			error.message,
			{ error, errorInfo },
		));
	}

	public render(): React.ReactNode {
		if (this.error) {
			return <div className="alert alert-danger" style={{ "border": "1px dashed", "margin": 0, "padding": "7px" }}>
				<h4 style={{ margin: 0 }}>
					🤕 Something went wrong&nbsp;
					<small>
						<a
							onClick={() => this.showDetails = !this.showDetails}
							style={{ cursor: "pointer" }}
						>
							{this.showDetails ? "hide details..." : "show details..."}
						</a>
					</small>
				</h4>

				{this.showDetails ? (<>
					<pre><code>{this.error.stack}</code></pre>
					<pre><code>{this.errorInfo.componentStack}</code></pre>
				</>) : null}
			</div>;
		}
		return this.props.children;
	}
}
