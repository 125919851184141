import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useController } from "react-hook-form";
import { useTimezone } from "../../../Components/Format/Context";
import { Button, Flex, FlexItem, useFieldHook, useGroupBodyProps } from "../../../index";
import { DateInput } from "../Date/DateInput";
import { TimeInput } from "../TimeInput/TimeInput";
const InternalDateTimeInput = function DateTimeInput(props) {
    const { bootstrap: groupBootstrap } = useGroupBodyProps();
    const bootstrap = props.bootstrap ?? groupBootstrap ?? true;
    const tz = useTimezone(props.tz);
    return React.createElement(Flex, { style: props.style },
        React.createElement(Flex, { grow: true, gap: !bootstrap ? 2 : undefined },
            React.createElement("div", { style: { flex: "0 0 62%" } },
                React.createElement(DateInput, { bootstrap: bootstrap, value: props.value, tz: tz, left: props.left, disabled: props.disabled ?? props.disabledDate, wrapperStyle: { width: "100%" }, style: { width: "100%", ...(props.dateStyle ?? {}) }, clearable: props.clearable, closeOnChange: props.closeOnChange, onChange: (value) => {
                        props.onChange(value);
                    }, as: props.dateInput, min: props.minDate || props.min, max: props.maxDate || props.max, ref: props.dateRef })),
            React.createElement("div", { style: { width: "100%" } },
                React.createElement(TimeInput, { bootstrap: bootstrap, value: props.value, tz: tz, disabled: props.disabled ?? props.disabledTime, style: { borderLeft: 0, ...(props.timeStyle ?? {}) }, clearable: props.clearable, onChange: value => {
                        props.onChange(value);
                    }, as: props.timeInput, min: props.min, max: props.max, ref: props.timeRef }))),
        props.clearable && React.createElement(FlexItem, { shrink: true }, bootstrap ?
            React.createElement(Button, { onClick: () => {
                    props.onChange(undefined);
                } },
                React.createElement(FontAwesomeIcon, { icon: faTimes, fixedWidth: true })) :
            React.createElement(Button, { variant: "link", size: "sm", style: { width: "100%", margin: "auto" }, onClick: () => {
                    props.onChange(undefined);
                } },
                React.createElement(FontAwesomeIcon, { icon: faTimes, fixedWidth: true }))));
};
const InternalDateTimeInputHook = function DateTimeInputHook(props) {
    const { name } = useFieldHook() ?? {};
    const controller = useController({ name: props.name ?? name });
    return React.createElement(InternalDateTimeInput, { ...props, ...controller.field, onChange: e => {
            controller.field.onChange(props.asUTC ? e?.toUTC().toISO() : e?.toISO());
        } });
};
export const DateTimeInput = Object.assign(InternalDateTimeInput, {
    Hook: InternalDateTimeInputHook
});
