import { faBug, faMessageQuestion } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApiError } from "@tcs-rliess/fp-core";
import { fpLog } from "@tcs-rliess/fp-log";
import { CommandBar, lazyComponent } from "@tcs-rliess/fp-web-ui";
import { observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import ReactDOM from "react-dom";

import { ErrorBoundary, FpProvider } from "./common";
import { ToastMessageParams } from "./lib";
import { FP_APP } from "./main";

const ErrorReportDialog = lazyComponent(() => import("./ErrorReportDialog"), m => m.ErrorReportDialog);

export enum ErrorEventKind {
	Navigation = "NAVIGATION",
	Error = "ERROR",
}

export interface ErrorEvent {
	kind: ErrorEventKind;
	/** time of the event */
	time: string;
	/** location href at the time of the event */
	url: string;

	apiError?: ApiError;
}
const errorEvents = observable.box<ErrorEvent[]>([], { deep: false });

// track navigation
let previousHref = document.location.href;
window.addEventListener("load", () => {
	const observer = new MutationObserver(() => {
		if (previousHref != document.location.href) {
			previousHref = document.location.href;

			errorEvents.get().push({
				kind: ErrorEventKind.Navigation,
				time: new Date().toISOString(),
				url: document.location.href,
			});
		}
	});

	observer.observe(document.querySelector("body"), {
		childList: true,
		subtree: true
	});
}, { once: true });

let toastKey: string;
const showErrorReportDialog = observable.box(false);

export const handleError = (error: Error): void => {
	fpLog.error("handleError", { error });

	let apiError: ApiError;
	if (error instanceof ApiError) {
		apiError = error;
	} else {
		apiError = new ApiError(0, "UNHANDLED_JS_ERROR", "An unexpected error happened.", error);
	}

	errorEvents.get().push({
		kind: ErrorEventKind.Error,
		time: new Date().toISOString(),
		url: window.location.href,
		apiError: apiError,
	});

	const params: ToastMessageParams = {
		icon: faBug,
		app: "Error",
		variant: "danger",
		autoDismiss: false,
		body: <>
			Sorry, something went wrong<br/>
			{apiError.name}
		</>,
		buttons: <>
			<CommandBar.PulseButton
				variant="danger"
				onClick={() => {
					showErrorReportDialog.set(true);
					FP_APP.toastManager.dismiss(toastKey);
				}}
			>
				<FontAwesomeIcon icon={faMessageQuestion}/>&nbsp;
				Report
			</CommandBar.PulseButton>
		</>
	};

	if (toastKey) {
		FP_APP.toastManager.update(toastKey, params);
	} else {
		toastKey = FP_APP.toastManager.add(params);
	}
};

const ErrorDisplayer: React.FC = observer(() => (
	<ErrorBoundary>
		<FpProvider app={FP_APP}>
			{showErrorReportDialog.get() ? (
				<React.Suspense fallback={<div/>}>
					<ErrorReportDialog
						errorEvents={errorEvents.get()}
						onHide={() => {
							// hide dialog
							showErrorReportDialog.set(false);
						}}
						onAfterSubmit={() => {
							// empty events
							errorEvents.set([]);
						}}
					/>
				</React.Suspense>
			): null}
		</FpProvider>
	</ErrorBoundary>
));

const errorRoot = document.createElement("div");
errorRoot.id = "REACT_ERROR_ROOT";

document.addEventListener("readystatechange", () => {
	if (document.readyState !== "loading") {
		document.body.appendChild(errorRoot);
		ReactDOM.render(<ErrorDisplayer/>, errorRoot);
	}
}, { once: true });
