import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faArchive, faArrowFromLeft, faArrowFromRight, faArrowRight, faArrowToRight, faBalanceScale, faBan, faBookmark, faBriefcaseMedical, faBuilding, faCalendar,
	faCalendarEdit, faCalendarExclamation, faCertificate, faCheckSquare, faChevronDoubleRight, faChevronDown, faChevronRight, faClipboardList,
	faClipboardPrescription, faCodeBranch, faCog, faCommentPlus, faCommentSlash, faCopy, faDownload, faEnvelope, faExclamationTriangle, faExternalLink, faEye,
	faFile, faFileAlt, faFileExcel, faFilePdf, faGem, faGhost, faHandHeart, faHandsHeart, faHeartbeat, faHistory, faHome, faIdCard, faLayerGroup, faLink,
	faListAlt, faLock, faLockOpen, faMapMarker, faMapSigns, faPencil, faPhone, faProjectDiagram, faQuestionCircle, faSearchPlus, faShareSquare, faShield,
	faShieldCheck, faSigma, faSitemap, faSkullCrossbones, faSquare, faStickyNote, faSync, faTablet, faTachometer, faTachometerAlt, faTimes, faUnlink, faUser,
	faUserLock, faUserPlus, faUsers, faUserShield, faUserChart, faSun, faMoon, faRepeat, faDotCircle, faFlag, faCrown, faFont, faMinus, faICursor, faParagraph,
	faUserUnlock, faAt, faCogs, faSlidersH, faUserHardHat, faHelicopter, faDrone, faDroneAlt, faInventory, faPlane, faSignature, faAnalytics, faArrowToTop,
	faArrowToBottom, faAngleDoubleDown, faAngleDoubleUp, faAngleDown, faAngleUp, faBook, faGasPump, faInfoCircle, faCalendarCheck, faChalkboardTeacher,
	faClock, faChartBar, faCubes, faPaperPlane, faHeadphones, faList, faLightbulb, faSearch, faInfinity, faGraduationCap, faChevronLeft, faBars, faCalculator,
	faCartArrowDown, faChartLine, faWater, faChartPie, faFileCertificate, faPlus, faExpandAlt, faCompressAlt, faUserCheck, faComments, faDatabase, faTrash,
	faExternalLinkSquare, faPrint, faCircle, faMinusCircle, faMobile, faFax, faFileCheck, faColumns, faCalendarAlt, faUserCrown, faEllipsisV, faTicket, faCheck,
	faSpinnerThird, faTasks, faCube, faShoppingCart, faSuitcase, faFileExport, faWrench, faCalendarDay, faExchange, faReply, faStar, faReplyAll, faHdd, faKey,
	faUserClock, faPercent, faShip, faGlobeEurope, faCity, faWarehouse, faHospital, faUsersClass, faHospitalAlt, faCreditCard, faDrumstick, faBox,
	faHeadphonesAlt, faChairOffice, faCreditCardFront, faDoorClosed, faTowerControl, faFilter, faAngleRight, faAngleLeft, faArrowUpWideShort,
	faArrowDownWideShort, faEyeSlash, faScissors, faPaste, faKitMedical, faIslandTropical, faStarShooting, faArrowProgress, faCalculatorSimple,
	faPersonWalkingDashedLineArrowRight, faPersonWalkingArrowLoopLeft, faBriefcase, faMagnifyingGlassMinus,
} from "@fortawesome/pro-light-svg-icons";

library.add(
	faArchive, faArrowFromLeft, faArrowFromRight, faArrowRight, faArrowToRight, faBalanceScale, faBan, faBookmark, faBriefcaseMedical, faBuilding, faCalendar,
	faCalendarEdit, faCalendarExclamation, faCertificate, faCheckSquare, faChevronDoubleRight, faChevronDown, faChevronRight, faClipboardList,
	faClipboardPrescription, faCodeBranch, faCog, faCommentPlus, faCommentSlash, faCopy, faDownload, faEnvelope, faExclamationTriangle, faExternalLink, faEye,
	faFile, faFileAlt, faFileExcel, faFilePdf, faGem, faGhost, faHandHeart, faHandsHeart, faHeartbeat, faHistory, faHome, faIdCard, faLayerGroup, faLink,
	faListAlt, faLock, faLockOpen, faMapMarker, faMapSigns, faPencil, faPhone, faProjectDiagram, faQuestionCircle, faSearchPlus, faShareSquare, faShield,
	faShieldCheck, faSigma, faSitemap, faSkullCrossbones, faSquare, faStickyNote, faSync, faTablet, faTachometer, faTachometerAlt, faTimes, faUnlink, faUser,
	faUserLock, faUserPlus, faUsers, faUserShield, faUserChart, faSun, faMoon, faRepeat, faDotCircle, faFlag, faCrown, faFont, faMinus, faICursor, faParagraph,
	faUserUnlock, faAt, faCogs, faSlidersH, faUserHardHat, faHelicopter, faDrone, faDroneAlt, faInventory, faPlane, faSignature, faAnalytics, faArrowToTop,
	faArrowToBottom, faAngleDoubleDown, faAngleDoubleUp, faAngleDown, faAngleUp, faBook, faGasPump, faInfoCircle, faCalendarCheck, faChalkboardTeacher,
	faClock, faChartBar, faCubes, faPaperPlane, faHeadphones, faList, faLightbulb, faSearch, faInfinity, faGraduationCap, faChevronLeft, faBars, faCalculator,
	faCartArrowDown, faChartLine, faWater, faChartPie, faFileCertificate, faPlus, faExpandAlt, faCompressAlt, faUserCheck, faComments, faDatabase, faTrash,
	faExternalLinkSquare, faPrint, faCircle, faMinusCircle, faMobile, faFax, faFileCheck, faColumns, faCalendarAlt, faUserCrown, faEllipsisV, faTicket, faCheck,
	faSpinnerThird, faTasks, faCube, faShoppingCart, faSuitcase, faFileExport, faWrench, faCalendarDay, faExchange, faReply, faStar, faReplyAll, faHdd, faKey,
	faUserClock, faPercent, faShip, faGlobeEurope, faCity, faWarehouse, faHospital, faUsersClass, faHospitalAlt, faCreditCard, faDrumstick, faBox,
	faHeadphonesAlt, faChairOffice, faCreditCardFront, faDoorClosed, faTowerControl, faFilter, faAngleRight, faAngleLeft, faArrowUpWideShort,
	faArrowDownWideShort, faEyeSlash, faScissors, faPaste, faKitMedical, faIslandTropical, faStarShooting, faArrowProgress, faCalculatorSimple,
	faPersonWalkingDashedLineArrowRight, faPersonWalkingArrowLoopLeft, faBriefcase, faMagnifyingGlassMinus
);
