import { DateTime } from "luxon";
import styled from "styled-components";
export const Wrapper = styled.div `
	position: relative;
	display: inline-block;
`;
export const Overlay = styled.div `
	position: absolute;
	top: 100%;
	background: ${p => p.theme.background};
	border: 1px solid #dddddd;
	z-index: 2000;
	${p => p.left ? "right: 0;" : "left: 0;"}
`;
export class DateRange {
    constructor(from, to) {
        this.from = from;
        this.to = to;
        this.direction = this.to > this.from ? DateRangeDirection.Forward : DateRangeDirection.Backward;
        if (this.direction === DateRangeDirection.Forward) {
            this.from = this.from.startOf("day");
            this.to = this.to.endOf("day");
        }
        else {
            this.from = this.from.startOf("day");
            this.to = this.to.endOf("day");
        }
        this.start = DateTime.min(this.from, this.to);
        this.end = DateTime.max(this.from, this.to);
    }
    Limit(params) {
        if (this.direction === DateRangeDirection.Forward) {
            const from = params.min && this.from < params.min ? params.min : this.from;
            let to;
            if (params.maxNumberOfDays) {
                const toLimited = Math.abs(this.from.diff(this.to).as("days")) > params.maxNumberOfDays ? from.plus({ days: params.maxNumberOfDays }) : this.to;
                to = params.max && toLimited > params.max ? params.max : toLimited;
            }
            else {
                to = params.max && this.to > params.max ? params.max : this.to;
            }
            return new DateRange(from, to);
        }
        else {
            const from = params.max && this.from > params.max ? params.max : this.from;
            let to;
            if (params.maxNumberOfDays) {
                const toLimited = Math.abs(this.from.diff(this.to).as("days")) > params.maxNumberOfDays ? from.minus({ days: params.maxNumberOfDays }) : this.to;
                to = params.min && toLimited < params.min ? params.min : toLimited;
            }
            else {
                to = params.min && this.to < params.min ? params.min : this.to;
            }
            return new DateRange(from, to);
        }
    }
    IsValid(params) {
        if (this.direction === DateRangeDirection.Forward) {
            if (params.min && this.from < params.min)
                return false;
            if (params.max && this.to > params.max)
                return false;
            if (params.maxNumberOfDays && Math.abs(this.from.diff(this.to).as("days")) > params.maxNumberOfDays)
                return false;
            return true;
        }
        else {
            if (params.max && this.from > params.max)
                return false;
            if (params.min && this.to < params.min)
                return false;
            if (params.maxNumberOfDays && Math.abs(this.from.diff(this.to).as("days")) > params.maxNumberOfDays)
                return false;
            return true;
        }
    }
}
export var DateRangeDirection;
(function (DateRangeDirection) {
    DateRangeDirection[DateRangeDirection["Forward"] = 0] = "Forward";
    DateRangeDirection[DateRangeDirection["Backward"] = 1] = "Backward";
})(DateRangeDirection || (DateRangeDirection = {}));
