import React from "react";
import styled, { css } from "styled-components";
import { Flex, FlexItem } from "../../Components";
import { useThemeColor } from "../../Css";
import { InfoTableBodyStateCtx } from "./InfoTableBody";
export const InfoTableField = function InfoTableField(props) {
    const stateCtx = React.useContext(InfoTableBodyStateCtx);
    const color = useThemeColor(props.variant);
    return React.createElement(React.Fragment, null,
        props.inline ? React.createElement("tr", { style: { width: "33%", background: color?.[100].main, color: color?.[500].main }, className: props.className },
            React.createElement("td", { className: (props.condensed || stateCtx?.condensed) ? "field-condensed" : "field" },
                React.createElement("div", { className: "infoTableContent" }, props.label)),
            React.createElement("td", { className: (props.condensed || stateCtx?.condensed) ? "field-condensed" : "field" },
                React.createElement("div", { className: "infoTableContent" }, props.children))) : React.createElement(React.Fragment, null,
            React.createElement("tr", null,
                React.createElement("td", { className: (props.condensed || stateCtx?.condensed) ? "field-condensed" : "field", colSpan: 2 },
                    React.createElement("div", { className: "infoTableContent" }, props.label))),
            React.createElement("tr", null,
                React.createElement("td", { colSpan: 2 },
                    React.createElement("div", { className: "infoTableContent" }, props.children)))),
        props.error && React.createElement(StyledErrorField, null,
            React.createElement("td", { colSpan: 2 },
                React.createElement("small", null, typeof props.error === "string" ? props.error : JSON.stringify(props.error)))));
};
InfoTableField.defaultProps = {
    inline: true,
};
export const StyledField = styled(InfoTableField) `
	td.field:first-child {
		width: 33%;
		height: 40px;
	}
	td.field-condensed:first-child {
		width: 33%;
		height: 27px;
	}
	.field, .field-condensed {
		vertical-align: top;
	}
	.infoTableContent {
	${props => {
    if (props.sticky) {
        return css `color: green;`;
    }
}}
	}
`;
export const StyledErrorField = styled.tr `
	color: #a94442;
`;
export const InfoTableHeader = function InfoTableHeader(props) {
    return React.createElement("tr", null,
        React.createElement("td", { colSpan: 2, className: "field" },
            React.createElement(Flex, null,
                React.createElement(FlexItem, { grow: true, style: { display: "flex", alignItems: "center" } },
                    React.createElement("span", null, props.label)),
                React.createElement(FlexItem, { shrink: true }, props.children))));
};
export const InfoTableFooter = (props) => {
    return React.createElement(React.Fragment, null, props.children);
};
