import { ApiContext, Brand, DirectoryMember, DirectoryNode, FpApi, FpDirClient, getBrand, ReadRegion, UserSettings } from "@tcs-rliess/fp-core";
import { fpLog } from "@tcs-rliess/fp-log";
import { Formatter, ThemeDark, ThemeLight, UnitSystem } from "@tcs-rliess/fp-web-ui";
import { pick } from "lodash-es";
import { Settings } from "luxon";
import { autorun, extendObservable, observable, when } from "mobx";
import moment from "moment-timezone";
import React from "react";

import { FleetplanApi } from "./api";
import { handleError } from "./handleError";
import { ClientStore, EventStreamManager, FpSettingsClientWeb, LinkUtil, Mqtt, PresenceStore, ToastManager } from "./lib";

const DEFAULT_USER_SETTINGS: UserSettings = {
	defaultClient: "default",
	tz: moment.tz.guess(),
	fplaid: 0,
	fdt: {
		ruleset: "",
		tz: moment.tz.guess(),
	},
	finance: {
		currency: "EUR",
		language: "en",
		salesinvoice: {
			numberrange: "",
		},
	},
	leg: {
		tz: moment.tz.guess(),
	},
	i18n: {
		locale: "de_DE",
		firstDayOfWeek: 1,
		papersize: "iso",
		separator: {
			decimal: ".",
			group: ",",
		},
		units: {
			temperature: "c",
			volume: UnitSystem.Metric,
			speed: UnitSystem.Metric,
			weight: UnitSystem.Metric,
			distance: UnitSystem.Metric,
			byte: UnitSystem.SI,
		},
		time: {
			short: "HH:mm",
			medium: "HH:mm:ss",
			format24: true,
		},
		date: {
			short: "DD.MM.YYYY",
			medium: "DD MMM. YYYY",
		},
	},
};

export interface FleetplanAppParams {
	devMode?: boolean;
	/**
	 * If not provided will fallback to an empty ctx
	 */
	jsonCtx?: FpApi.MeInformation;
	userSettings?: UserSettings;

	disableAutoLoad?: boolean;

	newLayout?: boolean;
	themeAutomatic?: boolean;

	/** set some legacy window variables */
	setWindowVariables?: boolean;

	/** FP_READTRACKING_REGION */
	readTrackingRegion: ReadRegion;
}

export class FleetplanApp {
	public readonly ctx: ApiContext;
	/**
	 * @deprecated
	 */
	public readonly fleetplanApi = new FleetplanApi();

	@observable public newLayout = false;
	@observable public themeAutomatic = false;
	@observable public theme = ThemeLight;

	public brand: Brand;
	public get dscid(): number { return this.ctx?.dscid; }
	public get dscaid(): number { return this.ctx?.dscaid; }
	public $dscaid: FpApi.Contact.ContactAddress;
	public customer: FpApi.Customer.Customer;
	@observable public userSettings: UserSettings = DEFAULT_USER_SETTINGS;
	@observable public readTrackingRegion: ReadRegion;

	// utils
	public readonly linkUtil: LinkUtil;
	public readonly formatter: Formatter;

	// client / mqtt
	public readonly mqtt = new Mqtt(this);
	public readonly eventStream = new EventStreamManager(this);
	public readonly presenceStore = new PresenceStore(this);
	public readonly fpDirClient = new FpDirClient({ use: "FleetplanApp" });
	public readonly fpSettings: FpSettingsClientWeb;
	public readonly toastManager = new ToastManager();

	/**
	 * dscaid of active client
	 */
	@observable public activeClient: number;

	// directory caches
	/** @deprecated */ public get directory(): DirectoryNode[] { return this.store.fpDir.directory.getTree().tree; }
	/** @deprecated */ public get userMembers(): DirectoryMember[] { return this.store.fpDir.directory.getMembersByResource("dscaid", this.ctx.dscaid); }
	/** @deprecated */ public get members(): DirectoryMember[] { return this.store.fpDir.directory.getMembers(); }

	// stores
	public readonly preload: Promise<void>;
	public readonly store: ClientStore;

	// flags
	@observable public devMode = false;
	/** if this mode is enabled, <Access/> of type dev won't be shown */
	@observable public presentationMode = Boolean(localStorage.getItem("presentationMode") || localStorage.getItem("devMode2"));
	@observable public showDevBar = false;

	@observable public flags: {
		flightOpsNew: boolean;
		dutySchedule: boolean;
		/**
		 * Set of existing id names in the "duty_type" category
		 * Can be used to hide buttons etc. when no category of a type exists.
		 */
		permissionInherit: boolean;
		checkIn: boolean;
		controlledDocumentNew: boolean;
	} = {
		flightOpsNew: false,
		dutySchedule: false,
		permissionInherit: false,
		checkIn: false,
		controlledDocumentNew: false
	};

	public isWorkflowEnabledForSchedule(type: FpApi.Resource.Duty.ScheduleType | Array<FpApi.Resource.Duty.ScheduleType>) : boolean {
		if (Array.isArray(type)) {
			return type.every(t => this.isWorkflowEnabledForSchedule(t));
		}
		return this.isScheduleTypeEnabled(type) && this.store.settingsProject.getBoolean(`hr.schedule.${type}.workflowEnabled`);
	}

	public isScheduleTypeEnabled(type: FpApi.Resource.Duty.ScheduleType | Array<FpApi.Resource.Duty.ScheduleType>): boolean {
		if (Array.isArray(type)) {
			return type.every(t => this.isScheduleTypeEnabled(t));
		}
		return this.store.settingsProject.getBoolean(`hr.schedule.${type}.enabled`);
	}

	public get isAnyWorkflowEnabled(): boolean {
		return this.isWorkflowEnabledForSchedule(FpApi.Resource.Duty.ScheduleType.LocalDay)
			|| this.isWorkflowEnabledForSchedule(FpApi.Resource.Duty.ScheduleType.MedicalLeave)
			|| this.isWorkflowEnabledForSchedule(FpApi.Resource.Duty.ScheduleType.Off)
			|| this.isWorkflowEnabledForSchedule(FpApi.Resource.Duty.ScheduleType.Vacation);
	}

	constructor(params: FleetplanAppParams) {
		if (params.jsonCtx != null) {
			this.ctx = new ObservableApiContext(params.jsonCtx.sessionData);
			this.$dscaid = params.jsonCtx.$dscaid;
			this.customer = params.jsonCtx.$dscid;
			this.brand = params.jsonCtx.brand;
		} else {
			// no have no ctx in:
			// - mobile app
			// - mobile app "AppViews" for testing
			this.ctx = ObservableApiContext.emptyCtx(undefined);
			this.brand = getBrand(location.host);
		}

		this.devMode = params.devMode ?? false;
		this.newLayout = params.newLayout ?? false;
		this.themeAutomatic = params.themeAutomatic ?? false;
		this.showDevBar = (params.devMode || this.ctx.ghostDscaid != null) ?? false;
		this.readTrackingRegion = params.readTrackingRegion ?? "de1";

		this.store = new ClientStore(this);
		this.linkUtil = new LinkUtil(this);
		this.fpSettings = new FpSettingsClientWeb({ ctx: this.ctx, use: "FleetplanApp", app: this });
		this.formatter = new Formatter({
			tz: this.userSettings.tz,
			locale: this.userSettings.i18n.locale,
			number: {
				decimal: this.userSettings.i18n.separator.decimal,
				group: this.userSettings.i18n.separator.group,
			},
			time: {
				short: this.userSettings.i18n.time.short,
				medium: this.userSettings.i18n.time.medium,
			},
			date: {
				short: this.userSettings.i18n.date.short,
				medium: this.userSettings.i18n.date.medium,
			},
			units: {
				byte: this.userSettings.i18n.units.byte,
				distance: this.userSettings.i18n.units.distance,
				volume: this.userSettings.i18n.units.volume,
				speed: this.userSettings.i18n.units.speed,
				weight: this.userSettings.i18n.units.weight,
			},
		});

		// color theme
		autorun(() => {
			if (this.themeAutomatic) {
				this.theme = window.matchMedia("(prefers-color-scheme: dark)").matches ? ThemeDark : ThemeLight;
				window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", e => {
					this.theme = e.matches ? ThemeDark : ThemeLight;
					fpLog.debug(`[FleetplanApp] changed theme ${this.theme.kind}`);
				});
			} else {
				this.theme = ThemeLight;
			}
		});

		// new layout
		autorun(() => {
			const header: HTMLDivElement = document.querySelector(".dsappcontainer > header");
			const sidebar: HTMLDivElement = document.querySelector(".dsappsidebar");

			if (this.newLayout) {
				if (header) header.style.display = "none";
				if (sidebar) sidebar.style.display = "none";
			} else {
				if (header) header.style.display = null;
				if (sidebar) sidebar.style.display = null;
			}
		});

		// show/hide dev items in the lucee main navigation
		autorun(() => {
			this.presentationMode;

			document.querySelectorAll("[data-dsdev=\"1\"]").forEach(el => {
				if (el instanceof HTMLElement) {
					if (this.presentationMode) {
						el.style.display = "none";
					} else {
						el.style.display = null;
					}
				}
			});
		});

		// update user settings when ever data in the settings store changes
		autorun(() => {
			// only actually do if we have setting in there
			if (this.store.settingsUser.items.length === 0) return;
			this.userSettings = this.store.settingsUser.asObjectDeep();

			this.formatter.options = {
				tz: this.userSettings.tz,
				locale: this.userSettings.i18n.locale,
				number: {
					decimal: this.userSettings.i18n.separator.decimal,
					group: this.userSettings.i18n.separator.group,
				},
				time: {
					short: this.userSettings.i18n.time.short,
					medium: this.userSettings.i18n.time.medium,
				},
				date: {
					short: this.userSettings.i18n.date.short,
					medium: this.userSettings.i18n.date.medium,
				},
				units: {
					byte: this.userSettings.i18n.units.byte,
					distance: this.userSettings.i18n.units.distance,
					volume: this.userSettings.i18n.units.volume,
					speed: this.userSettings.i18n.units.speed,
					weight: this.userSettings.i18n.units.weight,
				},
			};
		}),

		// i18n for moment & luxon
		autorun(() => {
			if (this.userSettings == null) return;

			const locale = this.userSettings.i18n.locale.replace("_", "-");
			const localeShort = locale.split("-")[0];

			fpLog.info(`updating locale settings..., locale: ${locale}`);

			Settings.defaultZone = this.userSettings.tz;
			Settings.defaultLocale = locale.toLowerCase();

			// set custom formats
			const currentLocale = (moment.localeData() as any)._config;
			const data = {
				parentLocale: localeShort,
				longDateFormat: {
					LT: this.userSettings.i18n.time.short,
					LTS: this.userSettings.i18n.time.medium,

					L: this.userSettings.i18n.date.short,
					LL: this.userSettings.i18n.date.medium,
					LLL: this.userSettings.i18n.date.medium,
					LLLL: this.userSettings.i18n.date.medium,
				},
				week: {
					dow: this.userSettings.i18n.firstDayOfWeek,
					doy: currentLocale.week.doy,
				},
			};

			if (!moment.locales().includes(locale)) {
				moment.defineLocale(locale.toLowerCase(), data);
			} else {
				moment.updateLocale(locale.toLowerCase(), data);
			}

			fpLog.info(`update locale settings, locale: ${locale}`);
			moment.locale(locale.toLowerCase());
		});

		if (params.setWindowVariables) {
			autorun(() => {
				// any-cast to typescript is okay with us doing this
				const w = window as any;

				w.DSDEV = this.devMode;
				w.DSCID = `${this.ctx.dscid}`;
				w.DSUID = this.ctx.dsuid;
				w.DSCAID = this.ctx.dscaid;
				w.DSCAID_name = `${this.$dscaid.$person.givenName} ${this.$dscaid.$person.lastName}`;
				w.DSCAUID = this.$dscaid.uid.toLowerCase();
				w.DSCAID_avatar_tn = this.$dscaid.avatarTn ?? "";
				w.DSRoleNames = this.ctx.sessionData.roleNames;
				w.dsmodules = this.ctx.sessionData.project.moduleNames;
			});
		}

		// preload
		this.preload = this.runPreload();

		// mqtt connection
		when(() => {
			return this.dscid != null
				&& this.ctx.dscaid != null
				&& this.ctx.isAuthorized;
		}, () => {
			// connect to MQTT
			this.mqtt.connect().catch(handleError);

			// old wkhtml print
			this.mqtt.subscribe(`/pusher/${this.dscaid}`, (topic: string, json: string) => {
				const data = JSON.parse(json);

				if (window.dsNotify) {
					const parsedContent = typeof data.message.content === "string" ? JSON.parse(data.message.content) : data.message.content;
					window.dsNotify(data.message.key, parsedContent);
				}
				if (data.callback?.length) {
					(window as any)[data.callback]();
				}
			}).catch(handleError);
		});
	}

	/**
	 * Changes the "client", each project can have multiple clients. A Client 
	 * @param dscaid new active client
	 */
	public async setActiveClient(dscaid: number): Promise<void> {
		const original = this.activeClient;
		this.activeClient = dscaid;

		try {
			// command will save the dscaid in a cookie
			// the value from the cookie will also be used in various lucee stuff
			const res = await fetch(`/dynasite.cfm?dscmd=contact_client_client_api&dsxhr=1&_method=set&dscaid=${dscaid}`);
			if (res.status !== 200) throw new Error("couldn't set client");

			const resultDscaid = parseInt(await res.text());
			this.activeClient = resultDscaid;
		} catch (e) {
			// error, restore
			this.activeClient = original;
			throw e;
		}
	}

	private async runPreload(): Promise<void> {
		await when(() => this.ctx.isAuthorized === true && this.customer != null);

		await Promise.all([
			// load fpDirs
			this.store.fpDir.directory.reload(),
			this.store.fpDir.hazard.reload(),
			// load categories
			this.store.categoryUtil.reload(),
			// load project settings
			this.store.settingsProject.reload(),
			this.store.settingsUser.reload(),
			// load task templates, needed for possibly every view, since 
			// templates could exist everywhere for everything
			this.store.taskTemplate.ensureLoad(),
			// preload some stores
			this.store.contact.ensureLoad(),
			this.store.landingField.ensureLoad(),
			this.store.resource.aircraft.ensureLoad(),
			this.store.resource.aircraftModel.ensureLoad(),
			this.store.resource.aircraftState.ensureLoad(),
			this.store.resource.shift.ensureLoad(),
			this.store.resource.userLogType.ensureLoad(),
			this.store.workflow.ensureLoad(),
			this.store.qualityReport.ensureLoad(),
			this.store.systemCategory.ensureLoad(),
			this.store.controlleddocument.fetchPermissions(),
		]);

		const tree = this.store.fpDir.directory.getTree();
		this.ctx.directoryStructure = new Map();
		tree.walk(info => this.ctx.directoryStructure.set(info.node.id, info.parents.map(n => n.id)));

		this.ctx.assignmentStructure = new Map();
		this.store.fpDir.directory.getMembers().forEach(member => {
			if (member.linktype !== "dscaid") return;
			// ignore any source duty entries
			if (member.src === "duty") return;

			const dscaid = parseInt(member.linkid);
			const value = pick(member, "loc", "grp", "pos", "sec");

			const existing = this.ctx.assignmentStructure.get(dscaid);
			if (existing) existing.push(value);
			else this.ctx.assignmentStructure.set(dscaid, [ value ]);
		});

		// 2024-09-26 - [PR] This needs to be loaded AFTER the directory is loaded!
		await this.store.resource.locationSetupUtil.prepareUtil();

		// set flags
		this.flags.flightOpsNew = this.store.settingsProject.getBoolean("CF.Request.DS.Flag.FlightOpsNew") ?? false;
		this.flags.dutySchedule = this.store.settingsProject.getBoolean("CF.Request.DS.Flag.DutySchedule") ?? false;
		this.flags.checkIn = this.store.settingsProject.getBoolean("CF.Request.DS.Flag.CheckIn") ?? false;
		this.flags.permissionInherit = this.store.settingsProject.getBoolean("security.permissionInherit") ?? false;
		this.flags.controlledDocumentNew = this.store.settingsProject.getBoolean("CF.Request.DS.Flag.ControlledDocumentNew") ?? false;
	}
}

export const appContext = React.createContext<FleetplanApp>(undefined);
export const useApp = () => React.useContext(appContext);

class ObservableApiContext extends ApiContext {
	constructor(sessionData: FpApi.Security.SessionData) {
		super(sessionData);
		extendObservable(this, {
			sessionData: this.sessionData,
		}, {
			sessionData: observable,
		});
	}
}
