import { TimeSpan } from "@tcs-rliess/fp-core";
import { DateTime } from "luxon";

import { FleetplanApp } from "../FleetplanApp";

export class ScheduleUtil {
	public static async getCurrentStation({ app, fpvid, timeSpan, fallbackLocation } : {
		app: FleetplanApp;
		fpvid: number;
		timeSpan: TimeSpan | DateTime;
		fallbackLocation?: number;
	}): Promise<{ location?: number; customer?: number; }> {
		if (DateTime.isDateTime(timeSpan)) {
			timeSpan = new TimeSpan(timeSpan.startOf("minute"), timeSpan.endOf("minute"));
		}

		const schedules = (await app.store.resource.schedule.getRange(timeSpan.start, timeSpan.end))
			.filter(e => {
				return e.linkType === "fpvid"
					&& e.dscid > 0
					&& e.isCurrent;
			}).map(e => {
				return {
					...e,
					range: new TimeSpan(DateTime.fromISO(e.dateFrom), DateTime.fromISO(e.dateTo)),
				};
			});

		const schedule = schedules.find(e => e.linkId === fpvid.toString() && e.range.intersects(timeSpan));
		let location = schedule?.data.fpdirloc;

		// 2024-11-15 - [DL, PR]
		// If no location was found, use the fallback location
		// If no fallback location is provided, use the aircraft's default location
		if (!location && fallbackLocation) location = fallbackLocation;
		else if (!location) location = app.store.resource.aircraft.getId(fpvid)?.fpdirloc;

		return {
			location,
			customer: schedule?.data?.dscaidCustomer
		};
	}
}
