import { ResourceTimeRangeModel, TimeRangeModel } from "@bryntum/scheduler-thin";
import { AssignmentModel } from "@bryntum/schedulerpro-thin";
import { DateTime } from "luxon";

import { FpEventModel, FpResourceModel } from "../models";

export class BuilderState {
	public readonly events: FpEventModel[] = [];
	public readonly assignments: AssignmentModel[] = [];
	public readonly timeRanges: TimeRangeModel[] = [];
	public readonly resourceTimeRanges: ResourceTimeRangeModel[] = [];

	public readonly resourceScheduler: Map<string, Set<FpResourceModel>>;

	constructor(params: {
		resourceScheduler: Map<string, Set<FpResourceModel>>;
	}) {
		this.resourceScheduler = params.resourceScheduler;
	}

	public isEmpty(): boolean {
		return this.events.length === 0
			&& this.assignments.length === 0
			&& this.timeRanges.length === 0
			&& this.resourceTimeRanges.length === 0;
	}

	public getResourcesIdList(linkType: string): string[] {
		const idList = new Set<string>();

		Array.from(this.resourceScheduler.keys()).forEach(k => {
			const [ type, id ] = k.split(":");
			if (type ===linkType) idList.add(id);
		});

		return Array.from(idList);
	}

	// public getResources(linkType: string, linkId: string | number): Set<FpResourceModel> {
	// 	return this.resourceScheduler.get(`${ linkType}:${linkId}`) ?? new Set();
	// }
}

export class RangeBuilderState extends BuilderState {
	public readonly from: DateTime;
	public readonly to: DateTime;

	constructor(params: {
		resourceScheduler: Map<string, Set<FpResourceModel>>;
		from: DateTime;
		to: DateTime;
	}) {
		super(params);

		this.from = params.from;
		this.to = params.to;
	}
}
