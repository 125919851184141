import { ClientCategoryUtil, FpApi } from "@tcs-rliess/fp-core";

import { FleetplanApp } from "../FleetplanApp";

export class ContactUtil {
	/**
	 * Check if a contact is a flight crew member.  
	 * If the setting "contact.flightCrewByTag" is enabled, the contact is considered a flight crew member if it has a flightcrew tag.  
	 * If the setting is disabled, all contacts are considered flight crew members.
	 *
	 */
	public static isFlightCrew(app: FleetplanApp, dscaid: number): boolean {
		if (app.store.settingsProject.getBoolean("contact.flightCrewByTag")) {
			const contact = app.store.contact.getId(dscaid);
			const mappedTagsDir = contact.tags_dir?.map(e => e.split(":")[1]);
			if (mappedTagsDir?.length) {
				// check if any of the tags is a flightcrew tag
				const isFlightCrew = mappedTagsDir.some(tag => {
					// tag can be any possible string, not just an enum from the EventResourceRole
					const contactDirTag = ClientCategoryUtil.byEnum(FpApi.Calendar.Event.EventResourceRole).getOption(tag as any);
					return contactDirTag?.type?.includes("flightcrew");
				});
				if (isFlightCrew) return true;
			}

			return false;
		} else {
			// old default - everyone is "flight crew"
			return true;
		}
	}
}
