import { defaults } from "lodash";
import { DateTime, Duration } from "luxon";
import moment from "moment-timezone";
import { findUnit, getUnit, getUnits, UnitConverter, UnitSystem, UnitType } from "./UnitConverter";
export class Formatter {
    constructor(userSettings) {
        this.dec2dms = (lat, lon) => {
            const latDir = lat >= 0 ? "N" : "S";
            const lonDir = lon >= 0 ? "E" : "W";
            return `${latDir}${this.calcDMS(lat)} / ${lonDir}${this.calcDMS(lon)}`;
        };
        this.calcDMS = (cord) => {
            // Thanks to: http://en.marnoto.com/2014/04/converter-coordenadas-gps.html
            const val = Math.abs(cord);
            const valDeg = Math.floor(val);
            const valMin = Math.floor((val - valDeg) * 60);
            const valSec = (Math.round((val - valDeg - valMin / 60) * 3600 * 1000) / 1000).toFixed(2);
            return `${valDeg}º ${valMin}' ${valSec}"`;
        };
        this.options = userSettings;
    }
    /**
     * Formats the `value` based on the settings of the user.
     * @param value date value to format
     * @param options additional options
     */
    time(value, options = {}) {
        options = defaults(options, {
            tz: this.options.tz,
            mask: this.options.time.short,
        });
        switch (options.mask) {
            case "short":
                options.mask = this.options.time.short;
                break;
            case "medium":
                options.mask = this.options.time.medium;
                break;
        }
        const date = this.parseDateTime(value);
        if (DateUtil.isNoDate(date))
            return "";
        return date.tz(options.tz).format(options.mask);
    }
    /**
     * Formats the `value` based on the settings of the user.
     * @param value date value to format
     * @param options additional options
     */
    date(value, options = {}) {
        options = defaults(options, {
            tz: this.options.tz,
            mask: this.options.date.short,
        });
        switch (options.mask) {
            case "short":
                options.mask = this.options.date.short;
                break;
            case "medium":
                options.mask = this.options.date.medium;
                break;
        }
        const date = this.parseDateTime(value);
        if (DateUtil.isNoDate(date))
            return "";
        if (options.today && date.isSame(moment(), "d")) {
            // TODO: translation
            return "Today";
        }
        return date.tz(options.tz).format(options.mask);
    }
    /**
     * Formats the `value` based on the settings of the user.
     * @param value date value to format
     * @param options additional options
     */
    dateTime(value, options = {}) {
        const date = this.parseDateTime(value);
        if (options.today && date.isSame(moment(), "d")) {
            return this.time(value, options);
        }
        options = defaults(options, {
            tz: this.options.tz,
            mask: this.options.date.short + " " + this.options.time.short,
        });
        switch (options.mask) {
            case "short":
                options.mask = this.options.date.short + " " + this.options.time.short;
                break;
            case "medium":
                options.mask = this.options.date.medium + " " + this.options.time.medium;
                break;
        }
        if (DateUtil.isNoDate(date.toISOString()))
            return "";
        return date.tz(options.tz).format(options.mask);
    }
    tz(value, options = {}) {
        const date = this.parseDateTime(value);
        return date.tz(options.tz).format("z");
    }
    tzOffset(value, options = {}) {
        const date = this.parseDateTime(value);
        return date.tz(options.tz).format("Z");
    }
    /**
     * Formats duration (time between to points in time)
     * @param value ISO8601 string, number (in `options.unit`), or an object
     * @param options additional options
     */
    duration(value, options) {
        options = defaults(options, {
            mask: "hh:mm",
            unit: "millisecond",
            floor: true,
        });
        let dur;
        if (value == null) {
            return "";
        }
        else if (Duration.isDuration(value)) {
            dur = value;
        }
        else if (typeof value === "string") {
            dur = Duration.fromISO(value);
        }
        else if (typeof value === "number") {
            dur = Duration.fromObject({ [options.unit]: value });
        }
        else {
            dur = Duration.fromObject(value);
        }
        const durationIsNegative = dur.as("seconds") < 0;
        if (durationIsNegative) {
            dur = dur.negate();
        }
        const formatted = dur.toFormat(options.mask, { floor: options.floor });
        if (durationIsNegative) {
            return `-${formatted}`;
        }
        else {
            return formatted;
        }
    }
    /**
     * Returns date time span depending on usersettings
     * @param start start date
     * @param end end date
     * @returns string like this: `10-02 (13:03 - 13:03)`
     */
    dateTimeRange(start, end, providedOptions = {}) {
        const options = defaults({ ...providedOptions }, {
            tz: this.options.tz,
            mask: this.options.date.short + " " + this.options.time.short,
        });
        const toOptions = { ...options };
        const fromOptions = { ...options };
        const startDate = this.parseDateTime(start);
        let endDate = this.parseDateTime(end);
        if (options.hideHours || endDate.clone().tz(options.tz).format("HH:mm") === "00:00") {
            endDate = endDate.subtract(1, "minute");
            if (!providedOptions.mask) {
                toOptions.mask = this.options.date.short;
            }
        }
        if (options.hideHours || startDate.clone().tz(options.tz).format("HH:mm") === "00:00") {
            if (!providedOptions.mask) {
                fromOptions.mask = this.options.date.short;
            }
        }
        switch (options.returnType) {
            case "start":
                return `${this.date(startDate, fromOptions)}`;
            case "end":
                return `${this.date(endDate, toOptions)}`;
            default:
                return `${this.date(startDate, fromOptions)} ${(start && end) ? "-" : ""} ${this.date(endDate, toOptions)}`;
        }
    }
    /**
     * Returns date time span depending on usersettings
     * @param start start date
     * @param end end date
     * @returns string like this: `10-02 (13:03 - 13:03)`
     */
    dateTimeSpan(start, end, options = {}, returnOptions = {}) {
        options = defaults(options, {
            tz: this.options.tz,
            mask: this.options.date.short + " " + this.options.time.short,
        });
        const startDate = this.parseDateTime(start);
        const endDate = this.parseDateTime(end);
        const sameYear = moment().isSame(startDate, "year");
        if (options.showDays) {
            let diff = 0;
            diff = Math.ceil(endDate.clone().startOf("day").diff(startDate.clone().startOf("day"), "days"));
            // if(!startDate.isSame("day")) { // only do it in case its not the same day
            // }
            return `${this.dateTime(startDate, {
                today: options.today,
                tz: options.tz
            })} ${(start && end) ? "-" : ""} ${this.time(endDate, {
                today: options.today,
                tz: options.tz,
            })} ${diff ? `+${diff}` : ""}`;
        }
        if (sameYear) {
            if (options) {
                if (["medium", "short"].includes(options.mask)) {
                    this.filterMaskYear(this.options.date[options.mask]);
                }
                else {
                    options.mask = this.filterMaskYear(options.mask);
                }
            }
            else {
                options.mask = `${this.filterMaskYear(this.options.date.medium)} ${this.options.time.short}`;
            }
        }
        else {
            options.mask = `${this.options.date.medium} ${this.options.time.short}`;
        }
        if (startDate.isSame(endDate, "year")) { // is same year?
            return `${this.dateTime(startDate, options)} ${(start && end) ? "-" : ""} ${this.time(endDate, { tz: options.tz })}`; // TODO there should be an option for this
        }
        return `${this.dateTime(startDate, options)} ${(start && end) ? "-" : ""} ${this.dateTime(endDate, options)}`;
    }
    /**
     * Helper function for dateTimeSpan. May need some improvement // TODO
     * @param mask Mask
     */
    filterMaskYear(mask) {
        return mask.replace(/Y{4}|Y{2}/, "") // replace YYYY or YY with empty string
            .replace(/([\s\\\-\.\/]([\s\\\-\.\/]))/, "$2") // replace double .,-,/
            .replace(/^(.*)([\s\\\.\-\/])$/, "$2")
            .replace(/^([\s\\\.\-\/])(.*)$/, "$2");
    }
    /**
     * Formats the `value` based on the settings of the user.
     * @param value currency/value value to format
     * @param options additional options
     */
    currency(value, options = { currency: "usd" }) {
        options = defaults(options, {
            currency: "usd",
            locale: this.options.locale.replace("_", "-"),
            separatorGroup: this.options.number.group,
            separatorDecimal: this.options.number.decimal,
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            enforceSign: false,
        });
        const format = new Intl.NumberFormat(this.options.locale.replace("_", "-"), {
            style: "currency",
            currency: options.currency,
            maximumFractionDigits: options.decimalDigits,
            minimumFractionDigits: options.decimalDigits,
            useGrouping: true,
            currencyDisplay: options.currencyDisplay,
        });
        // as any because it does exist (Not typed because may not supported everywhere):
        // https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat/formatToParts
        let out = format.formatToParts(value).map(e => {
            switch (e.type) {
                case "decimal": return options.separatorDecimal;
                case "group": return options.separatorGroup;
                default:
                    return e.value;
            }
        }).join("");
        if (value > 0 && options.enforceSign) {
            out = "+" + out;
        }
        return out;
    }
    /**
     * Formats the `value` based on the settings of the user.
     * @param value currency/value value to format
     * @param options additional options
     */
    number(value, options = {}) {
        options = defaults(options, {
            locale: this.options.locale.replace("_", "-"),
            separatorGroup: this.options.number.group,
            separatorDecimal: this.options.number.decimal,
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            enforceSign: false,
        });
        const format = new Intl.NumberFormat(this.options.locale.replace("_", "-"), {
            maximumFractionDigits: options.decimalDigits,
            minimumFractionDigits: options.decimalDigits,
            useGrouping: true,
        });
        // as any because it does exist (Not typed because may not supported everywhere):
        // https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat/formatToParts
        let out = format.formatToParts(value).map(e => {
            switch (e.type) {
                case "decimal": return options.separatorDecimal;
                case "group": return options.separatorGroup;
                default:
                    return e.value;
            }
        }).join("");
        if (value > 0 && options.enforceSign) {
            out = "+" + out;
        }
        return out;
    }
    /**
     * Internal function to handle conversion into a moment object.
     * Can handle:
     * - seconds & millisecond timestamps
     * - JS Date object
     * - ISO 8601 String
     * - moment objects
     * @param value input value to parse
     */
    parseDateTime(value) {
        if (moment.isMoment(value)) {
            return value;
        }
        let luxonValue;
        if (value == null) {
            luxonValue = DateUtil.noDate;
        }
        else if (value.trim && value.trim() === "") {
            luxonValue = DateUtil.noDate;
        }
        else if (typeof value === "number") {
            if (value > 9999999999) {
                // milliseconds
                luxonValue = DateTime.fromMillis(value);
            }
            else {
                // seconds
                luxonValue = DateTime.fromSeconds(value);
            }
        }
        else if (value instanceof Date) {
            luxonValue = DateTime.fromJSDate(value);
        }
        else if (typeof value === "string") {
            luxonValue = DateTime.fromISO(value);
        }
        else if (value instanceof DateTime) {
            luxonValue = value;
        }
        else {
            throw new Error(`value could not be parsed! ${value}`);
        }
        return moment.tz(luxonValue.toISO(), moment.ISO_8601, luxonValue.zoneName);
    }
    /**
     * Tries to convert a moment.js date mask to a JQuery mask.
     *
     * for JQuery:
     * http://api.jqueryui.com/datepicker/#utility-formatDate
     * for moment.js:
     * http://momentjs.com/docs/#/displaying/
     *
     * @param mask moment.js mask
     * @returns JQuery mask
     */
    momentMaskToJQuery(mask) {
        mask = mask.replace(/DDDD/g, "oo"); // day of the year (three digit)
        mask = mask.replace(/DDD/g, "o"); // day of the year (no leading zeros)
        mask = mask.replace(/DD/g, "dd"); // day of month (two digit)
        mask = mask.replace(/D/g, "d"); // day of month (no leading zero)
        mask = mask.replace(/dddd/g, "DD"); // day name long
        mask = mask.replace(/ddd/g, "D"); // day name short
        mask = mask.replace(/MMMM/g, "MM"); // month name long
        mask = mask.replace(/MMM/g, "M"); // month name short
        mask = mask.replace(/MM/g, "mm"); // month of year (two digit)
        mask = mask.replace(/M/g, "m"); // month of year (no leading zero)
        mask = mask.replace(/YYYY/g, "yy"); // year (four digit)
        mask = mask.replace(/YY/g, "y"); // year (two digit)
        mask = mask.replace(/x/g, "@"); // Unix timestamp (ms since 01/01/1970)
        // not supported: ! - Windows ticks (100ns since 01/01/0001)
        return mask;
    }
    distance(value, options = {}) {
        const units = getUnits(UnitType.Distance, this.options.units.distance, true);
        return this.unitBaseFormat(options, units, value, "distance.metric.millimeter");
    }
    volume(value, options = {}) {
        const units = getUnits(UnitType.Volume, this.options.units.volume, true);
        return this.unitBaseFormat(options, units, value, "volume.metric.liter");
    }
    speed(value, options = {}) {
        const units = getUnits(UnitType.Speed, this.options.units.speed, true);
        return this.unitBaseFormat(options, units, value, "speed.metric.kmh");
    }
    mass(value, options = {}) {
        const units = getUnits(UnitType.Mass, this.options.units.weight, true);
        return this.unitBaseFormat(options, units, value, "mass.metric.kilogram");
    }
    bytes(value, options = {}) {
        let units = [];
        switch (this.options.units.byte) {
            default:
            case "si":
                units = getUnits(UnitType.Byte, UnitSystem.SI);
                break;
            case "binary":
                units = [getUnit("byte.si.byte"), ...getUnits(UnitType.Byte, UnitSystem.Binary)];
                break;
        }
        return this.unitBaseFormat(options, units, value, "byte.si.byte");
    }
    unitBaseFormat(options, units, value, defaultInUnit) {
        if (options.outUnit)
            units = [getUnit(options.outUnit)];
        if (options.decimalDigits == null)
            options.decimalDigits = 0;
        const inUnit = options.inUnit || defaultInUnit;
        let unit;
        let outValue;
        if (options.size != null) {
            const realUnit = units.find(u => u.size === options.size);
            outValue = UnitConverter.convert(value, inUnit, realUnit.id);
            unit = realUnit.id;
        }
        else {
            [unit, outValue] = findUnit(value, inUnit, units.map(e => e.id));
        }
        const _unit = getUnit(unit);
        if (options.ignoreAbbr) {
            return this.number(outValue, options);
        }
        return this.number(outValue, options) + " " + _unit.abbr;
    }
    // TODO use formatter as suggested: FP-2622
    phoneNumber(number) {
        const parts = [];
        let normalized = number.replace(/\s/, "");
        const defaultPrefix = "49"; // TODO userSettings country -> CLDR
        if (/^\+\d+/.test(normalized)) {
            parts.push(normalized.substr(0, 3), "(0)");
            normalized = normalized.substr(3);
        }
        else if (normalized.startsWith("0")) {
            parts.push(`+${defaultPrefix}`, "(0)");
            normalized = normalized.substr(1);
        }
        else {
            console.error(`${number} is not a valid phone number`);
        }
        while (normalized.length >= 3) {
            const length = normalized.length <= 3 ? normalized.length : 2;
            parts.push(normalized.substr(0, length));
            normalized = normalized.substr(length);
        }
        return parts.join(" ");
    }
    iban(iban) {
        const split = iban.match(/.{1,4}/g);
        return split.join(" ");
    }
}
/** Provides some utils for working with dates. */
class DateUtil {
    /**
     * Checks if the date is a "no Date" date. Applies a "buffer" of 24 hours before and after.
     * @see noDate
     * @see infinityDate
     * @param data Date to check
     */
    static isNoDate(input) {
        const date = this.toLuxon(input);
        if (Math.abs(date.diff(this.noDate).as("hours")) < 24) {
            return true;
        }
        else if (Math.abs(date.diff(this.infinityDate).as("hours")) < 24) {
            return true;
        }
        return false;
    }
    static toLuxon(input) {
        if (input instanceof DateTime)
            return input;
        else if (moment.isMoment(input))
            return DateTime.fromISO(input.toISOString());
        else
            return DateTime.fromISO(input);
    }
}
/** value used if no Date is selected. */
DateUtil.noDate = DateTime.fromISO("1901-01-01T00:00:00Z");
/** value used for "forever". */
DateUtil.infinityDate = DateTime.fromISO("2500-01-01T00:00:00Z");
