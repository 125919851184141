import { faBullseyePointer, faCloudArrowUp, faPlus, faSpinnerThird, faSync } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { omit } from "lodash";
import React from "react";
import { CSSTransition } from "react-transition-group";
import styled, { css, useTheme } from "styled-components";
import { isThemeColor } from "../../Css";
import { Alert } from "../Alert/Alert";
import { Button, StyledButton } from "../Button";
import { Dropdown } from "../Dropdown";
import { Flex, FlexItem } from "../Flex";
import { Text } from "../Text";
import { WorkflowWidgetContainer } from "../WorkflowWidget/WorkflowWidget";
const Title = styled.h3 `
	margin-left: 1em;
	font-size: 16px;
	color: rgb(88, 95, 105);
	margin-top: 0px;
	margin-bottom: 0px;
`;
const CommandBarText = styled(Text) `
	padding: 0 10px;
	line-height: 30px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	/** same as a button, to match the bar */
	font-weight: 400;
`;
const Divider = styled.div `
	height: 100%;
	border-left: 1px solid ${props => props.theme.defaults.borderColor};
	margin: 0px 3px;
`;
const BASE_HEIGHT = 40;
const CONTEXT_HEIGHT = {
    select: 20,
    base: BASE_HEIGHT,
    grid: 27.2,
};
const TopBarHeader = React.forwardRef(function TopBar(props, ref) {
    const theme = useTheme();
    const context = props.height ?? "base";
    const innerRef = React.useRef();
    React.useImperativeHandle(ref, () => innerRef.current);
    return (React.createElement(StyledFlexItem, { height: CONTEXT_HEIGHT[context], className: props.className, style: { position: "relative", ...(props.style ?? {}) } },
        React.createElement(Flex, { ref: innerRef, row: true, alignItems: "center", style: { height: "100%", [!props.noBorder ? `border${props.flipBorder ? "Top" : "Bottom"}` : undefined]: `1px solid ${theme.defaults.borderColor}` } },
            (props.label) && React.createElement(Title, null, props.label),
            props.children,
            React.createElement(FlexItem, { grow: true, style: { order: 7000 } }),
            React.createElement(FlexItem, { grow: true, style: { order: 9000 } }))));
});
const Order = (props) => {
    const baseOrder = React.useContext(BaseOrderCtx);
    return React.createElement(React.Fragment, null, React.Children.map(props.children, (child) => {
        if (!child)
            return null;
        if (React.isValidElement(child)) {
            if (child.type === React.Fragment) {
                return React.cloneElement(child, { children: React.Children.map(child.props.children, (c) => {
                        if (React.isValidElement(c)) {
                            return React.cloneElement(c, { style: { ...(c?.props?.style ?? {}), order: baseOrder + (props.order ?? 0) } });
                        }
                        return c;
                    }) });
            }
            return React.cloneElement(child, { style: { ...(child?.props?.style ?? {}), order: baseOrder + (props.order ?? 0) } });
        }
        return child;
    }) ?? null);
};
const BaseOrderCtx = React.createContext(0);
const Left = (props) => {
    return React.createElement(BaseOrderCtx.Provider, { value: 0 },
        React.createElement(Order, { ...props }));
};
const Center = (props) => {
    return React.createElement(BaseOrderCtx.Provider, { value: 8000 },
        React.createElement(Order, { ...props }));
};
const Right = (props) => {
    return React.createElement(BaseOrderCtx.Provider, { value: 10000 },
        React.createElement(Order, { ...props }));
};
const StyledFlexItem = styled(FlexItem) `
	height: ${e => e.height}px;
	background-color: ${p => p.theme.background};
	${StyledButton} {
		border: none;
	}
	${WorkflowWidgetContainer} {
		gap: 5px;
	}
`;
const InternalCommandBarButton = function CommandBarButton(props) {
    return (React.createElement(CommandBarButtonComponent, { ...props },
        React.createElement("span", { style: props.textLabel ? { position: "relative", top: ".6em" } : undefined }, props.children)));
};
const CommandBarButton = styled(InternalCommandBarButton) ``;
const CommandBarButtonComponent = styled(Button) `
	border: none;
	height: 100%;
	${e => e.variant ? "" : css `background: transparent;`}
	line-height: 30px;
	white-space: nowrap;
	padding: 0px 10px;
	user-select: none;
	${props => props.active ? css `
	box-shadow: inset 0px 3.1em 0 ${props.theme.background};
	background: ${p => p.theme.color.primary[500].main};
	` : ""}
	${props => {
    if (props.active)
        return "";
    return css `
			&:focus:not(:active):not(:hover) {
				background: ${props => props.theme.color.primary[200].main};
			}	
		`;
}}
	${e => {
    // TODO 2023-08-14 - [PR] We should either emove variant="default" in all of our buttons, or add it as a variant to the theme
    // Currently no time to do either, so this is a quick fix
    if (e.variant === "default")
        return "";
    if (e.variant === "link")
        return "";
    if (isThemeColor(e.variant)) {
        return css `
				color: ${e.theme.color[e.variant][500].main};
				box-shadow: inset 0px 3.1em 0 ${e.theme.color[e.variant][100].main};
				background: ${e.theme.color[e.variant][100].main};
				&:hover {
					background: ${e.theme.color[e.variant][600].main};
				}
				&:focus {
					background: ${e.theme.color[e.variant][200].main};
				}
			`;
    }
    return css `
			box-shadow: inset 0px 3.1em 0 ${e.theme.background};
			&:hover {
				background: ${e.theme.color["primary"][500].main};
			}
		`;
}}
	${e => {
    if (e.variant === "default")
        return "";
    if (e.variant === "link")
        return "";
    if (isThemeColor(e.variant)) {
        return css `
				${() => e.active ? css `
				box-shadow: inset 0px 3.1em 0 ${e.theme.color[e.variant][100].main};
				background: ${e.theme.color[e.variant][400].main};
				` : ""}
				&:active {
					box-shadow: inset 0px 3.1em 0 ${e.theme.color[e.variant][100].main};
					background: ${e.theme.color[e.variant][400].main};
				}
			`;
    }
    else {
        return css `
					${() => e.active ? css `
					box-shadow: inset 0px 3.1em 0 ${e.theme.background};
					background: ${e.theme.color["primary"][600].main};
					&:hover {
						box-shadow: inset 0px 3.1em 0 ${e.theme.kind === "light" ? "rgb(250, 250, 250)" : "#272727"};
					}
					` : ""}
					&:active {
						box-shadow: inset 0px 3.1em 0 ${e.theme.kind === "light" ? "#f2f2f2" : "#272727"};
						background: ${e.theme.color["primary"][600].main};
					}
				`;
    }
}}


	@media (pointer: coarse) {
		padding: 0 15px;
	}

	>svg.svg-inline--fa {
		font-size: 1em;
	}

    display: inline-flex;
	justify-content: center;
    flex-direction: column;
    align-items: center;
	position: relative;
	${props => {
    if (props.textLabel) {
        return css `
			::after {
					content: "${props.textLabel}";
					font-weight: 300;
					position: relative;
					transform: translateY(-100%);
					text-align: center;
					width: max-content;
					font-size: .85em;
					line-height: 0px;
					transform: translateY(.5em);
					width: calc(100% + 20px);
					top: -3.25em;
					font-variant: all-petite-caps;
    				letter-spacing: 1px;
					${() => {
            if (isThemeColor(props.variant)) {
                return css `
							background-color: ${props.theme.color[props.variant][500].main};
							color: ${props.theme.color[props.variant][500].font};
						`;
            }
        }}
				}
				::before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					height: 1.2em;
					${() => {
            if (isThemeColor(props.variant)) {
                return css `
							background-color: ${props.theme.color[props.variant][500].main};
							color: ${props.theme.color[props.variant][500].font};
						`;
            }
        }}
				}
			`;
    }
    return "";
}}
    
`;
CommandBarButton.defaultProps = {
    size: "sm",
};
const AddButton = (props) => {
    return (React.createElement(Dropdown, { style: { ...(props.style ?? {}), height: "100%" }, enableSingleClick: true, component: CommandBarButton, title: React.createElement(React.Fragment, null,
            React.createElement(FontAwesomeIcon, { fixedWidth: true, icon: faPlus }),
            "\u00A0Add") }, props.children));
};
const ReloadButton = (props) => {
    const [loading, setLoading] = React.useState(false);
    const isLoading = props.isLoading || loading;
    return (React.createElement(CommandBarButton, { ...props, onClick: async (ev) => {
            props.onClick?.(ev);
            if (ev.defaultPrevented)
                return;
            if (isLoading || props.disabled)
                return;
            setLoading(true);
            await props.action(ev);
            setLoading(false);
        }, disabled: props.disabled || isLoading },
        React.createElement(FontAwesomeIcon, { spin: isLoading, icon: faSync })));
};
const SaveButton = (props) => {
    const [loading, setLoading] = React.useState(false);
    return (React.createElement(CommandBar.Button, { style: props.style, onClick: async (ev) => {
            props.onClick?.(ev);
            if (ev.defaultPrevented)
                return;
            if (loading || props.disabled)
                return;
            setLoading(true);
            await props.action(ev);
            setLoading(false);
            props.onAfterAction?.(ev);
        }, disabled: props.disabled || loading, title: "Save" }, loading ? React.createElement(FontAwesomeIcon, { icon: faSpinnerThird, spin: true }) : React.createElement(FontAwesomeIcon, { icon: faCloudArrowUp })));
};
const ActionButton = (props) => {
    return (React.createElement(Dropdown, { style: { ...(props.style ?? {}), height: "100%" }, component: CommandBarButton, title: React.createElement(React.Fragment, null,
            React.createElement(FontAwesomeIcon, { fixedWidth: true, icon: faBullseyePointer }),
            "\u00A0Actions") }, props.children));
};
const DropdownButton = (props) => {
    return (React.createElement(Dropdown, { ...props, style: { ...(props.style ?? {}), height: "100%" }, component: CommandBarButton, title: props.title }, props.children));
};
const SecondaryBar = styled(TopBarHeader) `
	transition: all 100ms;
	box-shadow: 0 5px 25px -5px rgb(0 0 0 / 10%), 0 8px 10px -6px rgb(0 0 0 / 10%);
	&.secondary-bar-enter {
		height: 0px;
		opacity: 0;
	}
	&.secondary-bar-enter-active {
		height: 40px;
		opacity: 1;
	}
	&.secondary-bar-exit{
		height: 0px;
		
	}
	&.secondary-bar-exit-done {
		height: 0px;
		opacity: 0;
	}
`;
export const SecondBarButton = function SecondBarButton(props) {
    const [open, setOpen] = React.useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(CommandBarButton, { ...(omit(props ?? {}, "children", "title")), style: { borderBottom: open ? "none" : undefined }, onClick: () => {
                setOpen(!open);
            }, active: open }, props.title),
        React.createElement(CSSTransition, { in: open, unmountOnExit: true, timeout: 100, classNames: "secondary-bar", onExited: () => {
                setOpen(false);
            }, onEnter: () => setOpen(true) },
            React.createElement(SecondaryBar, { className: "secondary-bar", style: { position: "absolute", width: "100%", top: 40, zIndex: 100 } }, open && props.children))));
};
const CommandBarInput = styled.input `
	height: 100%;
	border: none;
	outline: none;
	border-left: 1px solid ${props => props.theme.defaults.borderColor};
	border-right: 1px solid ${props => props.theme.defaults.borderColor};
	padding: 0 8px;
`;
export const PulseInButton = styled(CommandBarButton) `
position: relative;
animation: innerPulse 1s 3 ease-in;
> {
	z-index: 2;
}

@keyframes innerPulse {
	0% {
		background: ${e => e.theme.color.cancelled[300].main};
	}
	70% {
		background: ${e => e.theme.color.cancelled[300].main};
	}
	100% {
		background: none;
	}
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 ${e => e.theme.color.cancelled[300].main};
	}
	70% {
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	animation: pulse 1s 3 ease-in;
	z-index: 3;
}
`;
const CommandBarAlert = styled(Alert) `
    padding: 0;
    height: 100%;
    padding-inline: 1.25em;
    align-content: center;
	border-width: 0px;
    border-bottom-width: 2px;
`;
export const CommandBar = Object.assign(TopBarHeader, {
    Button: CommandBarButton,
    Reload: ReloadButton,
    Save: SaveButton,
    Alert: CommandBarAlert,
    PulseButton: PulseInButton,
    Divider: Divider,
    Dropdown: Object.assign(DropdownButton, {
        Item: Dropdown.Item,
        Menu: Dropdown.Menu,
        Separator: Dropdown.Separator,
        Title: Dropdown.Title,
    }),
    Title: Title,
    Text: CommandBarText,
    SubBar: SecondBarButton,
    Add: AddButton,
    Action: ActionButton,
    Left: Left,
    Right: Right,
    Center: Center,
    Input: CommandBarInput,
    SelectTheme: {
        styles: {
            container: (base) => ({ ...base, height: "39px", marginLeft: 10, minWidth: 100 }),
            option: (base) => ({ ...base, padding: 8 }),
            placeholder: (base) => ({ ...base, color: "hsl(0,0%,50%)", paddingTop: 3, paddingLeft: 2 }),
            multiValue: (base) => ({ ...base, fontSize: "1em", marginLeft: 5 }),
            multiValueLabel: (base) => ({ ...base, marginRight: 5 }),
            control: (base, state) => {
                return { ...base,
                    marginLeft: "0px",
                    padding: "2.5px 2.5px 2.5px 5px",
                    minHeight: 0,
                    height: "100%",
                    borderTop: "none",
                    borderBottom: "none",
                    border: "none",
                    borderLeft: "1px solid #ddd",
                    borderRight: "1px solid #ddd",
                    boxShadow: "none",
                    "&:hover": {}
                };
            },
            indicatorSeparator: (base) => ({ ...base, display: "none" }),
        },
        // theme: (theme => ({ ...theme, borderRadius: 0, spacing: { ...theme.spacing, baseUnit: 1, menuGutter: 2 }})),
    },
    SelectThemeNoMargin: {
        styles: {
            container: (base) => ({ ...base, height: "39px", minWidth: 100 }),
            option: (base) => ({ ...base, padding: 8 }),
            placeholder: (base) => ({ ...base, color: "hsl(0,0%,50%)", paddingTop: 3, paddingLeft: 2 }),
            multiValue: (base) => ({ ...base, fontSize: "1em", marginLeft: 5 }),
            multiValueLabel: (base) => ({ ...base, marginRight: 5 }),
            control: (base, state) => {
                return { ...base,
                    marginLeft: "0px",
                    padding: "2.5px 2.5px 2.5px 5px",
                    minHeight: 0,
                    height: "100%",
                    borderTop: "none",
                    borderBottom: "none",
                    border: "none",
                    borderLeft: "1px solid #ddd",
                    borderRight: "1px solid #ddd",
                    boxShadow: "none",
                    "&:hover": {}
                };
            },
            indicatorSeparator: (base) => ({ ...base, display: "none" }),
        },
        // theme: (theme => ({ ...theme, borderRadius: 0, spacing: { ...theme.spacing, baseUnit: 1, menuGutter: 2 }})),
    },
});
