import { FpLegClientLeg } from "@tcs-rliess/fp-core";

import { FpEventModel } from "../models";

import { BaseBuilder } from "./BaseBuilder";
import { BuilderState, RangeBuilderState } from "./BuilderState";

export class AircraftLegBuilder extends BaseBuilder {
	public async buildRange(state: RangeBuilderState): Promise<void> {
		const loads: Promise<void>[] = [];

		if (this.store.configuration.events.aircraftLeg?.enabled) loads.push(this.aircraftLeg(state));

		await Promise.all(loads);
	}

	public handlePut(state: BuilderState, items: FpLegClientLeg[]): Promise<void> {
		return Promise.resolve();
	}

	public handleDelete(state: BuilderState, idList: string[]): Promise<void> {
		return Promise.resolve();
	}

	private async aircraftLeg(state: RangeBuilderState): Promise<void> {
		const fpvidList = state.getResourcesIdList("fpvid").map(i => parseInt(i));
		const buckets = this.getBuckets(state);

		for (const bucket of buckets) {
			const aircraftLeg = await bucket.aircraftLeg(fpvidList);

			aircraftLeg.forEach(leg => {
				this.makeEvent(state, () => {
					const event = new FpEventModel({
						id: `_aircraft_leg:${leg.id}`,
						startDate: this.toTimeZone(leg.blockoff),
						endDate: this.toTimeZone(leg.blockon),
						name: leg.running_no,
						draggable: false,
						resizable: false,

						fpLinkType: "_aircraft_leg",
						fpLinkId: leg.id.toString(),
						fpData: leg,
					});

					return event;
				}, function*() {
					yield { linkType: "fpvid", linkId: leg.fpvid };
				});
			});
		}
	}
}
